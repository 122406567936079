define('slate-payroll/models/help-article', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  /* eslint-disable no-undef */
  var attr = _emberData.default.attr;

  exports.default = _emberData.default.Model.extend({
    title: attr('string'),
    body: attr('string'),
    youtubeUrl: attr('string'),

    votesDown: attr('number'),
    votesUp: attr('number'),
    votesUsersUp: _emberData.default.hasMany('user', {
      async: true,
      inverse: false
    }),
    votesUsersDown: _emberData.default.hasMany('user', {
      async: true,
      inverse: false
    }),

    tags: attr('array'),
    category: _emberData.default.belongsTo('help-category', { async: true }),
    created: attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),

    bodyPreview: Ember.computed('body', function () {
      if (this.get('body')) {
        var html = markdown.toHTML(this.get('body'));
        var slicedHtml = html.replace(/<[^>]*>/g, '').slice(0, 100);
        return slicedHtml.length > 99 ? slicedHtml + '...' : slicedHtml;
      } else {
        return undefined;
      }
    })
  });
});