define('slate-payroll/models/paystub', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var attr = _emberData.default.attr;

  exports.default = _emberData.default.Model.extend({
    read: attr('boolean'),

    earnings: _emberData.default.hasMany('earning'),
    deductions: _emberData.default.hasMany('deduction'),
    achTransactions: _emberData.default.hasMany('ach-transaction'),
    balances: attr('array'),
    descriptor: attr('string'),
    payrollDate: attr('date'),

    yearToDateInformationGrossWages: attr('number'),
    yearToDateInformationFederal: attr('number'),
    yearToDateInformationState: attr('number'),
    yearToDateInformationSocialSecurity: attr('number'),
    yearToDateInformationMedicare: attr('number'),
    yearToDateInformationOtherDeductions: attr('number'),

    totalsHours: attr('number'),
    totalsGross: attr('number'),
    totalsDeductions: attr('number'),
    totalsNet: attr('number'),

    withholdingFederal: attr('number'),
    withholdingState: attr('number'),
    withholdingSocialSecurity: attr('number'),
    withholdingMedicare: attr('number'),
    withholdingLocal: attr('number'),

    company: _emberData.default.belongsTo('company', {
      async: true,
      inverse: false
    }),
    employee: _emberData.default.belongsTo('employee', {
      async: true,
      inverse: false
    }),
    payPeriod: _emberData.default.belongsTo('pay-period', {
      async: true,
      inverse: false
    }),
    payroll: _emberData.default.belongsTo('payroll', {
      async: true,
      inverse: false
    }),

    created: attr('date')
  });
});