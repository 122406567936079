define('slate-payroll/mixins/controller-abstractions/pagination', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    limit: 10,
    __totalItems: Ember.computed.reads('model.meta.totalRecords'),
    __metadataTotalItems: Ember.computed.reads('metadata.totalRecords'),

    pages: Ember.computed('__totalItems', '__metadataTotalItems', 'limit', function () {
      var total = this.get('__totalItems') || this.get('__metadataTotalItems') || 0;
      return Math.ceil(total / this.get('limit'));
    })
  });
});