define('slate-payroll/controllers/account/broadcasts/index/new', ['exports', 'slate-payroll/mixins/controller-abstractions/add-edit'], function (exports, _addEdit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_addEdit.default, {
    showModal: true,
    enableNotify: true,
    modalId: 'new-broadcast-modal',
    modalTitle: '<h2><i class="fa fa-fw fa-plus"></i> New Broadcast</h2>',
    transitionAfterSave: 'account.broadcasts.index',
    transitionWithModel: false,

    actions: {
      cancel: function cancel() {
        this.transitionToRoute('account.broadcasts.index');
      }
    }
  });
});