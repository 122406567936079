define('slate-payroll/components/polyfills/blend-mode-shim', ['exports', 'slate-payroll/utils/hex-to-rgb', 'slate-payroll/utils/fade-rgb', 'slate-payroll/config/app-data'], function (exports, _hexToRgb2, _fadeRgb, _appData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    blend: Ember.computed('color.[]', function () {
      if (!/MSIE|Edge/.test(window.navigator.userAgent)) {
        return;
      }

      var colorName = this.color[0];

      var _hexToRgb = (0, _hexToRgb2.default)(_appData.brandColors[colorName]),
          r = _hexToRgb.r,
          g = _hexToRgb.g,
          b = _hexToRgb.b;

      var color = (0, _fadeRgb.default)({
        r: r,
        g: g,
        b: b
      }, 0.8);

      return 'background-color: ' + color;
    })
  }).reopenClass({ positionalParams: 'color' });
});