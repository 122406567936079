define('slate-payroll/controllers/account/employee/tax-forms', ['exports', 'slate-payroll/mixins/confirm'], function (exports, _confirm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_confirm.default, {
    actions: {
      delete: function _delete(file) {
        var _this = this;

        var _file$attributes = file.attributes,
            year = _file$attributes.year,
            type = _file$attributes.type;

        this.confirm('Are you sure you want to delete this ' + type + ' for year ' + year + '?', 'Are you sure?', 'confirmState').then(function (ok) {
          if (ok) {
            file.destroyRecord();
            _this.send('refresh');
          }
        });
      }
    }
  });
});