define('slate-payroll/controllers/account/company-documents/read-by', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    showModal: true,
    modalId: 'company-document-read-by-modal',
    modalTitle: '<h2><i class="fa fa-fw fa-eye"></i> Who\'s read it</h2>',

    actions: {
      cancel: function cancel() {
        this.transitionToRoute('account.company-documents');
      }
    }
  });
});