define('slate-payroll/components/x-confirm-instant', ['exports', 'slate-payroll/mixins/confirm'], function (exports, _confirm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_confirm.default, {
    tagName: '',

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.openConfirm();
    },
    openConfirm: function openConfirm() {
      this.confirm(this.message, this.title).then(this.onConfirmation);
    }
  });
});