define('slate-payroll/routes/account/pay-periods/payroll', ['exports', 'slate-payroll/mixins/refreshable'], function (exports, _refreshable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_refreshable.default, {
    model: function model(params) {
      return Ember.RSVP.hash({
        model: this.store.find('payroll', params.payroll_id),
        transactions: this.store.query('punch', {
          transaction: true,
          payroll: params.payroll_id
        })
      });
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        model: model.model,
        transactions: model.transactions
      });
    }
  });
});