define('slate-payroll/mixins/route-abstractions/resource', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  var extend = Ember.$.extend;
  exports.default = Ember.Mixin.create({
    queryParams: {
      page: { refreshModel: true },
      limit: { refreshModel: true },
      sortBy: { refreshModel: true },
      sortAsc: { refreshModel: true }
    },

    model: function model(params) {
      var query = {
        page: params.page - 1 || 0,
        limit: params.limit
      };

      if (this.get('sort')) {
        query.sort = this.get('sort');
      }

      if (params.sortBy) {
        query.sort = _extends({}, query.sort || {}, _defineProperty({}, params.sortBy, params.sortAsc ? 1 : -1));
      }

      if (this.get('query')) {
        extend(query, this.get('query'));
      }

      var mutate = this.mutateQuery,
          sorter = this.sortQuery;

      if (mutate && typeof mutate === 'function') {
        mutate.call(this, query, params);
      }

      if (sorter && typeof sorter === 'function') {
        sorter.call(this, query);
      }

      return this.store.query(this.get('modelName'), query);
    }
  });
});