define('slate-payroll/controllers/account/pay-period/upload/review', ['exports', 'slate-payroll/config/app-data', 'slate-payroll/mixins/ux-ajax-status-hooks', 'slate-payroll/mixins/confirm', 'slate-payroll/utils/title-case'], function (exports, _appData, _uxAjaxStatusHooks, _confirm, _titleCase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  exports.default = Ember.Controller.extend(_uxAjaxStatusHooks.default, _confirm.default, {
    states: _appData.states,
    enableNotify: true,
    sendWelcomeEmails: true,
    notDryrun: Ember.computed.not('dryrun'),
    importing: Ember.computed.and('notDryrun', 'working'),

    guesses: Ember.computed.reads('model.data.0'),

    rows: Ember.computed('model.data.[]', function () {
      return (this.get('model.data') || []).slice(2);
    }),

    availableFields: Ember.computed('model.availableFields.[]', function () {
      var _this = this;

      return (this.get('model.availableFields') || []).map(function (_ref) {
        var path = _ref.path,
            format = _ref.format,
            enums = _ref.enums,
            required = _ref.required;

        var label = '' + _this.convertPathToLabel(path) + (format ? ' - ' + format : '');

        if (path === 'customFields') {
          label = 'Assign a custom field';
        }

        return {
          path: path,
          label: label,
          required: required,
          enums: enums,
          isRelationship: format === 'lookup or id' ? true : false
        };
      }).sortBy('label');
    }),

    dataValidation: Ember.computed('rows.[]', 'guesses.[]', 'availableFields.[]', 'potentialData.[]', function () {
      var _getProperties = this.getProperties('guesses', 'availableFields', 'potentialData'),
          guesses = _getProperties.guesses,
          availableFields = _getProperties.availableFields,
          potentialData = _getProperties.potentialData;

      if (!guesses || !availableFields) {
        return [];
      }

      return this.get('rows').map(function (row, rIdx) {
        return row.map(function (column, cIdx) {
          var guessForCell = guesses[cIdx],
              potentialDataForCell = potentialData[rIdx][guessForCell],
              field = availableFields.findBy('path', guessForCell);

          if (!field) {
            return { invalid: false };
          }

          // if cell is a relationship cell, and cell has a value in it, and the potentialDataForCell is undefined
          if (field.isRelationship && column && !potentialDataForCell ? field.path : null) {
            return {
              invalid: true,
              missingRelationship: field.path
            };
          }

          return { invalid: false };
        });
      });
    }),

    convertPathToLabel: function convertPathToLabel() {
      var path = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

      var desegmentedPath = path.replace('[]', '').replace(/\./g, ' '),
          decamelizedPath = desegmentedPath.split(' ').map(function (x) {
        return Ember.String.decamelize(x).replace(/_/g, ' ');
      }).join(' ');

      return (0, _titleCase.default)([decamelizedPath]);
    },


    actions: {
      remapGuess: function remapGuess(index, map) {
        this.set('model.data.0.' + index, map ? map : '');
      },
      dumpDryRun: function dumpDryRun() {
        this.set('dryrunResult', null);
      },
      doDryRun: function doDryRun() {
        var _this2 = this;

        var headerMap = this.get('model.data')[0],
            uploadId = this.get('model.uploadId');

        this.set('dryrun', true);
        this.ajaxStart();

        return Ember.$.post('/api/pay-period/upload/' + uploadId + '/dryrun', { headerMap: headerMap }).done(function (dryrunResult) {
          _this2.set('dryrun', null);
          _this2.set('dryrunResult', dryrunResult);
          _this2.ajaxSuccess(null, true);
        }).fail(this.ajaxError.bind(this));
      },
      import: function _import() {
        var _this3 = this;

        this.confirm('Are you sure you want to import these records?', 'Are you sure?').then(function (ok) {
          if (!ok) {
            return;
          }

          _this3.ajaxStart();

          var headerMap = _this3.get('model.data')[0],
              sendWelcomeEmails = _this3.get('sendWelcomeEmails'),
              uploadId = _this3.get('model.uploadId');

          Ember.$.post('/api/pay-period/upload/' + uploadId + '/import', {
            headerMap: headerMap,
            sendWelcomeEmails: sendWelcomeEmails
          }).done(function (result) {
            _this3.ajaxSuccess('Successfully imported ' + result.nCreated + ' records!');
            _this3.transitionToRoute('account.pay-period.upload.results', { queryParams: { ids: result.createdIds } });
          }).fail(_this3.ajaxError.bind(_this3));
        });
      },
      showModal: function showModal(relationship, name) {
        var _setProperties,
            _this4 = this;

        this.setProperties((_setProperties = {}, _defineProperty(_setProperties, 'new' + relationship, this.get('store').createRecord(relationship, { name: name })), _defineProperty(_setProperties, 'show' + relationship + 'Modal', true), _setProperties));

        return new Promise(function (resolve, reject) {
          var _this4$setProperties;

          return _this4.setProperties((_this4$setProperties = {}, _defineProperty(_this4$setProperties, 'resolve' + relationship, resolve), _defineProperty(_this4$setProperties, 'reject' + relationship, reject), _this4$setProperties));
        });
      },
      respondModal: function respondModal(relationship, response) {
        if (!response) {
          this.get('new' + relationship).destroyRecord();
          this.set('new' + relationship, null);
        }

        this.get(response ? 'resolve' + relationship : 'reject' + relationship)(response ? this.get('new' + relationship) : null);
        this.set('show' + relationship + 'Modal', false);
      },
      onRefresh: function onRefresh() {
        this.send('refresh');
      }
    }
  });
});