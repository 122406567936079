define('slate-payroll/utils/unique-by-key', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = uniqueByKey;
  function uniqueByKey(array, propertyKey) {
    var uniques = Ember.A();

    return array.filter(function (item) {
      var val = Ember.get(item, propertyKey);
      // Pushing an object gives back a truthy val
      return !uniques.includes(val) ? uniques.pushObject(val) : false;
    });
  }
});