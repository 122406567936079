define('slate-payroll/models/change-request', ['exports', 'ember-data', 'slate-payroll/config/models'], function (exports, _emberData, _models) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;


  var isNully = function isNully(v) {
    return v === undefined || v === null || v === '' || v === [];
  };

  exports.default = _emberData.default.Model.extend({
    diff: attr('array'),
    applied: attr('boolean'),
    updater: attr('string'),

    employee: belongsTo('employee', {
      inverse: false,
      async: true
    }),
    company: belongsTo('company', {
      inverse: false,
      async: true
    }),
    user: belongsTo('user', {
      inverse: false,
      async: true
    }),

    consumed: attr('date'),
    effective: attr('date'),
    created: attr('date'),

    snapshot: attr(),

    processedDiffs: Ember.computed('diff.[]', function () {
      var _this = this;

      return (this.get('diff') || []).reduce(function (diffs, diff) {
        // Exclude really similar null values
        if (diff.kind !== 'A' && isNully(diff.lhs) && isNully(diff.rhs)) {
          return diffs;
        }

        // Resume reducing, we are only touching array diffs
        if (diff.kind !== 'A' && _models.employeeModelArrayPaths.indexOf((diff.path || [])[0]) < 0) {
          diffs.addObject(diff);
          return diffs;
        }

        var shallowDiffPath = (diff.path || [])[0];
        var similarDiff = diffs.find(function (d) {
          return shallowDiffPath === (d.path || [])[0];
        });

        if (similarDiff) {
          similarDiff.relatedDiffs = similarDiff.relatedDiffs ? [diff].concat(_toConsumableArray(similarDiff.relatedDiffs)) : [diff];
        } else {
          diff.snapshot = {
            lhs: Ember.get(_this, 'snapshot.' + shallowDiffPath),
            rhs: Ember.get(_this, 'employee.' + shallowDiffPath)
          };

          diffs.addObject(diff);
        }

        return diffs;
      }, Ember.A());
    })
  });
});