define('slate-payroll/routes/error', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    titleToken: 'Error',

    actions: {
      didTransition: function didTransition() {
        var _this = this;

        var controller = this.get('controller'),
            lf = window.localforage;

        if (!controller.get('fromError')) {
          return lf.getItem('slateRoutePreviousToError').then(function (previousRoute) {
            return _this.transitionTo(previousRoute || 'index');
          });
        }

        var previousRoute = controller.get('previousRoute');

        if (previousRoute) {
          lf.setItem('slateRoutePreviousToError', previousRoute);
        }
      },
      willTransition: function willTransition() {
        window.localforage.setItem('slateRoutePreviousToError', null);
      }
    }
  });
});