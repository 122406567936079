define('slate-payroll/routes/account/index/punch-card', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    titleToken: 'Punch Card',

    queryParams: {
      start: { refreshModel: true },
      end: { refreshModel: true }
    },

    model: function model(params) {
      var _ref = params || {},
          start = _ref.start,
          end = _ref.end;

      var dataset = Ember.A([['x'], ['Punches']]);

      return this.store.query('punch', {
        timeIn: { $gt: start },
        timeOut: { $lt: (0, _moment.default)(end).endOf('day').toDate() },
        select: 'timeIn',
        sort: 'timeIn'
      }).then(function (punches) {
        return punches.reduce(function (arr, punch) {
          if (!punch) {
            return arr;
          }

          var timeIn = (0, _moment.default)(Ember.get(punch, 'timeIn'));
          arr[0].push(timeIn.format('YYYY-MM-DD'));
          arr[1].push(timeIn.get('hour'));

          return arr;
        }, dataset);
      });
    }
  });
});