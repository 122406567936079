define('slate-payroll/components/x-snowshoe-screen', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    logging: Ember.inject.service(),

    classNames: ['snowshoe-stamp-screen'],
    postUrl: '/client-api/employee/squares-login',

    didInsertElement: function didInsertElement() {
      this.initStampScreen();
    },
    initStampScreen: function initStampScreen() {
      var initData = {
        postUrl: this.get('postUrl'),
        stampScreenElmId: this.get('elementId'),
        postViaAjax: true,
        success: this.stampAction('success'),
        error: this.stampAction('error'),
        messages: {}
      };

      this.logging.debug('Initializing stamp screen...');
      Ember.$.snowshoe.stampScreen.init(initData, Ember.$.snowshoe.client);
      this.logging.debug('Initialized stamp screen.');
    },
    stampAction: function stampAction(event) {
      var _this = this;

      return function (response) {
        var callback = _this.get(event);

        if (!callback) {
          return _this.logging.send('No action for "' + event + '".', 'error');
        }

        callback(response);
      };
    }
  });
});