define('slate-payroll/controllers/login/reset-password', ['exports', 'slate-payroll/mixins/ux-ajax-status-hooks'], function (exports, _uxAjaxStatusHooks) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_uxAjaxStatusHooks.default, {
    showModal: true,
    modalId: 'password-reset-modal',

    actions: {
      resetPassword: function resetPassword() {
        var resetToken = this.get('model'),
            password = this.get('password'),
            passwordConfirm = this.get('passwordConfirm');

        this.ajaxStart();

        if (!password) {
          return this.ajaxError('Please enter a password.');
        }

        if (password !== passwordConfirm) {
          return this.ajaxError('Passwords do not match.');
        }

        Ember.$.post('/api/user/reset-password/' + resetToken, { password: password }).done(Ember.run.bind(this, function () {
          this.ajaxSuccess();
          this.transitionToRoute('login');
        })).fail(this.ajaxError.bind(this));
      }
    }
  });
});