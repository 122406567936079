define('slate-payroll/controllers/account/reminders/index', ['exports', 'slate-payroll/mixins/resource-pagination', 'slate-payroll/mixins/controller-abstractions/delete', 'slate-payroll/mixins/render-tooltips', 'slate-payroll/utils/title-case'], function (exports, _resourcePagination, _delete, _renderTooltips, _titleCase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_resourcePagination.default, _delete.default, _renderTooltips.default, {
    init: function init() {
      this._super.apply(this, arguments);
      this._sortOptions = [{
        label: 'Due Date',
        value: 'next'
      }, 'description', 'code', 'missed', 'employee'];
    },

    queryParams: ['page', 'sortAsc', 'sortBy'],
    enableNotify: true,
    sortBy: 'next',
    sortAsc: false,
    page: 1,
    limit: 20,

    sortOptions: Ember.computed('_sortOptions', function () {
      var sortOptions = this.get('_sortOptions');
      return sortOptions.map(function (option) {
        return typeof option === 'string' ? {
          label: (0, _titleCase.default)([option]),
          value: option
        } : option;
      });
    }),

    reRenderTooltips: Ember.observer('model.[]', function () {
      this._renderTooltips();
    }),

    actions: {
      toggleProperty: function toggleProperty(prop) {
        this.toggleProperty(prop);
      }
    }
  });
});