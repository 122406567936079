define('slate-payroll/routes/account/pay-period/punches/edit', ['exports', 'slate-payroll/mixins/routable-modal'], function (exports, _routableModal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_routableModal.default, {
    beforeModel: function beforeModel() {
      if (this.controllerFor('account.pay-period.punches').get('punchSelection.length') < 1) {
        return this.transitionTo('account.pay-period.punches');
      }
    },
    model: function model() {
      return Ember.RSVP.hash({
        payPeriod: this.modelFor('account.pay-period'),
        paycodes: this.store.findAll('company-paycode')
      });
    },
    willTransitionFromModal: function willTransitionFromModal(controller) {
      if (controller.get('model.isNew')) {
        controller.get('model').destroyRecord();
      }
    },
    afterModalClose: function afterModalClose(controller) {
      controller.set('lockSelection', false);
      this.get('modalClosePromise').then(function () {
        controller.get('punchesController').send('refreshModel');
      });
    }
  });
});