define('slate-payroll/routes/account/automated-paycodes/add', ['exports', 'slate-payroll/mixins/routable-modal'], function (exports, _routableModal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_routableModal.default, {
    model: function model() {
      return Ember.RSVP.hash({
        automatedPaycode: this.store.createRecord('automated-paycode', { company: this.auth.get('currentUser.company') }),
        employees: this.store.query('employee', {
          sort: {
            'name.last': 1,
            'name.first': 1
          },
          $or: [{ terminatedOn: { $not: { $type: 9 } } }, { terminatedOn: { $gt: moment().subtract(1, 'month').toDate() } }, { terminatedOn: null }]
        }),
        companyPaycodes: this.store.query('company-paycode', { sort: { description: 1 } })
      });
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        model: model.automatedPaycode,
        employees: model.employees,
        companyPaycodes: model.companyPaycodes
      });
    },
    willTransitionFromModal: function willTransitionFromModal(controller) {
      if (controller.get('model.isNew')) {
        controller.get('model').destroyRecord();
      }
    },
    afterModalClose: function afterModalClose() {
      this.controllerFor('account.automated-paycodes').send('refresh');
    }
  });
});