define('slate-payroll/routes/account/pay-period/index', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var payPeriod = this.modelFor('account.pay-period');

      return Ember.RSVP.hash({
        payPeriod: payPeriod,
        totals: Ember.$.getJSON('/api/pay-periods/' + payPeriod.get('id') + '?$report=summary'),
        punches: Ember.$.getJSON('/api/punches', {
          payPeriod: payPeriod.get('id'),
          hours: { $gt: 0 },
          select: 'hours isOvertime created timeIn'
        }).then(function (res) {
          var punches = Ember.A(res.punch),
              data = Ember.A([['x'], ['Regular Hours'], ['Overtime']]),
              date = (0, _moment.default)(payPeriod.get('start'));

          var _loop = function _loop() {
            var startOfDate = (0, _moment.default)(date).subtract(1, 'day').endOf('day'),
                endOfDate = (0, _moment.default)(date).endOf('day');

            var hourData = punches.filter(function (p) {
              var d = (0, _moment.default)(Ember.get(p, 'timeIn') || Ember.get(p, 'created'));
              return d.isAfter(startOfDate) && d.isBefore(endOfDate);
            }).map(function (p, i) {
              punches.splice(i, 1);
              return p;
            }).reduce(function (hours, p) {
              hours[Ember.get(p, 'isOvertime') ? 1 : 0] += Ember.get(p, 'hours') || 0;
              return hours;
            }, [0, 0]);

            data[0].push(date.format('YYYY-MM-DD'));
            data[1].push(hourData[0]);
            data[2].push(hourData[1]);

            date.add(1, 'day');
          };

          while (date.isBefore(payPeriod.get('end'))) {
            _loop();
          }

          return data;
        })
      });
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        model: model.payPeriod,
        punches: model.punches,
        totals: model.totals
      });
    }
  });
});