define('slate-payroll/controllers/account/employee/index', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    img: Ember.computed.reads('model.picture'),

    rehireTime: Ember.computed('model', function () {
      var model = this.get('model');
      return (0, _moment.default)(model.rehireDate).isSame((0, _moment.default)(), 'day') ? (0, _moment.default)().add(6, 'minute') : (0, _moment.default)(model.rehireDate);
    }),

    style: Ember.computed('img', function () {
      return Ember.String.htmlSafe('height: 200px; background-image: url("' + this.get('img') + '"); background-repeat: no-repeat; background-size: cover; background-position: center;');
    }),

    actions: {
      toggleProperty: function toggleProperty(prop) {
        this.toggleProperty(prop);
      }
    }
  });
});