define('slate-payroll/models/company', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var attr = _emberData.default.attr;

  exports.default = _emberData.default.Model.extend({
    name: attr('string'),
    tin: attr('string'),
    email: attr('string'),

    addressLine1: attr('string'),
    addressLine2: attr('string'),
    addressCity: attr('string'),
    addressState: attr('string'),
    addressZipcode: attr('string'),
    logo: attr('string'),
    faxNumbers: attr('array'),
    devices: attr('array'),

    loginMessage: attr('string'),
    payPeriod: attr('string'),
    workWeekStart: attr('number'),
    timeMinuteInterval: attr('number'),
    requireSignatures: attr('boolean'),
    useGeolocation: attr('boolean'),
    requireNavLocation: attr('boolean'),
    disableTor: attr('boolean'),
    maxHourEntry: attr('number', { defaultValue: 12 }),
    endOfDayAutoOut: attr('boolean', { defaultValue: false }),
    enableSquares: attr('boolean', { defaultValue: false }),
    requireSquares: attr('boolean', { defaultValue: false }),
    requires2fa: attr('boolean', { defaultValue: false }),
    landOnSquaresLogin: attr('boolean', { defaultValue: false }),
    enablePunchComments: attr('boolean', { defaultValue: false }),
    enablePunchCommentsView: attr('boolean', { defaultValue: false }),
    enableDirectHourEntry: attr('boolean', { defaultValue: false }),
    basicAutoLogout: attr('boolean', { defaultValue: false }),
    allowCustomTimeOff: attr('boolean', { defaultValue: false }),
    timeOfDayTimeOff: attr('boolean', { defaultValue: false }),
    autoPunchOut: attr('boolean', { defaultValue: false }),
    allowBreakBtn: attr('boolean', { defaultValue: false }),
    enablePAS: attr('boolean', { defaultValue: false }),
    tz: attr('string'),
    codeBasedPunch: attr('boolean', { defaultValue: false }),

    ipAddresses: attr('string'),

    incompletePunches: attr('boolean'),

    supervisorChanges: attr('boolean'),
    supervisorReportsOnly: attr('boolean'),

    // Email Settings
    incompletePunchNotificationEmails: attr('array'),
    globalNotificationEmails: attr('array'),

    // Relational
    employees: _emberData.default.hasMany('employee', { async: true }),
    departments: _emberData.default.hasMany('department', { async: true }),
    locations: _emberData.default.hasMany('location', { async: true }),
    companyPaycodes: _emberData.default.hasMany('company-paycode', { async: true }),
    companyDocuments: _emberData.default.hasMany('company-document', {
      async: true,
      inverse: 'company'
    }),
    payPeriods: _emberData.default.hasMany('pay-period', { async: true }),

    // Legacy Fields
    legacyCompanyId: attr('number'),
    legacyUsernamePrefix: attr('string'),
    legacyMaxEFileMonths: attr('number'),

    inactiveOn: attr('date'),
    'time_stamp': attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),

    payPeriodIsSemiMonthly: Ember.computed.equal('payPeriod', 'Semi-Monthly')
  });
});