define('slate-payroll/controllers/account/employee/balances', ['exports', 'slate-payroll/mixins/controller-abstractions/add-edit'], function (exports, _addEdit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_addEdit.default, {
    availablePaycodes: Ember.computed('model.balances.@each.companyPaycode.description', 'paycodes.[]', 'pendingBalance.companyPaycode.description', function () {
      var balances = this.get('model.balances'),
          currentBalanceDesc = this.get('pendingBalance.companyPaycode.description');

      return this.get('paycodes').filter(function (paycode) {
        var desc = paycode.get('description');
        return currentBalanceDesc === desc || !balances.findBy('companyPaycode.description', desc);
      });
    }),

    actions: {

      //We decided that it was causing too many problems with people
      //adding balances.  That's why this is commented out.
      // beginAddingBalance (balance) {
      //   this.set('wasExistingBalance', !!balance);
      //   this.set('pendingBalance', balance || this.store.createRecord('balance'));
      //
      //   if (balance) {
      //     this.set('selectedBalance', balance.get('companyPaycode.id'));
      //   }
      // },

      addPendingBalance: function addPendingBalance() {
        var _this = this;

        var pendingBalance = this.get('pendingBalance');

        pendingBalance.setProperties({
          asOf: new Date(),
          companyPaycode: this.get('paycodes').findBy('id', this.get('selectedBalance'))
        });

        pendingBalance.set('description', pendingBalance.get('companyPaycode.description'));

        this.get('model.balances').addObject(pendingBalance);

        this.saveModel().then(function () {
          _this.get('pendingBalance').destroy();
          _this.set('pendingBalance', null);
        });
      },
      cancelPendingBalance: function cancelPendingBalance() {
        if (this.get('wasExistingBalance')) {
          this.get('pendingBalance').rollbackAttributes();
        } else {
          this.get('pendingBalance').destroy();
        }

        this.set('pendingBalance', null);
      },
      deleteBalance: function deleteBalance(balance) {
        if (!confirm('Are you sure you want to delete that balance?')) {
          return;
        }

        this.get('model.balances').removeObject(balance);
        this.saveModel();
      }
    }
  });
});