define('slate-payroll/components/list-item/diff-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'tr',
    isNewItemInArray: Ember.computed.equal('diff.item.kind', 'N'),
    isNewItem: Ember.computed.equal('diff.kind', 'N'),
    isDeletedItemInArray: Ember.computed('diff.kind', function () {
      return this.get('diff.kind') === 'A' && this.get('diff.item.lhs') && !this.get('diff.item.rhs');
    })
  });
});