define('slate-payroll/mixins/authenticated-route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    beforeModel: function beforeModel(transition) {
      if (!this.auth.get('authenticated')) {
        this.controllerFor('login').set('savedTransition', transition);
        return this.transitionTo('login');
      }

      var session = this.auth.get('content');

      if (session.twoFactorRequired && !session.twoFactorAuthorized && transition.targetName.indexOf('2fa') < 0) {
        this.controllerFor('2fa.verify').set('savedTransition', transition);
        return this.transitionTo('2fa.verify');
      }

      this._super.apply(this, arguments);
    },

    authenticationChanged: Ember.observer('auth.authenticated', function () {
      if (!this.auth.get('authenticated')) {
        this.transitionTo('index');
      }
    })
  });
});