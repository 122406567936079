define('slate-payroll/controllers/account/pay-period', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    router: Ember.inject.service(),

    onPaystub: Ember.computed.equal('router.currentRouteName', 'account.pay-period.paystub'),
    inGracePeriod: Ember.computed('model.{paidOn,end}', function () {
      // The strange graceDay computation below is due to a client set up... see associatedemployers/slate-payroll#907
      var _model = this.model,
          paidOn = _model.paidOn,
          end = _model.end,
          graceDay = paidOn < end || (0, _moment.default)(paidOn).isSame(end, 'day') ? (0, _moment.default)(end).add(3, 'days') : (0, _moment.default)();


      return (0, _moment.default)().isBefore(graceDay.endOf('day'));
    }),

    actions: {
      goBackOne: function goBackOne() {
        return true;
      },
      goForwardOne: function goForwardOne() {
        return true;
      }
    }
  });
});