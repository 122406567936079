define('slate-payroll/serializers/bank-account', ['exports', 'slate-payroll/serializers/application', 'slate-payroll/utils/serialize-object', 'slate-payroll/utils/expand-serialized-object'], function (exports, _application, _serializeObject, _expandSerializedObject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    isNewSerializerAPI: true,

    normalize: function normalize(modelClass, hash) {
      (0, _serializeObject.default)(hash, true, 'ach');
      return this._super.apply(this, arguments);
    },
    serialize: function serialize(snapshot, options) {
      var json = this._super(snapshot, options);

      json._id = json.id;
      delete json.id;

      (0, _expandSerializedObject.default)(json, 'ach');

      return json;
    }
  });
});