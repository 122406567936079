define('slate-payroll/controllers/account/pay-periods/payrolls/new', ['exports', 'slate-payroll/mixins/ux-ajax-status-hooks', 'slate-payroll/mixins/disable-holidays', 'slate-payroll/config/app-data', 'slate-payroll/config/models'], function (exports, _uxAjaxStatusHooks, _disableHolidays, _appData, _models) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var defaultDays = {
    'Weekly': 5,
    'Bi-Weekly': 10,
    'Semi-Monthly': 10.833,
    'Monthly': 21.667
  };

  exports.default = Ember.Controller.extend(_uxAjaxStatusHooks.default, _disableHolidays.default, {
    init: function init() {
      this._super.apply(this, arguments);

      this.selectionOptions = [{
        name: 'Exempt',
        filter: {
          key: 'exempt',
          value: true
        }
      }, {
        name: 'Hourly',
        filter: {
          key: 'exempt',
          value: false
        }
      }, {
        name: 'Part Time',
        filter: {
          key: 'partTime',
          value: true
        }
      }, {
        name: 'Full Time',
        filter: {
          key: 'partTime',
          value: false
        }
      }, {
        name: 'Department',
        modelName: 'department'
      }, {
        name: 'Supervisor',
        modelName: 'employee',
        key: 'supervisor'
      }];

      this._setDaysToPunchDefault();
    },


    enableNotify: true,
    mixpanel: Ember.inject.service(),
    payPeriodController: Ember.inject.controller('account/pay-period'),
    payPeriod: Ember.computed.alias('payPeriodController.model'),
    selectedEmployees: Ember.A(),
    transactions: Ember.A(),

    disableSubmit: Ember.computed('model.paidOn', 'transactions.length', 'working', function () {
      return !this.get('model.paidOn') || !this.get('transactions.length') || this.get('working');
    }),

    selectByValid: Ember.computed('selectBy', 'selectByRecord', function () {
      var selectBy = this.get('selectBy');
      return selectBy && (selectBy.filter || this.get('selectByRecord'));
    }),

    lazyModelSettings: Ember.computed('selectBy.modelName', function () {
      var modelName = this.get('selectBy.modelName');

      if (modelName) {
        var setting = _models.modelSettings[modelName];
        return setting || { label: 'id' };
      }
    }),

    selectByChanged: Ember.observer('selectBy', function () {
      this.set('selectByRecord', undefined);
    }),

    allowSubmit: Ember.computed('selectedEmployees.[]', 'daysToPunch', 'working', 'paycode', function () {
      return !this.get('working') && this.get('selectedEmployees.length') > 0 && this.get('paycode');
    }),

    _setDaysToPunchDefault: function _setDaysToPunchDefault() {
      var frequency = this.auth.get('currentUser.company.payPeriod');
      this.set('daysToPunch', defaultDays[frequency]);
    },

    actions: {
      selectAll: function selectAll() {
        this.get('selectedEmployees').addObjects(this.get('employees'));
      },
      deselectAll: function deselectAll() {
        this.get('selectedEmployees').removeObjects(this.get('selectedEmployees'));
      },
      selectCustom: function selectCustom() {
        var selectBy = this.get('selectBy'),
            selectByRecord = this.get('selectByRecord'),
            selected = this.get('selectedEmployees'),
            employees = this.get('employees');

        if (!selectBy) {
          return;
        }

        if (selectBy.filter) {
          selected.addObjects(employees.filterBy(selectBy.filter.key, selectBy.filter.value));
        } else if (selectBy.modelName && selectByRecord) {
          var id = selectByRecord.get('id');
          selected.addObjects(employees.filter(function (employee) {
            var rel = employee.belongsTo(selectBy.key || selectBy.modelName),
                relValue = rel && rel.value ? rel.value() : rel;
            return relValue && relValue.get('id') === id;
          }));
        }

        this.setProperties({
          selectBy: undefined,
          selectByRecord: undefined
        });
      },
      addTransactions: function addTransactions() {
        var _this = this;

        if (!this.get('allowSubmit')) {
          return;
        }

        var units = this.get('units'),
            employees = this.get('selectedEmployees'),
            paycode = this.get('paycode');

        var mapping = _appData.paycodeUnitMap[paycode.get('unitActual')];

        if ((typeof mapping === 'undefined' ? 'undefined' : _typeof(mapping)) === 'object') {
          mapping = mapping[paycode.get('type')];
        }

        this.get('transactions').addObjects(employees.map(function (employee) {
          var data = {};
          data[mapping] = units;

          return _this.store.createRecord('punch', Ember.$.extend(data, {
            employee: employee,
            companyPaycode: paycode,
            status: 'Manual Transaction',
            transaction: true,
            commentToEmployee: _this.get('commentToEmployee'),
            created: _this.get('created'),
            clientVersion: 'slate-payroll@' + _this.get('buildInfo.version')
          }));
        }));

        this.setProperties({
          units: null,
          paycode: null,
          commentToEmployee: null,
          created: null,
          selectedEmployees: []
        });
      },
      removeTransaction: function removeTransaction(transaction) {
        this.get('transactions').removeObject(transaction);
        transaction.destroyRecord();
      },
      save: function save() {
        var _this2 = this;

        this.ajaxStart();
        var transactions = this.get('transactions'),
            payroll = this.get('model');

        this.mixpanel.trackEvent('Manual Payroll Submission :: pay-period/close', { numberOfTransactions: transactions.get('length') });

        this.set('status', 'Intializing payroll...');

        payroll.save().then(function (payrollRecord) {
          _this2.set('status', 'Saving transactions...');

          return Ember.RSVP.map(transactions, function (transaction) {
            transaction.set('payroll', payrollRecord);
            return transaction.save();
          }).then(function () {
            payrollRecord.setProperties({
              finalized: true,
              finalizedOn: new Date()
            });
            _this2.set('status', 'Finalizing payroll...');
            return payrollRecord.save();
          });
        }).then(function (completedPayroll) {
          _this2.setProperties({
            status: null,
            transactions: Ember.A()
          });
          _this2.ajaxSuccess();
          _this2.transitionToRoute('account.pay-periods.payroll', completedPayroll.get('id'));
        }).catch(function (err) {
          _this2.set('status', null);
          _this2.ajaxError(err);
        });
      }
    }
  });
});