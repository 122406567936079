define('slate-payroll/controllers/account/pay-period/punches/edit', ['exports', 'slate-payroll/config/app-data', 'slate-payroll/mixins/ux-ajax-status-hooks', 'moment'], function (exports, _appData, _uxAjaxStatusHooks, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Controller.extend(_uxAjaxStatusHooks.default, {
    queryParams: ['unitEntry'],
    parentController: Ember.inject.controller('account/pay-period'),
    punchesController: Ember.inject.controller('account/pay-period/punches'),
    editingPunches: Ember.computed.alias('punchesController.punchSelection'),
    showModal: true,
    unitEntry: false,
    timeInterval: 5,
    modalId: 'edit-punches-modal',
    lunchIsOther: Ember.computed.equal('punchLunch', 'other'),
    overrides: Ember.Object.create(), // Stores overriding properties for the edit

    modalTitle: Ember.computed('editingPunches.length', function () {
      return '<h2>Bulk editing ' + this.get('editingPunches.length') + ' punches</h2>';
    }),

    isMultiDay: Ember.computed('overrides.{timeIn,timeOut}', function () {
      var _overrides = this.overrides,
          timeIn = _overrides.timeIn,
          timeOut = _overrides.timeOut;

      return timeIn && timeOut && !(0, _moment.default)(timeOut).isSame(timeIn, 'day');
    }),

    updateLunch: Ember.observer('punchLunch', 'otherPunchLunch', function () {
      var data = this.getProperties('punchLunch', 'otherPunchLunch');
      var lunch = data.punchLunch !== 'other' ? data.punchLunch / 60 : data.otherPunchLunch / 60 || 0;

      this.set('overrides.lunch', lunch.toFixed(2) / 1);
    }),

    resetForm: function resetForm() {
      this.setProperties({
        autoDateOut: undefined,
        punchLunch: undefined,
        other: undefined,
        overrides: Ember.Object.create()
      });
    },
    transitionToParentRoute: function transitionToParentRoute() {
      this.resetForm();
      this.transitionToRoute('account.pay-period.punches', this.get('parentController.model.id'));
    },


    actions: {
      cancel: function cancel() {
        this.transitionToParentRoute();
      },
      savePunch: function savePunch() {
        var _this = this;

        var overrides = this.get('overrides'),
            selected = this.get('editingPunches');

        this.ajaxStart();
        this.set('lockSelection', true);

        if (!selected || selected.get('length') < 1) {
          return this.ajaxError('No punches selected.');
        }

        if (!overrides.get('companyPaycode.id')) {
          return this.ajaxError('No paycode selected.');
        }

        if (this.get('unitEntry')) {
          var paycode = overrides.get('companyPaycode'),
              mapping = _appData.paycodeUnitMap[paycode.get('unitActual')],
              units = this.get('units');

          if (!mapping) {
            return this.ajaxError('Unable to find suitable punch unit type.');
          }

          if ((typeof mapping === 'undefined' ? 'undefined' : _typeof(mapping)) === 'object') {
            mapping = mapping[paycode.get('type')];
          }

          if (typeof mapping === 'function') {
            mapping = mapping(units);
          }

          overrides.setProperties({
            hours: null,
            days: null,
            addEarnings: null,
            deduction: null
          });

          overrides.set(mapping, units);

          if (overrides.get('timeIn')) {
            var time = (0, _moment.default)(overrides.get('timeIn')).hour(12).minute(0).second(0).toDate();
            overrides.setProperties({
              timeIn: time,
              timeOut: time
            });
          }
        }

        Ember.RSVP.all(selected.map(function (punch) {
          punch.setProperties({
            hours: null,
            addEarnings: null,
            deduction: null
          });

          for (var key in overrides) {
            if (!overrides.hasOwnProperty(key)) {
              continue;
            }

            punch.set(key, overrides.get(key));
          }

          return punch.save();
        })).then(function () {
          _this.ajaxSuccess();
          _this.set('units', null);
          _this.transitionToParentRoute();
        });
      }
    }
  });
});