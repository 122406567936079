define('slate-payroll/routes/account/employees/census/results', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    queryParams: { ids: { refreshModel: true } },

    model: function model(_ref) {
      var ids = _ref.ids;

      return this.store.query('employee', { _id: { $in: ids } });
    }
  });
});