define('slate-payroll/serializers/help-article', ['exports', 'slate-payroll/serializers/application', 'slate-payroll/utils/serialize-object', 'slate-payroll/utils/expand-serialized-object'], function (exports, _application, _serializeObject, _expandSerializedObject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    isNewSerializerAPI: true,

    normalize: function normalize(modelClass, hash) {
      (0, _serializeObject.default)(hash, true, 'votes');
      return this._super.apply(this, arguments);
    },
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);
      (0, _expandSerializedObject.default)(json, 'votes');
      return json;
    }
  });
});