define('slate-payroll/routes/account/company-documents', ['exports', 'slate-payroll/mixins/refreshable'], function (exports, _refreshable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_refreshable.default, {
    titleToken: 'Company Documents',

    queryParams: {
      page: { refreshModel: true },
      sortAsc: { refreshModel: true }
    },

    model: function model(params) {
      var page = params.page - 1;
      var query = {
        page: page || 0,
        limit: this.get('controller.limit') || 10,
        sort: { created: params.sortAsc ? -1 : 1 }
      };

      return this.store.query('company-document', query);
    },


    actions: {
      deleteDocument: function deleteDocument(companyDocument) {
        var _this = this;

        companyDocument.destroyRecord().then(function () {
          return _this.refresh();
        });
      }
    }
  });
});