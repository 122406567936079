define('slate-payroll/controllers/index', ['exports', 'slate-payroll/mixins/scroll-reveal', 'slate-payroll/mixins/ux-ajax-status-hooks'], function (exports, _scrollReveal, _uxAjaxStatusHooks) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_scrollReveal.default, _uxAjaxStatusHooks.default, {
    hasEmail: Ember.computed.bool('email'),
    hasCompanyName: Ember.computed.bool('companyName'),
    formIsValid: Ember.computed.and('hasEmail', 'hasCompanyName'),

    actions: {
      scrolly: function scrolly() {
        Ember.$('html, body').animate({ scrollTop: Ember.$('.sign-up-hero').offset().top - 85 });
      },


      sendSignup: function sendSignup() {
        var _this = this;

        this.ajaxStart();

        Ember.$.post('/api/contact/', {
          email: this.get('email'),
          name: this.get('companyName')
        }).done(function () {
          Ember.run.bind(_this, _this.ajaxSuccess('Successfully sent. We\'ll be in touch soon'));
        }).fail(this.ajaxError.bind(this));
      }
    }
  });
});