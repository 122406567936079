define('slate-payroll/models/signature', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = _emberData.default.Model.extend({
    uri: attr('string'),
    employee: belongsTo('employee', {
      async: true,
      inverse: false
    }),
    created: attr('date')
  });
});