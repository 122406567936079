define('slate-payroll/routes/account/help/category/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var category = this.modelFor('account.help.category'),
          categories = this.modelFor('account.help');

      return Ember.RSVP.hash({
        category: category,
        categories: categories ? categories.filter(function (cat) {
          return cat.get('id') !== category.get('id');
        }) : categories,
        articles: this.store.query('help-article', { category: category.get('id') })
      });
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        model: model.category,
        categories: model.categories,
        articles: model.articles
      });
    }
  });
});