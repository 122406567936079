define('slate-payroll/components/list-item/employee-grid-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var EmployeeGridItemComponent = Ember.Component.extend({
    img: Ember.computed('employee.{picture,gravatarUrl}', function () {
      var _get$getProperties = this.get('employee').getProperties('gravatarUrl', 'picture'),
          gravatarUrl = _get$getProperties.gravatarUrl,
          picture = _get$getProperties.picture;

      return picture || gravatarUrl || '/assets/images/no-user-thumbnail.jpg';
    }),

    style: Ember.computed('img', function () {
      return Ember.String.htmlSafe('background-image: url(\'' + this.get('img') + '\');background-repeat:no-repeat;background-position:center;background-size:contain;');
    })
  });

  EmployeeGridItemComponent.reopenClass({ positionalParams: ['employee'] });

  exports.default = EmployeeGridItemComponent;
});