define('slate-payroll/controllers/account/automated-paycodes/edit', ['exports', 'slate-payroll/mixins/ux-ajax-status-hooks'], function (exports, _uxAjaxStatusHooks) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_uxAjaxStatusHooks.default, {
    showModal: true,
    modalId: 'edit-automated-paycode-modal',
    modalTitle: '<h2><i class="fa fa-fw fa-pencil"></i> Edit Automated Paycode</h2>',
    parentController: Ember.inject.controller('account/automated-paycodes'),
    employeesSelection: Ember.A(),

    setEmployeeSelection: Ember.observer('setEmployees', function () {
      var _this = this;

      if (this.get('setEmployees')) {
        this.set('setEmployees', false);
      } else {
        return;
      }

      Ember.run.scheduleOnce('afterRender', function () {
        _this.get('model.employees').then(function (employees) {
          _this.set('employeesSelection', Ember.A(employees.toArray()));
        });
      });
    }),

    actions: {
      cancel: function cancel() {
        this.get('model').rollbackAttributes();
        this.transitionToRoute('account.automated-paycodes');
      },
      selectAll: function selectAll() {
        this.set('employeesSelection', Ember.A(this.get('employees').toArray()));
      },
      deselectAll: function deselectAll() {
        this.get('employeesSelection').clear();
      },
      saveAutomatedPaycode: function saveAutomatedPaycode() {
        var _this2 = this;

        var paycode = this.get('model');

        this.ajaxStart();

        paycode.set('employees', this.get('employeesSelection'));

        paycode.save().then(function () {
          _this2.ajaxSuccess();
          _this2.transitionToRoute('account.automated-paycodes');
        }).catch(this.ajaxError.bind(this));
      }
    }
  });
});