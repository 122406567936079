define('slate-payroll/controllers/account/index/clock-map', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.employees = [];
      this.defaultMapCenter = {
        lat: 45.787636,
        lng: -108.489304
      };
      this.rangeOptions = [{
        value: 24,
        label: '24 Hours'
      }, {
        value: 72,
        label: '3 Days'
      }, {
        value: 168,
        label: 'Week'
      }, {
        value: 720,
        label: 'Month'
      }];
    },

    queryParams: ['range', 'employees', 'heatmap', 'filterByEmployees'],
    range: 24,
    heatmap: false,
    selectedEmployees: Ember.A(),
    filterByEmployees: false,

    selectedRange: Ember.computed('range', 'rangeOptions', function () {
      return this.get('rangeOptions').findBy('value', this.get('range'));
    }),

    heatmapData: Ember.computed('model', '_employees', function () {
      if (!this.get('_employees')) {
        return false;
      }

      var punches = this.get('model');

      var heatMapArray = [];

      punches.forEach(function (punch) {
        var geoOut = punch.getProperties('timeOutGeoLat', 'timeOutGeoLng');

        heatMapArray.push(new google.maps.LatLng(punch.get('timeInGeoLat'), punch.get('timeInGeoLng')));

        if (geoOut.timeOutGeoLat && geoOut.timeOutGeoLng) {
          heatMapArray.push(new google.maps.LatLng(geoOut.timeOutGeoLat, geoOut.timeOutGeoLng));
        }
      });

      return heatMapArray.length > 0 ? heatMapArray : false;
    }),

    markers: Ember.computed('model', '_employees', function () {
      if (!this.get('_employees')) {
        return false;
      }

      var punches = this.get('model');

      var markersArray = [];

      punches.forEach(function (punch) {
        var punchEmployee = punch.get('employee'),
            label = punchEmployee.get('firstName').charAt(0),
            hasTimeOutData = punch.get('timeOutGeoLat') && punch.get('timeOutGeoLng');

        // haversine formula
        var lat = punch.get('timeOutGeoLat') - punch.get('timeInGeoLat'),
            lon = punch.get('timeOutGeoLng') - punch.get('timeInGeoLng'),
            a = Math.sin(lat / 2) * Math.sin(lat / 2) + Math.cos(punch.get('timeOutGeoLat')) * Math.cos(punch.get('timeInGeoLat')) * Math.sin(lon / 2) * Math.sin(lon / 2),
            c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)),
            milesDiff = Math.round(1000 * (3961 * c)) / 1000;

        var titleSegment = hasTimeOutData ? 'C' : 'Incomplete c';

        markersArray.push({
          label: label,
          position: {
            lat: punch.get('timeInGeoLat'),
            lng: punch.get('timeInGeoLng')
          },
          title: titleSegment + 'lock in by ' + punchEmployee.get('initials') + ' at ' + (0, _moment.default)(punch.get('timeIn')).format('h:mma on M/D')
        });

        if (hasTimeOutData) {
          markersArray.forEach(function () {
            if (milesDiff < 0.075) {
              markersArray.pop();
              markersArray.push({
                label: label,
                position: {
                  lat: punch.get('timeOutGeoLat'),
                  lng: punch.get('timeOutGeoLng')
                },
                title: 'Clock in at ' + (0, _moment.default)(punch.get('timeIn')).format('h:mma on M/D') + ' /out at ' + (0, _moment.default)(punch.get('timeOut')).format('h:mma on M/D') + ' by ' + punchEmployee.get('initials')
              });
            } else {
              markersArray.push({
                label: label,
                position: {
                  lat: punch.get('timeOutGeoLat'),
                  lng: punch.get('timeOutGeoLng')
                },
                title: 'Clock out by ' + punchEmployee.get('initials') + ' at ' + (0, _moment.default)(punch.get('timeOut')).format('h:mma on M/D')
              });
            }
          });
        }
      });

      return markersArray.length > 0 ? markersArray : false;
    }),

    setEmployeeFilter: Ember.observer('filterByEmployees', 'selectedEmployees.[]', function () {
      if (!this.get('filterByEmployees')) {
        return this.set('employees', []);
      }

      this.set('employees', this.get('selectedEmployees').mapBy('id'));
    }),

    actions: {
      toggleProperty: function toggleProperty(prop) {
        this.toggleProperty(prop);
      },
      setRange: function setRange(range) {
        this.set('range', range);
      },
      selectAll: function selectAll() {
        this.get('selectedEmployees').addObjects(this.get('_employees'));
      },
      deselectAll: function deselectAll() {
        this.set('selectedEmployees', Ember.A());
      }
    }
  });
});