define('slate-payroll/routes/account/help', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    titleToken: 'Help Center',

    model: function model() {
      return this.store.query('help-category', { 'articles.0': { $exists: true } });
    }
  });
});