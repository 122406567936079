define('slate-payroll/routes/account/employee/paystub', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return this.store.find('paystub', params.paystub_id);
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      var paystubsIndexController = this.controllerFor('account.employee.paystubs');

      if (paystubsIndexController.get('printOnInit')) {
        paystubsIndexController.set('printOnInit', undefined);
        Ember.run.scheduleOnce('afterRender', this, function () {
          controller.send('print', function () {
            this.transitionToRoute('account.employee.paystubs');
          });
        });
      }

      if (paystubsIndexController.get('downloadOnInit')) {
        paystubsIndexController.set('downloadOnInit', undefined);
        Ember.run.scheduleOnce('afterRender', this, function () {
          controller.send('download', function () {
            this.transitionToRoute('account.employee.paystubs');
          });
        });
      }
    }
  });
});