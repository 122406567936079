define('slate-payroll/components/visualizations/organization-tree-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var OrganizationTreeListComponent = Ember.Component.extend({
    tagName: 'table',
    classNames: ['pas', 'table', 'table-hover', 'table-striped', 'table-responsive']
  });

  OrganizationTreeListComponent.reopenClass({ positionalParams: ['list'] });

  exports.default = OrganizationTreeListComponent;
});