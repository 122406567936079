define('slate-payroll/controllers/account/user/edit', ['exports', 'slate-payroll/mixins/controller-abstractions/add-edit'], function (exports, _addEdit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_addEdit.default, {
    init: function init() {
      this._super.apply(this, arguments);
      this.sortPermissions = ['prettyName'];
    },

    transitionAfterSave: 'account.users.index',
    transitionWithModel: false,
    sortedPermissions: Ember.computed.sort('permissions', 'sortPermissions'),

    actions: {
      selectAll: function selectAll(modelKey, arrayKey) {
        this.model.get(modelKey).addObjects(this.get(arrayKey));
      },
      deselectAll: function deselectAll(modelKey) {
        this.model.get(modelKey).clear();
      }
    }
  });
});