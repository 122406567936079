define('slate-payroll/utils/serialize-object', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = serializeObject;

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  function upperCaseFirst(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  function serializeObject(object, prefix) {
    var args = Array.prototype.slice.call(arguments, 2);

    if (!object || (typeof object === 'undefined' ? 'undefined' : _typeof(object)) !== 'object') {
      return object;
    }

    var setInside = function setInside(o, a) {
      for (var key in o) {
        if (!o.hasOwnProperty(key)) {
          continue;
        }

        if (_typeof(o[key]) === 'object') {
          setInside(o[key], a ? a + upperCaseFirst(key) : key);
          continue;
        }

        var modifiedKey = a ? a + upperCaseFirst(key) : key;
        object[modifiedKey] = o[key];
      }
      return object;
    };

    args.forEach(function (objectKey) {
      if (!object[objectKey] || _typeof(object[objectKey]) !== 'object') {
        return;
      }

      setInside(object[objectKey], prefix ? objectKey : null);
      delete object[objectKey];
    });

    return object;
  }
});