define('slate-payroll/routes/account/data-explorer/explore/reports/parameters', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return this.store.find('query', params.id);
    },
    resetController: function resetController(controller) {
      controller.set('model', undefined);
    },
    afterModel: function afterModel(model) {
      if (!model) {
        return this.transitionTo('account.data-explorer.explore.reports.index');
      }
    }
  });
});