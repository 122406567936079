define('slate-payroll/controllers/account/automated-paycodes/add', ['exports', 'slate-payroll/mixins/ux-ajax-status-hooks'], function (exports, _uxAjaxStatusHooks) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_uxAjaxStatusHooks.default, {
    showModal: true,
    modalId: 'add-automated-paycode-modal',
    modalTitle: '<h2><i class="fa fa-fw fa-plus"></i> Add Automated Paycode<br /><small class="text-sm">Automated Paycodes are helpful specifications for what gets added or deducted each pay period</small></h2>',

    actions: {
      cancel: function cancel() {
        this.transitionToRoute('account.automated-paycodes');
      },
      selectAll: function selectAll() {
        this.get('model.employees').addObjects(this.get('employees'));
      },
      deselectAll: function deselectAll() {
        this.get('model.employees').clear();
      },
      saveAutomatedPaycode: function saveAutomatedPaycode() {
        var _this = this;

        var paycode = this.get('model');

        this.ajaxStart();

        paycode.save().then(function () {
          _this.ajaxSuccess();
          _this.transitionToRoute('account.automated-paycodes');
        }).catch(this.ajaxError.bind(this));
      }
    }
  });
});