define('slate-payroll/models/square', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var attr = _emberData.default.attr;

  exports.default = _emberData.default.Model.extend({
    customName: attr('string'),
    serial: attr('string'),
    autoPunch: attr('boolean'),

    employee: _emberData.default.belongsTo('employee', {
      async: true,
      inverse: false
    }),
    company: _emberData.default.belongsTo('company', {
      async: true,
      inverse: false
    }),

    lastUsed: attr('date'),
    created: attr('date')
  });
});