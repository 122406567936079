define('slate-payroll/components/time-off/condensed-request-item', ['exports', 'moment', 'slate-payroll/mixins/render-tooltips'], function (exports, _moment, _renderTooltips) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_renderTooltips.default, {
    tagName: 'li',
    classNames: ['tor-condensed__component'],

    requestedPaycode: Ember.computed('request.{balanceDescription,companyPaycode.description}', function () {
      var request = this.get('request'),
          explicitDesc = request.get('balanceDescription');
      return explicitDesc ? explicitDesc : request.get('companyPaycode.description') ? request.get('companyPaycode.description') : '';
    }),

    daysTooltipContent: Ember.computed('request.days.@each.{date,end}', function () {
      return 'Days in this request:\n' + (this.get('request.days') ? this.get('request.days').reduce(function (accumulator, day) {
        return accumulator.concat((0, _moment.default)(day.date).format('dddd MM/DD/YYYY') + (day.end ? ' ' + (0, _moment.default)(day.date).format('h:mma') + ' - ' + (0, _moment.default)(day.end).format('h:mma') : ''));
      }, []).join('\n') : 'None');
    }),

    actions: {
      toggleProperty: function toggleProperty(prop) {
        this.toggleProperty(prop);
      },
      approve: function approve() {
        this.get('approve').apply(undefined, arguments);
      },
      reject: function reject() {
        this.get('reject').apply(undefined, arguments);
      },
      delete: function _delete() {
        this.get('delete').apply(undefined, arguments);
      }
    }
  });
});