define('slate-payroll/helpers/round-to', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.roundTo = roundTo;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  /*
  decimalPlace value dictates at which decimal will be rounded
  hundred's place = -2
  ten's place = -1
  one's place = 0
  tenth's place = 1
  hundreth's place = 2
  etc...
  */

  function roundTo(_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        number = _ref2[0],
        decimalPlace = _ref2[1];

    var factor = Math.pow(10, decimalPlace);
    return Math.round(number * factor) / factor;
  }

  exports.default = Ember.Helper.helper(roundTo);
});