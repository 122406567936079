define('slate-payroll/controllers/account/time-off/index', ['exports', 'slate-payroll/mixins/controller-abstractions/add-edit', 'slate-payroll/mixins/confirm', 'slate-payroll/utils/title-case'], function (exports, _addEdit, _confirm, _titleCase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_addEdit.default, _confirm.default, {
    queryParams: ['month', 'year', 'sortBy', 'sortAsc', 'preserveScrollPosition'],
    pendingRequests: Ember.computed.filter('model', function (item) {
      return !item.get('reviewedOn');
    }),
    approvedRequests: Ember.computed.filter('model', function (request) {
      return request.get('approved');
    }),
    month: null,
    year: null,
    sortBy: 'created',
    sortAsc: true,
    preserveScrollPosition: true,

    _sortOptions: ['created', {
      label: 'Date in Request',
      value: 'days.date'
    }],

    sortOptions: Ember.computed('_sortOptions', function () {
      var sortOptions = this.get('_sortOptions');
      return sortOptions.map(function (option) {
        return typeof option === 'string' ? {
          label: (0, _titleCase.default)([option]),
          value: option
        } : option;
      });
    }),

    subscribeUrl: Ember.computed('supervisor', function () {
      var company = this.get('auth.currentUser.company.id');
      return 'webcal://slatepayroll.com/api/calendar/company/' + company + '/calendar.ical';
    }),

    afterSave: function afterSave() {
      this.send('refresh');
    },


    actions: {
      approveRequest: function approveRequest(request, immediate) {
        var _this = this;

        if (immediate && request.get('balanceDescription')) {
          this.confirm('Are you sure you want to approve this request?', 'Are you sure?', 'basicConfirmState').then(function (ok) {
            if (ok) {
              request.setProperties({
                approved: true,
                reviewedOn: new Date()
              });
              _this.send('save', request);
            }
          });
        } else {
          this.transitionToRoute('account.time-off.approve', request.get('id'));
        }
      },
      rejectRequest: function rejectRequest(request) {
        var _this2 = this;

        this.confirm(null, 'Reject Request').then(function (confirmed) {
          var supervisorNotes = _this2.get('rejectionReason');
          _this2.set('rejectionReason', null);

          if (!confirmed) {
            return;
          }

          request.setProperties({
            supervisorNotes: supervisorNotes,
            approved: false,
            reviewedOn: new Date()
          });
          _this2.send('save', request);
        });
      },


      toggleProperty: function toggleProperty(prop) {
        this.toggleProperty(prop);
      }
    }
  });
});