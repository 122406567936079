define('slate-payroll/routes/account/company-documents/add', ['exports', 'slate-payroll/mixins/routable-modal'], function (exports, _routableModal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_routableModal.default, {
    model: function model() {
      return Ember.RSVP.hash({
        companyDocument: this.store.createRecord('company-document', { company: this.auth.get('currentUser.company') }),
        employees: this.store.query('employee', { terminatedOn: { $not: { $type: 9 } } })
      });
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        model: model.companyDocument,
        employees: model.employees
      });
    },
    willTransitionFromModal: function willTransitionFromModal(controller) {
      if (controller.get('model.isNew')) {
        controller.get('model').destroyRecord();
      }
    },
    afterModalClose: function afterModalClose() {
      this.controllerFor('account.company-documents').send('refresh');
    }
  });
});