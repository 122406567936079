define('slate-payroll/utils/expand-serialized-object', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = serializeObject;

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  function _findMatchingArg(key, args) {
    var matchingArg;

    for (var i = 0; i < args.length; i++) {
      var pathPrefix = args[i];

      if (key.indexOf(pathPrefix) > -1) {
        matchingArg = pathPrefix;
        break;
      }
    }

    return matchingArg;
  }

  function _lowerCaseFirst(str) {
    return str.charAt(0).toLowerCase() + str.slice(1);
  }

  function _remapKeys(object, newKey, matchingKeys) {
    object[newKey] = {};

    matchingKeys.forEach(function (oldKey) {
      object[newKey][_lowerCaseFirst(oldKey.replace(newKey, ''))] = object[oldKey];
      delete object[oldKey];
    });
  }

  function serializeObject(object) {
    var args = Array.prototype.slice.call(arguments, 1);

    if (!object || (typeof object === 'undefined' ? 'undefined' : _typeof(object)) !== 'object') {
      return object;
    }

    var objectKeys = Object.keys(object);

    objectKeys.forEach(function (key) {
      var matchingArg = _findMatchingArg(key, args);

      if (!matchingArg) {
        return;
      }

      args.splice(args.indexOf(matchingArg), 1);

      var matchingKeys = objectKeys.filter(function (_key) {
        return _key.indexOf(matchingArg) > -1;
      });

      _remapKeys(object, matchingArg, matchingKeys);
    });

    return object;
  }
});