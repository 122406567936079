define('slate-payroll/controllers/account/data-explorer/explore/reports/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    reports: Ember.computed('model.@each.name', function () {
      return this.get('model').sortBy('name');
    })
  });
});