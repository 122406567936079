define('slate-payroll/controllers/account/help/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['s'],
    s: '',

    searchTextChanged: Ember.observer('s', function () {
      var _this = this;

      var sTimeout = this.get('_searchTextTimeout');

      if (sTimeout) {
        Ember.run.cancel(sTimeout);
      }

      if (!this.get('s')) {
        return;
      }

      this.set('_searchTextTimeout', Ember.run.later(function () {
        _this.send('refreshModel');
      }, 1500));
    })
  });
});