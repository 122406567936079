define('slate-payroll/serializers/time-off-request', ['exports', 'slate-payroll/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    normalize: function normalize(modelClass, hash) {
      if (modelClass.modelName !== 'time-off-request') {
        return this._super.apply(this, arguments);
      }

      if (hash.days) {
        hash.days = hash.days.map(function (day) {
          day.date = new Date(day.date);
          return day;
        });
      }

      return this._super.apply(this, arguments);
    },
    serialize: function serialize(snapshot) {
      var approvedWasSet = typeof snapshot.attr('approved') === 'boolean',
          json = this._super.apply(this, arguments);

      if (!approvedWasSet) {
        json.approved = undefined;
      }

      return json;
    }
  });
});