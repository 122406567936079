define('slate-payroll/controllers/account/company-documents/add', ['exports', 'slate-payroll/mixins/ux-ajax-status-hooks', 'slate-payroll/config/models'], function (exports, _uxAjaxStatusHooks, _models) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_uxAjaxStatusHooks.default, {
    init: function init() {
      this._super.apply(this, arguments);
      this.documentFileExtensions = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'csv', 'rtf', 'txt'];
      this.selectionOptions = [{
        name: 'Exempt',
        filter: {
          key: 'exempt',
          value: true
        }
      }, {
        name: 'Hourly',
        filter: {
          key: 'exempt',
          value: false
        }
      }, {
        name: 'Part Time',
        filter: {
          key: 'partTime',
          value: true
        }
      }, {
        name: 'Full Time',
        filter: {
          key: 'partTime',
          value: false
        }
      }, {
        name: 'Department',
        modelName: 'department'
      }, {
        name: 'Supervisor',
        query: { terminatedOn: { $not: { $type: 9 } } },
        modelName: 'employee',
        key: 'supervisor'
      }];
    },


    showModal: true,
    enableNotify: true,
    modalId: 'add-company-document-modal',
    modalTitle: '<h2><i class="fa fa-fw fa-plus"></i> Add Company Document</h2>',

    // BROWSER POLYFILL WARNING:
    // The FormData Object is only available to following browsers:
    // IE 10+, Firefox 4.0+, Chrome 7+, Safari 5+, Opera 12+
    // Will need a polyfill to support less than versions
    uploadFile: function uploadFile(file, documentId) {
      var _this = this;

      var formData = new FormData();
      formData.append('file', file);

      return new Ember.RSVP.Promise(function (resolve, reject) {
        Ember.$.ajax({
          type: 'POST',
          url: '/api/company-documents/' + documentId,
          data: formData,
          processData: false,
          contentType: false,
          cache: false,
          xhr: function xhr() {
            var xhr = new window.XMLHttpRequest();

            xhr.upload.addEventListener('progress', function (evt) {
              if (evt && evt.lengthComputable) {
                _this.set('uploadProgress', Math.round(evt.loaded / evt.total * 100));
              }
            }, false);

            return xhr;
          },
          success: resolve,
          error: reject
        });
      });
    },


    selectByValid: Ember.computed('selectBy', 'selectByRecord', function () {
      var selectBy = this.get('selectBy');
      return selectBy && (selectBy.filter || this.get('selectByRecord'));
    }),

    lazyModelSettings: Ember.computed('selectBy.modelName', function () {
      var modelName = this.get('selectBy.modelName');

      if (modelName) {
        var setting = _models.modelSettings[modelName];
        return setting || { label: 'id' };
      } else {
        return null;
      }
    }),

    selectByChanged: Ember.observer('selectBy', function () {
      this.set('selectByRecord', undefined);
    }),

    actions: {
      selectAllEmployees: function selectAllEmployees() {
        this.get('model.forEmployees').addObjects(this.get('employees'));
      },
      deselectAllEmployees: function deselectAllEmployees() {
        this.set('model.forEmployees', Ember.A());
      },
      selectCustomEmployees: function selectCustomEmployees() {
        var selectBy = this.get('selectBy'),
            selectByRecord = this.get('selectByRecord'),
            selected = this.get('model.forEmployees'),
            employees = this.get('employees');

        if (!selectBy) {
          return;
        }

        if (selectBy.filter) {
          selected.addObjects(employees.filterBy(selectBy.filter.key, selectBy.filter.value));
        } else if (selectBy.modelName && selectByRecord) {
          var id = selectByRecord.get('id');
          selected.addObjects(employees.filter(function (employee) {
            var rel = employee.belongsTo(selectBy.key || selectBy.modelName),
                relValue = rel && rel.value ? rel.value() : rel;
            return relValue && relValue.get('id') === id;
          }));
        }

        this.setProperties({
          selectBy: undefined,
          selectByRecord: undefined
        });
      },
      cancel: function cancel() {
        this.transitionToRoute('account.company-documents');
      },
      saveDocument: function saveDocument() {
        var _this2 = this;

        var companyDocument = this.get('model'),
            file = this.get('file.firstObject');

        this.ajaxStart();

        companyDocument.save().then(function (doc) {
          return file ? _this2.uploadFile(file, doc.get('id')) : Ember.RSVP.Promise.resolve();
        }).then(function () {
          _this2.ajaxSuccess();
          _this2.transitionToRoute('account.company-documents');
        }).catch(this.ajaxError.bind(this));
      }
    }
  });
});