define('slate-payroll/routes/account/employee/paystubs', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Route.extend({
    model: function model() {
      var _modelFor = this.modelFor('account.employee'),
          employee = _modelFor.employee,
          canDel = this.get('canDeletePaystub');

      return Ember.RSVP.hash({
        paystubs: this.store.query('paystub', {
          employee: employee.get('id'),
          payrollDate: { $gte: (0, _moment.default)(new Date()).subtract(3, 'year').toDate() },
          sort: { 'payrollDate': -1 }
        }),
        employee: employee,
        canDelete: (typeof canDel === 'undefined' ? 'undefined' : _typeof(canDel)) === Boolean ? canDel : this.auth.hasPermission('DELETE_PAYSTUB')
      });
    },
    setupController: function setupController(controller, model) {
      this.set('canDeletePaystub', !!model.canDelete);
      controller.setProperties({
        model: model.paystubs,
        employee: model.employee,
        canDeletePaystub: this.get('canDeletePaystub')
      });
    },


    actions: {
      refresh: function refresh() {
        this.refresh();
      }
    }

  });
});