define('slate-payroll/routes/account/pay-period/close/incomplete-punches/index', ['exports', 'slate-payroll/mixins/refreshable'], function (exports, _refreshable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_refreshable.default, {
    model: function model() {
      return this.store.query('punch', {
        payPeriod: this.modelFor('account.pay-period').get('id'),
        $and: [{ timeIn: { $exists: true } }, { timeIn: { $type: 9 } }],
        $or: [{ timeOut: { $type: 10 } }, { timeOut: { $exists: false } }, { needsReview: true }]
      });
    }
  });
});