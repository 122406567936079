define('slate-payroll/components/ip-restriction-setting', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    useCompanyIpAddresses: Ember.computed('employee.ipAddresses', function () {
      var ipAddresses = this.get('employee.ipAddresses');

      return (ipAddresses || []).length < 1 ? true : false;
    }),

    companyIpUsageDidChange: Ember.observer('useCompanyIpAddresses', function () {
      var ipAddresses = this.get('employee.ipAddresses'),
          savedIpAddresses = this.get('_ipAddressesStateSave');

      if (this.get('useCompanyIpAddresses') && ipAddresses) {
        this.set('_ipAddressesStateSave', ipAddresses);
        this.set('employee.ipAddresses', null);
      } else if (!this.get('useCompanyIpAddresses') && savedIpAddresses) {
        this.set('employee.ipAddresses', savedIpAddresses);
      }
    })
  });
});