define('slate-payroll/components/form-footer', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['form-group'],
    icon: 'fa-check',
    buttonText: 'Save',
    buttonClass: 'btn btn-success btn-block btn-lg',
    _disabled: Ember.computed.or('loading', 'disabled')
  });
});