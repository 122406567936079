define('slate-payroll/routes/account/messaging/compose', ['exports', 'slate-payroll/mixins/route-abstractions/add'], function (exports, _add) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_add.default, {
    modelName: 'message',

    deactivate: function deactivate() {
      var controller = this.controllerFor('account.messaging.compose');

      controller.set('files', Ember.A());
      controller.set('hasFiles', null);
    }
  });
});