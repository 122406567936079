define('slate-payroll/controllers/account/departments/add', ['exports', 'slate-payroll/mixins/controller-abstractions/add-edit'], function (exports, _addEdit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_addEdit.default, {
    transitionAfterSave: 'account.departments',
    transitionWithModel: false
  });
});