define('slate-payroll/controllers/unauthorized', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    loginController: Ember.inject.controller('login'),

    unauthorizedReason: Ember.computed('fromError', function () {
      var error = this.get('fromError');

      if (!error) {
        return 'Undefined Error.';
      }

      var sessionExpires = this.auth.get('content.expires');

      if ((0, _moment.default)(sessionExpires).isBefore((0, _moment.default)())) {
        this.get('loginController').set('fromError', 'Your session has expired. Please log in again.');
        this.transitionToRoute('login');
        return 'Your session has expired.';
      }

      return error.responseText ? 'The server says: ' + error.responseText : 'The server says: ' + error.statusText;
    })
  });
});