define('slate-payroll/models/pending-record', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    name: (0, _attr.default)('string'),
    serializedData: (0, _attr.default)('string'),
    deserializedData: (0, _attr.default)(),
    recordType: (0, _attr.default)('string'),
    meta: (0, _attr.default)(),

    company: (0, _relationships.belongsTo)('company'),
    creator: (0, _relationships.belongsTo)('user'),

    created: (0, _attr.default)('date', { defaultValue: function defaultValue() {
        return new Date();
      } })
  });
});