define('slate-payroll/components/visualizations/d3-force', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['d3-visualization', 'd3-force'],

    width: 960,
    height: 500,
    dragging: false,
    linkLabels: true,

    selectedNode: null,
    selectedLink: null,
    mousedownLink: null,
    mousedownNode: null,
    mouseupNode: null,

    initializeProperties: function initializeProperties() {
      this._initialize('charge', -1000);
      this._initialize('linkDistance', 50);
      this._initialize('linkLabels', true);
      this._initialize('gravity', null);
      this._initialize('friction', null);
      this._initialize('nodes', []);
      this._initialize('links', []);
      this._nodeMap = {};
      this._nodeList = [];
      this._linkList = [];
    },
    bindProperties: function bindProperties() {
      this._bindProperty('charge', this.forcePropertyUpdate);
      this._bindProperty('linkDistance', this.forcePropertyUpdate);
      this._bindProperty('gravity', this.forcePropertyUpdate);
      this._bindProperty('friction', this.forcePropertyUpdate);
      this._bindProperty('linkLabels', this.forcePropertyUpdate);
      this._bindProperty('nodes.@each', this.graphPropertyUpdate);
      this._bindProperty('links.@each', this.graphPropertyUpdate);
    },
    _bindProperty: function _bindProperty(key, method) {
      this.addObserver(key, this, method);
      this.on('willDestroyElement', this, function () {
        this.removeObserver(key, this, method);
      });
    },
    _initialize: function _initialize(key, value) {
      if (!this.get(key)) {
        this.set(key, value);
      }
    },
    didInsertElement: function didInsertElement() {
      this.initializeProperties();
      this.bindProperties();
      this.dataUpdate();
      this.setupGraph();
    },
    updateNodeProperty: function updateNodeProperty(node, propertyName, property) {
      if (!(propertyName in node) && property) {
        node[propertyName] = property;
      }
    },
    updateNode: function updateNode(node, data) {
      node.name = data.name;
      node.text = data.text ? data.text : data.name;
      this.updateNodeProperty(node, 'x', data.x);
      this.updateNodeProperty(node, 'y', data.y);
    },
    spliceLinksForNode: function spliceLinksForNode(node) {
      this.toSplice = this.get('links').filter(function (l) {
        return l.source === node || l.target === node;
      });
    },
    dataUpdate: function dataUpdate() {
      // calculate the node diff, while performing an update
      var retainKeys = {};
      var ind = 0;
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = this.get('nodes')[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var node = _step.value;

          if (node.name) {
            if (!(node.name in this._nodeMap)) {
              var newNode = Ember.$.extend(node, {
                name: node.name,
                originalIndex: ind
              });
              this._nodeMap[node.name] = newNode;
              this._nodeList.push(newNode);
            }
            this.updateNode(this._nodeMap[node.name], node);
            retainKeys[node.name] = true;
          }
          ind++;
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      var newList = [];
      for (var nodeName in this._nodeMap) {
        if (!(nodeName in retainKeys)) {
          delete this._nodeMap[nodeName];
        } else {
          newList.push(this._nodeMap[nodeName]);
        }
      }

      this._nodeList = newList;
      this._linkList = [];
      var idx = 0;
      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = this.get('links')[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var edge = _step2.value;

          if (edge.source in this._nodeMap && edge.target in this._nodeMap) {
            this._linkList.push({
              source: this._nodeMap[edge.source],
              target: this._nodeMap[edge.target],
              type: edge.type
            });
          }
          idx++;
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }
    },
    clearList: function clearList() {
      this._nodeList = [];
      this.redraw();
    },
    setForceProperties: function setForceProperties() {
      this.force.charge(this.get('charge'));
      this.force.linkDistance(this.get('linkDistance'));
      if (this.get('friction')) {
        this.force.friction(this.get('friction'));
      }
      if (this.get('gravity')) {
        this.force.gravity(this.get('gravity'));
      }
    },
    forcePropertyUpdate: function forcePropertyUpdate() {
      this.setForceProperties();
      this.redraw();
    },
    graphPropertyUpdate: function graphPropertyUpdate() {
      this.dataUpdate();
      this.redraw();
    },
    setupGraph: function setupGraph() {
      var _this = this;

      Ember.run.scheduleOnce('afterRender', function () {
        var $window = Ember.$(window);

        _this.setProperties({
          width: $window.width(),
          height: $window.height()
        });
        _this.fill = d3.scale.category20();
        // init svg
        _this.outer = d3.select('#graph-' + _this.elementId).append('svg:svg').attr('width', _this.get('width')).attr('height', _this.get('height')).attr('pointer-events', 'all');
        _this.zoom = d3.behavior.zoom().on('zoom', function () {
          return _this.rescale();
        });
        _this.outer.append('defs').append('marker').attr('id', 'marker-' + _this.elementId).attr('viewBox', '0 -5 10 10').attr('refX', 14).attr('refY', -1).attr('orient', 'auto').append('path').attr('d', 'M0,-5L10,0L0,5');
        _this.vis = _this.outer.append('svg:g').call(_this.zoom).on('dblclick.zoom', null).append('svg:g').on('mousemove', function () {
          _this.stageMousemove();
        }).on('mouseup', function () {
          _this.stageMouseup();
        });
        // Per-type markers, as they don't inherit styles.
        _this.vis.append('svg:rect').attr('x', -_this.get('width') * 50).attr('y', -_this.get('height') * 50).attr('width', _this.get('width') * 100).attr('height', _this.get('height') * 100).attr('fill', 'white');
        // init force layout
        _this.force = d3.layout.force().size([_this.get('width'), _this.get('height')]).nodes(_this._nodeList).links(_this._linkList).on('tick', function () {
          return _this.tick();
        });
        _this.setForceProperties();
        // line displayed when dragging new nodes
        _this.dragLine = _this.vis.append('g').append('line').attr('class', 'dragLine').attr('x1', 0).attr('y1', 0).attr('x2', 0).attr('y2', 0);
        _this.link = _this.vis.append('g').selectAll('.link');
        _this.node = _this.vis.append('g').selectAll('.node');
        _this.texts = _this.vis.append('g').selectAll('.graph-node-text');
        _this.linkText = _this.vis.append('g').selectAll('.graph-link-text');
        _this.redraw();
      });
    },
    activeMouse: function activeMouse(id) {
      if (this.trans && this.scale) {
        return (d3.mouse(this.element)[id] - this.trans[id]) / this.scale;
      } else {
        return d3.mouse(this.element)[id];
      }
    },
    activeMouseX: function activeMouseX() {
      return this.activeMouse(0);
    },
    activeMouseY: function activeMouseY() {
      return this.activeMouse(1);
    },
    stageMousemove: function stageMousemove() {
      if (!this.mousedownNode) {
        return;
      }
      this.mousedownNode.x = this.activeMouseX();
      this.mousedownNode.y = this.activeMouseY();
      this.tick();
    },
    stageMouseup: function stageMouseup() {
      this.resetMouseVars();
    },


    id: 0,

    resetMouseVars: function resetMouseVars() {
      this.mousedownNode = null;
      this.mouseupNode = null;
      this.mousedownLink = null;
      this.set('dragging', false);
    },
    avg: function avg(a, b) {
      return (a + b) / 2;
    },
    signDiff: function signDiff(a, b) {
      return a > b ? 1 : -1;
    },
    tick: function tick() {
      var _this2 = this;

      if (this.link && this.node) {
        this.node.attr('x', function (d) {
          return d.x;
        }).attr('y', function (d) {
          return d.y;
        });
        this.link.attr('x1', function (d) {
          return d.source.x;
        }).attr('y1', function (d) {
          return d.source.y;
        }).attr('x2', function (d) {
          return d.target.x;
        }).attr('y2', function (d) {
          return d.target.y;
        });
        this.linkText.attr('x', function (d) {
          return _this2.avg(d.source.x, d.target.x) + _this2.signDiff(d.source.x, d.target.x) * 10;
        }).attr('y', function (d) {
          return _this2.avg(d.source.y, d.target.y) + _this2.signDiff(d.source.x, d.target.x) * 10;
        });
        this.vis.selectAll('.group').attr('transform', function (d) {
          return 'translate(' + d.x + ',' + d.y + ')';
        });
      }
    },
    linkPath: function linkPath(d) {
      var dx = d.target.x - d.source.x;
      var dy = d.target.y - d.source.y;
      var dr = Math.sqrt(dx * dx + dy * dy);
      return 'M' + d.source.x + ',' + d.source.y + 'A' + dr + ',' + dr + ' 0 0,1 ' + d.target.x + ',' + d.target.y;
    },
    mousedown: function mousedown(d) {
      d3.event.stopPropagation();
      // disable zoom
      // d3.event.sourceEvent.stopPropagation();
      this.mousedownNode = d;
      if (this.mousedownNode === this.selectedNode) {
        this.selectedNode = null;
      } else {
        this.selectedNode = this.mousedownNode;
      }
      this.selectedLink = null;
    },


    // rescale g
    rescale: function rescale() {
      this.trans = d3.event.translate;
      this.scale = d3.event.scale;
      this.vis.attr('transform', 'translate(' + this.trans + ')' + ' scale(' + this.scale + ')');
    },


    // redraw force layout
    redraw: function redraw() {
      var _this3 = this;

      // handling links
      this.link = this.link.data(this._linkList);
      this.link.enter().append('line').attr('class', 'link').on('mousedown', function (d) {
        _this3.mousedownLink = d;
        if (_this3.mousedownLink === _this3.selectedLink) {
          _this3.selectedLink = null;
        } else {
          _this3.selectedLink = _this3.mousedownLink;
        }
        _this3.selectedNode = null;
        _this3.redraw();
      });
      this.link.exit().transition().style('opacity', 0).remove();
      this.link.classed('link_selected', function (d) {
        return d === _this3.selectedLink;
      });

      // handling link text
      this.linkText = this.linkText.data(this._linkList);
      this.linkText.enter().append('text').attr('class', '.graph-link-text').text(function (d) {
        return d.type;
      });
      this.linkText.attr('class', function () {
        var classes = '';
        if (!_this3.get('linkLabels')) {
          classes += 'hide-link';
        }
        return classes + ' graph-link-text';
      });
      this.linkText.exit().transition().style('opacity', 0).remove();

      // handling nodes
      this.node = this.node.data(this._nodeList);
      this.nodeGroup = this.node.enter();
      this.nodeGroup.append('circle').attr('class', function (d) {
        var c = 'node group';
        if (d.class) {
          c += ' ' + d.class;
        }
        return c;
      }).on('mouseup', function (d) {
        _this3.mouseupNode = d;
        if (_this3.mousedownNode === _this3.mouseupNode) {
          _this3.selectedNode = d;
          _this3.redraw();
        }
        _this3.mouseup(d);
      }).on('mousedown', function (d) {
        _this3.mousedown(d);
      }).transition().duration(750).ease('elastic').attr('r', function (d) {
        return d.size || 5;
      });
      this.node.exit().transition().style('opacity', 0).remove();

      // handling texts
      this.vis.selectAll('.node').classed('node_selected', function (d) {
        return d === _this3.selectedNode;
      });
      this.texts = this.texts.data(this._nodeList);
      this.textGroup = this.texts.enter();
      this.textGroup.append('text').attr('dx', function (d) {
        var spacing = d.size || 6.5;
        return 8 + spacing;
      }).attr('dy', '.35em').attr('class', 'graph-node-text group').text(function (d) {
        return d.text;
      });
      this.texts.exit().transition().style('opacity', 0).remove();
      // prevent browser's default behavior
      if (d3.event && d3.event.preventDefault) {
        d3.event.preventDefault();
      }
      this.force.start();
    },
    mouseup: function mouseup() {
      if (this.mousedownNode && this.mouseupNode) {
        if (this.mouseupNode === this.mousedownNode) {
          return;
        }

        this.force.start();
      }
    }
  });
});