define('slate-payroll/routes/account', ['exports', 'slate-payroll/mixins/authenticated-route'], function (exports, _authenticatedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRoute.default, {
    headData: Ember.inject.service(),
    auth: Ember.inject.service(),

    titleToken: function titleToken() {
      if (this.controller.get('currentPath') === 'account.index.index') {
        return 'Summary';
      }
    },
    model: function model() {
      return this.auth.get('currentUser');
    }
  });
});