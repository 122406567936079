define('slate-payroll/models/bank-account', ['exports', 'ember-data', 'ember-data-copyable'], function (exports, _emberData, _emberDataCopyable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var attr = _emberData.default.attr;

  exports.default = _emberData.default.Model.extend(_emberDataCopyable.default, {
    number: attr('string'),
    routing: attr('string'),
    type: attr('string'),

    achAmount: attr('number', { defaultValue: 100 }),
    achType: attr('string', { defaultValue: '%' }), // $ or %

    created: attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    })
  });
});