define('slate-payroll/components/form-steps', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'form',

    disableSubmit: Ember.computed.not('stepValid'),
    disableBack: Ember.computed.equal('step', 0),
    step: 0,
    _steps: Ember.A(),
    _formBindings: Ember.A(),

    didInsertElement: function didInsertElement() {
      this._stepDidChange();
    },
    retrieveStepIndex: function retrieveStepIndex(step) {
      return this.get('childViews').indexOf(step);
    },
    _stepDidChange: function _stepDidChange() {
      var step = this.get('step'),
          formBindings = this.get('_formBindings'),
          validWith = this.get('validWith').objectAt(step),
          stepComponent = (this.get('_steps') || Ember.A()).objectAt(step);

      if (formBindings.length > 0) {
        this._removeObservers(formBindings, this._setStepValid);
      }

      if (validWith) {
        this._addObservers(validWith, this._setStepValid.bind(this));
      }

      this._setStepValid();
      Ember.run.scheduleOnce('afterRender', this, this._autofocus);
      this.set('currentTitle', stepComponent ? stepComponent.get('stepName') : false);
    },
    _addObservers: function _addObservers(keys, method) {
      var _this = this;

      var added = [];

      keys.forEach(function (key) {
        added.push(key);
        _this.get('_formBindings').pushObject(key);
        _this.get('for').addObserver(key, _this, method);
      });
    },
    _removeObservers: function _removeObservers(keys, method) {
      var _this2 = this;

      var removed = [];

      keys.forEach(function (key) {
        removed.push(key);
        _this2.get('for').removeObserver(key, _this2, method);
      });

      this.set('_formBindings', Ember.A());
    },
    _setStepValid: function _setStepValid() {
      var _this3 = this;

      Ember.run.debounce(function () {
        if (_this3.get('isDestroyed') || _this3.get('isDestroying')) {
          return undefined;
        }

        var formContext = _this3.get('for'),
            qual = _this3.get('qualification'),
            validWith = _this3.get('validWith').objectAt(_this3.get('step'));

        if (!validWith) {
          return _this3.set('stepValid', true);
        }

        var invalid = validWith.filter(function (key) {
          var value = formContext.get(key),
              ret = qual && qual[key] && typeof qual[key] === 'function' ? qual[key].call(formContext, value) : !!value;

          if (typeof ret === 'string' && formContext.get(key) !== ret) {
            _this3.get('for').set(key, ret);
          }

          return !ret;
        });

        _this3.set('stepValid', invalid.length < 1);
      }, 100);
    },
    _autofocus: function _autofocus() {
      var step = this.get('_steps').objectAt(this.get('step'));

      if (!step) {
        return;
      }

      step._focus.call(step);
    },


    onLast: Ember.computed('step', '_steps', function () {
      return this.get('_steps.length') === this.get('step') + 1;
    }),

    contextualButtonText: Ember.computed('submitText', 'step', '_steps', function () {
      if (this.get('onLast')) {
        return this.get('finishText') || 'Finish';
      }

      var showStepNames = this.get('includeStepNames'),
          step = this.get('step'),
          _steps = this.get('_steps');

      if (_steps.get('length') < 1) {
        return null;
      }

      var stepName = _steps.objectAt(step + 1).get('name'),
          stepSubmitText = _steps.objectAt(step).get('submitText');

      var text = stepSubmitText || this.get('submitText') || 'Next';

      return showStepNames && stepName ? text + ': ' + stepName : text;
    }),

    allowSkip: Ember.computed('step', '_steps.@each.allowSkip', function () {
      var step = this.get('_steps').objectAt(this.get('step'));
      return step ? step.get('allowSkip') : false;
    }),

    submit: function submit(e) {
      e.preventDefault();
      this._next();
    },
    _next: function _next() {
      if (this.get('onLast')) {
        this.get('finish')();
      } else {
        this.incrementProperty('step');
        this._stepDidChange();
      }
    },
    _back: function _back() {
      this.decrementProperty('step');
      this._stepDidChange();
    },
    willDestroyElement: function willDestroyElement() {
      this._removeObservers(this.get('_formBindings'), this._setStepValid.bind(this));
    },
    onStepRender: function onStepRender(step) {
      this.get('_steps').addObject(step);
      return this.get('_steps.length') - 1;
    },
    onStepDestroy: function onStepDestroy(step) {
      this.get('_steps').removeObject(step);
    }
  });
});