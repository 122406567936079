define('slate-payroll/mixins/model-watcher', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    watchModel: null,
    watchModelKey: null,
    watchPollInterval: 10000,
    forceReload: false,

    afterModel: function afterModel(model) {
      var modelKey = this.get('watchModelKey'),
          data = modelKey ? model[modelKey] : model,
          count = data.get('meta.totalRecords');

      this.setProperties({
        __watcherOriginalQuery: data.query,
        __watcherCurrentTotal: count,
        __watcherServerTotal: count,
        forceReload: false
      });

      this.__updateControllerStaleStatus();

      Ember.run.scheduleOnce('afterRender', this, this.__scheduleWatcherPoll);
    },
    deactivate: function deactivate() {
      this.__destroyCurrentWatcher();
      this._super.apply(this, arguments);
    },
    __scheduleWatcherPoll: function __scheduleWatcherPoll() {
      this.__destroyCurrentWatcher();
      this.set('__modelWatcherEvent', Ember.run.later(this, this.__dispatchPoll, this.get('watchPollInterval')));
    },
    __dispatchPoll: function __dispatchPoll() {
      var modelName = this.get('watchModel'),
          url = this.store.adapterFor(modelName).buildURL(modelName);

      Ember.$.getJSON(url, Ember.$.extend({}, this.get('__watcherOriginalQuery'), { _count: true })).done(Ember.run.bind(this, function (result) {
        this.setProperties({
          __watcherServerTotal: result.count,
          __modelWatcherEvent: null
        });

        this.__updateControllerStaleStatus();

        if (!this.get('modelIsStale')) {
          this.__scheduleWatcherPoll();
        } else {
          this.set('forceReload', true);
        }
      }));
    },
    __destroyCurrentWatcher: function __destroyCurrentWatcher() {
      if (this.get('__modelWatcherEvent')) {
        Ember.run.cancel(this.get('__modelWatcherEvent'));
      }
    },


    modelIsStale: Ember.computed('__watcherCurrentTotal', '__watcherServerTotal', function () {
      return this.get('__watcherCurrentTotal') !== this.get('__watcherServerTotal');
    }),

    __updateControllerStaleStatus: function __updateControllerStaleStatus() {
      var _this = this;

      Ember.run.scheduleOnce('afterRender', function () {
        if (_this.get('controller')) {
          _this.get('controller').set('modelIsStale', _this.get('modelIsStale'));
        }
      });
    }
  });
});