define('slate-payroll/components/form-input', ['exports', 'slate-payroll/templates/components/form-input'], function (exports, _formInput) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.TextField.extend({
    layout: _formInput.default,
    classNames: ['form-control']
  });
});