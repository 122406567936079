define('slate-payroll/models/automated-paycode', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  exports.default = _emberData.default.Model.extend({
    units: attr('number'),
    static: attr('boolean'),
    changed: attr('boolean'),
    isLapsed: attr('boolean'),
    paycodeDescription: attr('string'),

    companyPaycode: belongsTo('company-paycode', {
      async: true,
      inverse: null
    }),
    employees: hasMany('employee', {
      async: true,
      inverse: null
    }),
    company: belongsTo('company', {
      async: true,
      inverse: null
    }),
    supersededBy: belongsTo('automated-paycode', {
      async: true,
      inverse: null
    }),

    effectiveOn: attr('date'),
    cutOffOn: attr('date'),

    created: attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    })
  });
});