define('slate-payroll/controllers/account/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var staticCardLinks = [{
    text: 'Punch Card',
    path: 'account.index.punch-card',
    description: 'An overall visualization of clock-in times in an easy to view, punch card like, grid.'
  }, {
    text: 'Punch In/Out Map',
    path: 'account.index.clock-map',
    description: 'A map using clock geodata showing you where your employees have been clocking in and out from.'
  }, {
    text: 'ACA ALE Report',
    path: 'account.index.ale-report',
    description: 'Check your ALE status for a custom lookback and see a breakdown of hours & FTE calculations.'
  }, {
    text: 'Organization Chart',
    path: 'account.index.org-chart',
    description: 'Get a view of your organization\'s heirarchy through a nice chart visualization.'
  }];

  exports.default = Ember.Controller.extend({
    application: Ember.inject.controller(),

    onSummaryIndex: Ember.computed('application.currentPath', function () {
      return this.get('application.currentPath') === 'account.index.index';
    }),

    navLinks: Ember.computed('model', function () {
      var links = [].concat(staticCardLinks);

      if (this.get('model.length')) {
        links.push({
          text: 'Custom Reports',
          path: 'account.index.custom-reports',
          description: 'Custom reports that have been tailored to your company by our development team.'
        });
      }

      var currentYear = new Date().getFullYear();

      if (currentYear === 2023 || currentYear === 2024) {
        links.push({
          text: 'State Withholding Report',
          path: 'account.index.state-w4-report',
          description: 'Overview of your organization\'s employees\' withholding settings'
        });
      }

      return links;
    })
  });
});