define('slate-payroll/components/multi-select', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['multi-select', 'list-group'],
    _optionComponents: Ember.computed.alias('childViews'),

    init: function init() {
      this._super.apply(this, arguments);
      Ember.deprecate('Use of multi-select has deprecated in favor of input/multi-select which uses "Data down, actions up" conventions, please use input/multi-select instead.', false, {
        id: 'slate-dep-multiselect-component',
        until: '0.0.0'
      });
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (!this.get('value')) {
        this.set('value', Ember.A());
      }
    },


    _valueDidChange: Ember.observer('value.[]', function () {
      if (this.get('_didChangeSelection')) {
        return this.set('_didChangeSelection', false);
      }

      var value = this.get('value'),
          optionValuePath = this.get('optionValuePath');

      this.get('_optionComponents').forEach(function (component) {
        var option = component.get('option'),
            v = optionValuePath ? Ember.get(option, optionValuePath) : option;

        var inValue = (value || []).includes(v),
            isChecked = component.get('checked');

        if (inValue && !isChecked) {
          component.setProperties({
            checked: true,
            _didChangeBound: true
          });
        } else if (!inValue && isChecked) {
          component.setProperties({
            checked: false,
            _didChangeBound: true
          });
        }
      });
    }),

    actions: {
      changeSelection: function changeSelection(bool, value) {
        var optionValuePath = this.get('optionValuePath');
        this.set('_didChangeSelection', true);
        this.get('value')[bool ? 'addObject' : 'removeObject'](optionValuePath ? Ember.get(value, optionValuePath) : value);
      }
    }
  });
});