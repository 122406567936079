define('slate-payroll/models/pay-period', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var attr = _emberData.default.attr;

  exports.default = _emberData.default.Model.extend({
    notes: attr('string'),
    start: attr('date'),
    end: attr('date'),
    paidOn: attr('date'),
    closedOn: attr('date'),
    correctedOn: attr('date'),
    acceptPunches: attr('boolean'),
    imported: attr('boolean'),

    company: _emberData.default.belongsTo('company', { async: true }),
    reviewedEmployees: _emberData.default.hasMany('employee', {
      async: true,
      inverse: null
    }),
    comments: attr('array'),
    payroll: attr('string'),
    originalPayroll: attr('string'),

    created: attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    })
  });
});