define('slate-payroll/controllers/account/data-explorer/explore/reports/parameters', ['exports', 'slate-payroll/config/statics'], function (exports, _statics) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.parameters = {};
    },


    parametersFor: Ember.computed.reads('model.id'),

    parameterDataPoints: Ember.computed('model.dataPoints.[]', function () {
      var points = this.get('model.dataPoints');

      return points ? points.filterBy('parameter', true).toArray().map(function (point) {
        Ember.set(point, 'is' + Ember.String.capitalize(Ember.get(point, 'propertyType')), true);
        Ember.set(point, 'field', Ember.get(point, 'propertyKey'));
        Ember.set(point, 'ops', _statics.dataTypeOperatorMap[point.propertyType]);
        return point;
      }) : points;
    }),

    actions: {
      parameterChange: function parameterChange(point, parameter) {
        var searchParams = {
          value: parameter,
          operator: point.operator
        };
        this.set('parameters.' + Ember.get(point, 'propertyKey'), searchParams);
      },
      sortChange: function sortChange(point) {
        var key = Ember.get(point, 'propertyKey'),
            parameters = this.parameters[key],
            searchParams = parameters || {};

        if (!parameters) {
          searchParams.value = point.operator === 'doesn\'t exist' ? undefined : point.value;
        }
        searchParams.operator = point.operator;

        this.set('parameters.' + key, searchParams);
      }
    }
  });
});