define('slate-payroll/components/time-off/request-item', ['exports', 'moment', 'slate-payroll/mixins/render-tooltips'], function (exports, _moment, _renderTooltips) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var statusIcons = {
    approved: 'check',
    rejected: 'times',
    pending: 'ellipsis-h'
  };

  exports.default = Ember.Component.extend(_renderTooltips.default, {
    classNames: ['tor__component'],
    tagName: 'li',
    requestIsPending: Ember.computed.equal('requestStatus', 'pending'),

    // Returns a string of month(s) as `contextualMonths`
    // based on the days selected
    contextualMonths: Ember.computed('request.days.@each.date', function () {
      return this.get('request.days') ? this.get('request.days').reduce(function (accumulator, day) {
        accumulator.addObject((0, _moment.default)(day.date).format('MMMM'));
        return accumulator;
      }, Ember.A()).join(', ') : 'N/A';
    }),

    daysTooltipContent: Ember.computed('request.days.@each.date', function () {
      return 'Days in this request:\n' + (this.get('request.days') ? this.get('request.days').reduce(function (accumulator, day) {
        return accumulator.concat((0, _moment.default)(day.date).format('MM/DD/YYYY'));
      }, []).join('\n') : 'None');
    }),

    commentTooltipContent: Ember.computed('request.supervisorNotes', function () {
      var notes = this.get('request.supervisorNotes');
      return notes ? 'Your supervisor\'s notes: ' + notes : undefined;
    }),

    statusIcon: Ember.computed('requestStatus', function () {
      var requestStatus = this.get('requestStatus');
      return statusIcons[requestStatus];
    }),

    requestStatus: Ember.computed('request.{approved,reviewedOn}', function () {
      var approved = this.get('request.approved'),
          reviewedOn = this.get('request.reviewedOn');

      if (approved) {
        return 'approved';
      } else if (!approved && reviewedOn) {
        return 'rejected';
      } else {
        return 'pending';
      }
    }),

    actions: {
      destroyRecord: function destroyRecord() {
        var action = this.get('onDelete'),
            model = this.get('request');

        model.destroyRecord();

        if (action && typeof action === 'function') {
          action(model);
        }
      }
    }
  });
});