define('slate-payroll/routes/account/reminders/edit', ['exports', 'slate-payroll/mixins/route-abstractions/edit'], function (exports, _edit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_edit.default, {
    modelName: 'reminder',

    model: function model(params) {
      return Ember.RSVP.hash({
        reminder: this.store.find('reminder', params.id),
        employees: this.store.findAll('employee'),
        users: this.store.findAll('user')
      });
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        model: model.reminder,
        employees: model.employees,
        users: model.users
      });
    }
  });
});