define('slate-payroll/controllers/account/punch/location', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    markers: Ember.computed('model.{timeInGeoLat,timeInGeoLng,timeOutGeoLat,timeOutGeoLng}', function () {
      var geo = this.get('model').getProperties('timeInGeoLat', 'timeInGeoLng', 'timeOutGeoLat', 'timeOutGeoLng');

      var markersArray = [];

      if (geo.timeInGeoLat && geo.timeInGeoLng) {
        markersArray.push({
          position: {
            lat: geo.timeInGeoLat,
            lng: geo.timeInGeoLng
          },
          title: 'Clocked In',
          icon: '/assets/images/icons/gmap-in.png'
        });
      }

      if (geo.timeOutGeoLat && geo.timeOutGeoLng) {
        markersArray.push({
          position: {
            lat: geo.timeOutGeoLat,
            lng: geo.timeOutGeoLng
          },
          title: 'Clocked Out',
          icon: '/assets/images/icons/gmap-out.png'
        });
      }

      return markersArray.length > 0 ? markersArray : false;
    })
  });
});