define('slate-payroll/models/query', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var attr = _emberData.default.attr;

  exports.default = _emberData.default.Model.extend({
    name: attr('string'),
    queryModel: attr('string', { defaultValue: 'employee' }),

    dataPoints: _emberData.default.hasMany('data-point', {
      inverse: false,
      async: false
    }),
    displayPoints: _emberData.default.hasMany('display-point', {
      inverse: false,
      async: false
    }),
    queryGroups: _emberData.default.hasMany('query-group', {
      inverse: false,
      async: false
    }),
    queryResults: _emberData.default.hasMany('query-result', { async: true }),
    createdBy: _emberData.default.belongsTo('user', {
      async: true,
      inverse: false
    }),

    unwind: attr('string'),
    sortProperty: attr('string'),
    sortAsc: attr('boolean'),
    limitItems: attr('number'),
    grouping: attr(),
    lastRan: attr('date'),

    created: attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    })
  });
});