define('slate-payroll/helpers/format-moment', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Helper.extend({
    auth: Ember.inject.service(),

    compute: function compute(_ref, _ref2) {
      var _ref3 = _slicedToArray(_ref, 2),
          timestamp = _ref3[0],
          pattern = _ref3[1];

      var forceTz = _ref2.forceTz;

      var _pattern = typeof pattern === 'string' ? pattern : 'MM/DD/YYYY',
          momentInst = (0, _moment.default)(timestamp);

      if (forceTz) {
        momentInst = momentInst.tz(this.auth.tz);
      }

      return timestamp ? momentInst.format(_pattern) : 'N/A';
    }
  });
});