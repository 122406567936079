define('slate-payroll/components/list-item/automated-paycode', ['exports', 'moment', 'slate-payroll/mixins/render-tooltips', 'slate-payroll/mixins/ux-ajax-status-hooks'], function (exports, _moment, _renderTooltips, _uxAjaxStatusHooks) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_uxAjaxStatusHooks.default, _renderTooltips.default, {
    tagName: 'tr',
    classNameBindings: ['isLapsed:superseded-item', 'isCutOff:cut-off-item'],

    isMulti: Ember.computed.gt('paycode.employees.length', 1),
    isDeduction: Ember.computed.equal('paycode.companyPaycode.type', 'D'),
    isDollars: Ember.computed.equal('paycode.companyPaycode.unitActual', 'Dollars'),
    isLapsed: Ember.computed.reads('paycode.isLapsed'),

    isCutOff: Ember.computed('paycode.cutOffOn', 'isLapsed', function () {
      var cutOff = (0, _moment.default)().isAfter(this.get('paycode.cutOffOn')),
          lapsed = this.get('isLapsed');

      return cutOff && !lapsed;
    }),

    path: Ember.computed(function () {
      return Ember.getOwner(this).lookup('controller:application').currentPath;
    }),

    //Are we on the employee automated paycodes page?
    employee: Ember.computed.equal('path', 'account.employee.automated-paycodes'),

    actions: {
      edit: function edit() {
        this.set('editMode', true);
        this.set('paycode.effectiveOn', this.get('effectiveDates.firstObject.value'));
      },
      cancelChanges: function cancelChanges() {
        this.get('paycode').rollbackAttributes();
        this.set('editMode', false);
      },
      saveChanges: function saveChanges() {
        var _this = this;

        var paycode = this.get('paycode');

        this.ajaxStart();

        paycode.save().then(function () {
          _this.ajaxSuccess();
          _this.set('editMode', false);
          _this.get('onchange')();
        }).catch(this.ajaxError.bind(this));
      },
      deletePaycode: function deletePaycode() {
        var paycode = this.get('paycode');
        this.ajaxStart();

        paycode.destroyRecord().catch(this.ajaxError.bind(this));
      }
    }
  });
});