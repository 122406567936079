define('slate-payroll/routes/account/data-explorer/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      if (this.auth.get('currentUser.content.dataExplorer')) {
        this.transitionTo('account.data-explorer.explore');
      }
    },


    actions: {
      willTransition: function willTransition() {
        var user = this.auth.get('currentUser.content');

        if (!user.get('dataExplorer')) {
          user.set('dataExplorer', true);
          user.save();
        }

        return true;
      }
    }
  });
});