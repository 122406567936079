define('slate-payroll/mixins/disable-holidays', ['exports', 'slate-payroll/config/app-data'], function (exports, _appData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var holidaysAsStr = _appData.holidays.map(function (holiday) {
    return moment(holiday).format('M/D/YY');
  });

  exports.default = Ember.Mixin.create({
    disableHolidays: Ember.computed(function () {
      return [function (date) {
        var dateM = moment(date),
            day = dateM.day();

        return day === 0 || day === 6 || holidaysAsStr.includes(dateM.format('M/D/YY'));
      }];
    })
  });
});