define('slate-payroll/models/ach-transaction', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var attr = _emberData.default.attr;

  exports.default = _emberData.default.Model.extend({
    order: attr('number'),
    account: attr('string'),
    routing: attr('string'),
    amount: attr('number'),
    type: attr('string'),
    batch: attr('number'),

    depositedOn: attr('date')
  });
});