define('slate-payroll/routes/account/help/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      var $regex = params.s,
          $options = 'gi';

      var promises = { categories: this.modelFor('account.help') };

      if ($regex) {
        promises.searchResults = this.store.query('help-article', {
          $or: [{
            title: {
              $regex: $regex,
              $options: $options
            }
          }, {
            body: {
              $regex: $regex,
              $options: $options
            }
          }]
        });
      }

      return Ember.RSVP.hash(promises);
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        model: model.categories,
        searchResults: model.searchResults
      });
    },


    actions: {
      refreshModel: function refreshModel() {
        this.refresh();
      }
    }
  });
});