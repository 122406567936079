define('slate-payroll/components/list/tor-balance-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    balances: Ember.computed('model.balances.[]', 'headers.[]', function () {
      var _this = this;

      return this.headers.map(function (header) {
        return _this.model.balances.find(function (bal) {
          return bal.description === header;
        });
      });
    })
  });
});