define('slate-payroll/components/time-off/view-calendar/cal-day', ['exports', 'slate-payroll/mixins/render-tooltips'], function (exports, _renderTooltips) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_renderTooltips.default, {
    init: function init() {
      this._super.apply(this, arguments);
      this.sortingRequests = ['approved'];
    },


    tagName: 'li',
    classNames: ['cal__day'],
    classNameBindings: ['day.outOfMonth:cal__gray', 'isToday:is-today'],
    requestsSorted: Ember.computed.sort('day.requests', 'sortingRequests'),

    pendingRequests: Ember.computed.filter('day.requests', function (request) {
      return !request.get('approved');
    }),

    isToday: Ember.computed('day.date', function () {
      return this.get('day.date') && moment().isSame(this.get('day.date'), 'day');
    }),

    actions: {
      openInteractive: function openInteractive() {
        this.get('interact')('day', this.get('day'));
        Ember.$('.tooltip').hide();
      }
    }
  });
});