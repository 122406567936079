define('slate-payroll/controllers/account/employee/squares/new/finish', ['exports', 'slate-payroll/mixins/ux-ajax-status-hooks'], function (exports, _uxAjaxStatusHooks) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_uxAjaxStatusHooks.default, {
    employeeController: Ember.inject.controller('account.employee'),
    employee: Ember.computed.reads('employeeController.model'),

    actions: {
      finishSquare: function finishSquare() {
        var _this = this;

        var squareId = this.get('pendingSquareId'),
            employee = this.get('employee.id'),
            customName = this.get('squareCustomName'),
            autoPunch = this.get('squareAutoPunch');

        this.ajaxStart();

        Ember.$.post('/api/square/register', {
          square: squareId,
          employee: employee,
          customName: customName,
          autoPunch: autoPunch
        }, function () {
          _this.ajaxSuccess();
          _this.set('squareCustomName', null);
          _this.transitionToRoute('account.employee.squares.index');
        }).fail(this.ajaxError.bind(this));
      }
    }
  });
});