define('slate-payroll/utils/computed-equals-key', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = computedEqualsKey;
  function computedEqualsKey(rhsDependentKey, lhsDependentKey) {
    return Ember.computed(rhsDependentKey, lhsDependentKey, function () {
      return Ember.get(this, rhsDependentKey) === Ember.get(this, lhsDependentKey);
    });
  }
});