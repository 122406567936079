define('slate-payroll/models/archived-record', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    record: (0, _attr.default)(),
    recordType: (0, _attr.default)('string'),

    company: (0, _relationships.belongsTo)('company', {
      async: true,
      inverse: null
    }),
    actor: (0, _relationships.belongsTo)('user', {
      async: true,
      inverse: null
    }),
    actorEmployee: (0, _relationships.belongsTo)('employee', {
      async: true,
      inverse: null
    }),

    created: (0, _attr.default)('date', { defaultValue: function defaultValue() {
        return new Date();
      } })
  });
});