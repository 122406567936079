define('slate-payroll/models/time-off-request', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = _emberData.default.Model.extend({
    approved: attr('boolean'),
    supervisorNotes: attr('string'),
    employeeNotes: attr('string'),
    days: attr('array'),
    totalDays: attr('number'),
    totalHours: attr('number'),

    balanceDescription: attr('string'),

    // Relational
    employee: belongsTo('employee', {
      async: true,
      inverse: false
    }),
    companyPaycode: belongsTo('company-paycode', {
      async: true,
      inverse: false
    }),
    company: belongsTo('company', {
      async: true,
      inverse: false
    }),
    supervisor: belongsTo('employee', {
      async: true,
      inverse: false
    }),

    reviewedOn: attr('date'),
    editedOn: attr('date'),

    created: attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    })
  });
});