define('slate-payroll/controllers/account/automated-paycodes', ['exports', 'slate-payroll/mixins/resource-pagination'], function (exports, _resourcePagination) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_resourcePagination.default, {
    queryParams: ['page', 'sortAsc', 'showFilters', 'filterByStatic', 'filterByEmployee', 'filterByMulti', 'static', 'multi'],

    sortAsc: true,
    page: 1,
    limit: 15,
    showFilters: false,
    filterByStatic: false,
    filterByMulti: false,
    static: false,
    multi: false,
    filterByEmployee: Ember.A(),

    employeeQuery: {
      sort: { 'name.last': 1 },
      $or: [{ terminatedOn: { $not: { $type: 9 } } }, { terminatedOn: { $gt: moment().subtract(1, 'month').toDate() } }]
    },

    actions: {
      toggleProperty: function toggleProperty(prop) {
        this.toggleProperty(prop);
      }
    }
  });
});