define('slate-payroll/routes/account/data-explorer/explore/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return this.transitionTo('account.data-explorer.explore.reports');
      // return this.store.query('pay-period', {
      //   select: 'start end paidOn closedOn',
      //   start: { $lte: new Date() },
      //   end: { $gte: new Date() }
      // });
    },


    actions: {
      willTransition: function willTransition() {
        return true;
      }
    }
  });
});