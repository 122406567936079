define('slate-payroll/controllers/account/employees/supervisory-settings', ['exports', 'slate-payroll/mixins/ux-ajax-status-hooks'], function (exports, _uxAjaxStatusHooks) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_uxAjaxStatusHooks.default, {
    selectedEmployees: Ember.A(),

    filteredEmployees: Ember.computed('model', 'supervisorEmployee', function () {
      var supervisorId = this.get('supervisorEmployee.id');
      return this.get('model').filter(function (employee) {
        return employee.get('id') !== supervisorId;
      });
    }),

    actions: {
      selectAll: function selectAll() {
        this.set('selectedEmployees', Ember.A(this.get('filteredEmployees').toArray()));
      },
      deselectAll: function deselectAll() {
        this.set('selectedEmployees', Ember.A());
      },
      setSupervisorySettings: function setSupervisorySettings() {
        var _this = this;

        var supervised = this.get('selectedEmployees').mapBy('id').toArray(),
            supervisor = this.get('supervisorEmployee.id'),
            resetCurrent = this.get('resetSupervised');

        if (!supervisor) {
          this.set('errorMessage', 'Please select a supervisor.');
          return;
        }

        this.ajaxStart();

        Ember.$.post('/api/employee/update-supervisor', {
          supervised: supervised,
          supervisor: supervisor,
          resetCurrent: resetCurrent
        }).then(Ember.run.bind(this, function () {
          _this.ajaxSuccess('Successfully saved supervisory settings for ' + _this.get('supervisorEmployee.fullName'));
          _this.send('deselectAll');
        })).fail(this.ajaxError.bind(this));
      }
    }
  });
});