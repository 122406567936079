define('slate-payroll/routes/account/pay-period/close/audit/changes', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var payPeriod = this.modelFor('account.pay-period');
      var range = {
        $gte: payPeriod.get('start'),
        $lte: payPeriod.get('end')
      };

      var query = {
        $or: [{
          effective: { $exists: false },
          created: range
        }, {
          effective: { $type: 10 },
          created: range
        }, { effective: range }],
        sort: { created: -1 }
      };

      return this.store.query('change-request', query);
    }
  });
});