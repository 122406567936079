define('slate-payroll/helpers/concat-string', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.concatString = concatString;
  function concatString(params /*, hash*/) {
    return params.join(' ');
  }

  exports.default = Ember.Helper.helper(concatString);
});