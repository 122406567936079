define('slate-payroll/mixins/routable-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    awaitModalClose: function awaitModalClose() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve) {
        _this.set('__resolveModal', resolve);
      });
    },


    actions: {
      modalDidClose: function modalDidClose() {
        if (this.get('__resolveModal')) {
          this.get('__resolveModal')();
        }
      },
      willTransition: function willTransition(transition) {
        var _this2 = this;

        var controller = this.controller;

        if (!controller.get('showModal')) {
          return;
        }

        if (this.willTransitionFromModal) {
          this.willTransitionFromModal(controller, transition);
        }

        transition.abort();
        controller.set('showModal', false);

        var modalClosePromise = this.awaitModalClose().then(function () {
          if (_this2.afterModalClose) {
            _this2.afterModalClose(controller, transition);
          }

          return transition.retry();
        });

        this.set('modalClosePromise', modalClosePromise);
      },
      didTransition: function didTransition() {
        if (this.get('disableAutoShowModal')) {
          return;
        }

        // We must turn the modal on every time we enter the route
        this.controller.set('showModal', true);
      }
    }
  });
});