define('slate-payroll/components/punch-list-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['list-group-item'],
    classNameBindings: ['punch.incomplete:list-group-item-danger'],

    showDelete: Ember.computed.or('deleteMode', 'onDelete'),

    computedType: Ember.computed('punch.companyPaycode.unitActual', function () {
      var types = Ember.A(['hours', 'days', 'addEarnings', 'deduction']),
          punch = this.get('punch');

      var type = punch.get('incomplete') ? undefined : types.find(function (t) {
        var valForType = punch.get(t);
        return valForType && !isNaN(valForType);
      });

      return type ? {
        long: Ember.String.capitalize(Ember.String.decamelize(type).replace(/_/g, ' ')),
        property: type
      } : type;
    }),

    computedAmount: Ember.computed('computedType', function () {
      var type = this.get('computedType');
      return type ? this.get('punch').get(type.property) : undefined;
    }),

    comment: Ember.observer('punch.punchComment', function () {
      var comment = this.get('punch.punchComment');
      if (comment) {
        this.$().find('.comment-popover').popover({
          trigger: 'hover',
          content: comment
        });
      }
    }),

    confirmMessage: Ember.computed('punch.employee.firstName', function () {
      return 'You are about to delete ' + this.get('punch.employee.firstName') + '\'s punch.';
    }),

    didInsertElement: function didInsertElement() {
      this.comment();
      this._super.apply(this, arguments);
    },


    actions: {
      deletePunch: function deletePunch() {
        var _this = this;

        if (this.get('onDelete')) {
          this.get('onDelete')(this.get('punch'));
          return;
        }

        if (this.get('confirmDelete') && !confirm(this.get('confirmMessage'))) {
          return;
        }

        this.set('loadingOperation', true);
        this.get('punch').destroyRecord().catch(function () {
          _this.set('loadingOperation', false);
        });
      }
    }
  });
});