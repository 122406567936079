define('slate-payroll/helpers/to-fixed', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.toFixed = toFixed;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function toFixed(_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        num = _ref2[0],
        places = _ref2[1];

    if (!num || isNaN(parseFloat(num)) || !isFinite(num)) {
      return num;
    }

    var _n = parseFloat(num),
        _places = _n.toFixed() === _n.toString() ? 0 : places;

    return _n.toFixed(_places);
  }

  exports.default = Ember.Helper.helper(toFixed);
});