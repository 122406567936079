define('slate-payroll/controllers/account/employees/balances/upload', ['exports', 'slate-payroll/mixins/ux-ajax-status-hooks'], function (exports, _uxAjaxStatusHooks) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_uxAjaxStatusHooks.default, {
    enableNotify: true,
    acceptedMimeTypes: ['text/csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].join(','),

    actions: {
      uploadedFile: function uploadedFile() {
        this.ajaxSuccess('Successfully updated balances');
        this.transitionToRoute('account.employees.balances');
      },
      uploadError: function uploadError(file, error) {
        this.ajaxError((error.balancesUpdated ? 'Partial balance update.' : 'No balances updated.') + ' ' + error.error);
      },
      removeFile: function removeFile(file) {
        file.previewElement.remove();
      }
    }
  });
});