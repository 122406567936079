define('slate-payroll/controllers/account/pay-period/close/incomplete-punches/complete', ['exports', 'slate-payroll/mixins/ux-ajax-status-hooks', 'moment'], function (exports, _uxAjaxStatusHooks, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_uxAjaxStatusHooks.default, {
    showModal: true,
    timeInterval: Ember.computed.reads('auth.currentUser.company.timeMinuteInterval'),
    modalId: 'complete-punch-modal',
    notSavingPunch: Ember.computed.not('working'),
    allowComplete: Ember.computed.and('notSavingPunch', 'punchOut'),

    modalTitle: Ember.computed('model.employee.firstName', function () {
      return '<h2>Fix Incomplete Punch for ' + this.get('model.employee.firstName') + '</h2>';
    }),

    disableDateOut: Ember.computed(function () {
      var _this = this;

      return [function (date) {
        var timeIn = _this.get('model.timeIn');
        return (0, _moment.default)(date).endOf('day').isBefore(timeIn);
      }];
    }),

    actions: {
      deletePunch: function deletePunch() {
        var _this2 = this;

        this.set('isDeletingPunch', true);

        this.get('model').destroyRecord().then(function () {
          _this2.transitionToRoute('account.pay-period.close.incomplete-punches');
          _this2.set('isDeletingPunch', false);
        });
      },
      completePunch: function completePunch() {
        var _this3 = this;

        var punch = this.get('model');

        this.ajaxStart();
        punch.set('timeOut', this.get('punchOut'));

        punch.save().then(function () {
          _this3.ajaxSuccess();
          _this3.transitionToRoute('account.pay-period.close.incomplete-punches');
        }).catch(this.ajaxError.bind(this));
      }
    }
  });
});