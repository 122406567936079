define('slate-payroll/helpers/join-array', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.joinArray = joinArray;
  function joinArray(params) {
    return params[0] ? params[0].join(params[1] || '') : undefined;
  }

  exports.default = Ember.Helper.helper(joinArray);
});