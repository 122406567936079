define('slate-payroll/routes/account/pay-period/close/incomplete-punches/complete', ['exports', 'slate-payroll/mixins/routable-modal'], function (exports, _routableModal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_routableModal.default, {
    model: function model(params) {
      return this.store.find('punch', params.punchid);
    }
  });
});