define('slate-payroll/controllers/account/index/punch-card', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var calculateTextValue = function calculateTextValue(key) {
    return function () {
      return (0, _moment.default)(this.get(key)).format('DD MMMM, YYYY');
    };
  };

  exports.default = Ember.Controller.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.end = new Date();
      this.punchChart = {
        chartOptions: {
          color: { pattern: ['#374140', '#3E606F', '#79BD8F'] },
          point: { r: 5 },
          axis: {
            x: {
              type: 'timeseries',
              label: 'Day'
            },
            y: {
              padding: {
                top: 5,
                bottom: 8
              },
              type: 'category',
              label: 'Hour of Day',
              tick: {
                format: function format(v) {
                  return (0, _moment.default)().set('hour', v).format('ha');
                }
              }
            }
          }
        },
        dataOptions: {
          type: 'scatter',
          x: 'x'
        }
      };
    },


    queryParams: ['start', 'end'],
    rangeStartText: Ember.computed('start', calculateTextValue('start')),
    rangeEndText: Ember.computed('end', calculateTextValue('end')),

    start: (0, _moment.default)().subtract(3, 'days').toDate(),

    hasChartData: Ember.computed.gt('model.0.length', 1),

    actions: {
      toggleProperty: function toggleProperty(prop) {
        this.toggleProperty(prop);
      }
    }
  });
});