define('slate-payroll/routes/account/pay-period/close/audit/time-off', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var payPeriod = this.modelFor('account.pay-period');
      var range = {
        $gte: payPeriod.get('start'),
        $lte: payPeriod.get('end')
      };
      var query = { days: { $elemMatch: { date: range } } };

      return Ember.RSVP.hash({

        timeOffCount: Ember.$.getJSON('/api/timeOffRequests', {
          days: { $elemMatch: { date: range } },
          _count: true
        }).then(function (res) {
          return res.count;
        }),

        timeOffRequests: this.store.query('time-off-request', query)

      });
    }
  });
});