define('slate-payroll/components/list/attachment-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var icons = {
    bat: 'fa fa-cogs',
    csv: 'fa-table',
    cmd: 'fa fa-cogs',
    doc: 'fa-file-word-o',
    docx: 'fa-file-word-o',
    dot: 'fa-file-word-o',
    dmg: 'fa fa-cogs',
    exe: 'fa fa-cogs',
    html: 'fa-code',
    jar: 'fa fa-cogs',
    jpg: 'fa-file-image-o',
    jpeg: 'fa-file-image-o',
    msi: 'fa fa-cogs',
    pdf: 'fa-file-pdf-o',
    png: 'fa-file-image-o',
    rtf: 'fa-file-word-o',
    sh: 'fa fa-cogs',
    text: 'fa-file-text',
    txt: 'fa-file-text',
    odt: 'fa fa-file-text',
    xlsx: 'fa-table',
    xlsm: 'fa-table',
    xls: 'fa-table'
  };

  exports.default = Ember.Component.extend({
    computedIcon: Ember.computed('extension', function () {
      return icons[this.extension] ? icons[this.extension] : 'fa-file-o';
    })
  });
});