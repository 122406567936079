define('slate-payroll/services/logging', ['exports', 'slate-payroll/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var levels = ['debug', 'info', 'warning', 'error', 'critical']; /* eslint-disable no-console */
  exports.default = Ember.Service.extend({
    rollbar: Ember.inject.service(),
    rollbarLogLevel: levels[1], // info

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      levels.forEach(function (level) {
        _this[level] = function () {
          for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
          }

          return _this.__curryLog.apply(_this, [level].concat(args));
        };
      });
    },
    identifyUser: function identifyUser(user) {
      var _ref = user.getProperties('id', 'email') || {},
          id = _ref.id,
          email = _ref.email;

      this.set('rollbar.currentUser', {
        id: id,
        email: email,
        username: email + '-c#' + user.belongsTo('company').id()
      });

      window.rb = this.get('rollbar');
    },
    determineLogLevel: function determineLogLevel(err) {
      if (err && err.errors) {
        Object.assign(err, err.errors[0]);
      }

      var dtLevel = 'error',
          status = err.status && ('' + err.status).charAt(0);

      if (status) {
        dtLevel = status === '5' ? 'critical' : 'info';
      }

      return dtLevel;
    },
    send: function send() {
      var err = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var userLevel = arguments[1];

      if (!err) {
        return;
      }

      var level = userLevel || err.level || this.determineLogLevel(err),
          threshold = levels.indexOf(this.get('rollbarLogLevel'));

      if (!_environment.default.emberRollbarClient.enabled || levels.indexOf(level) < threshold) {
        return this.__localLog(err, level);
      }

      this.rollbar[level](err);
    },
    __localLog: function __localLog(err, level) {
      console[level === 'error' ? 'error' : 'log'](err);
    },
    __curryLog: function __curryLog(level) {
      for (var _len2 = arguments.length, args = Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
        args[_key2 - 1] = arguments[_key2];
      }

      return this.send(args.join(' '), level);
    }
  });
});