define('slate-payroll/routes/account/pay-period/close/review', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return Ember.$.post('/api/payroll/generate', { payPeriod: this.modelFor('account.pay-period').get('id') }).then(function (res) {
        return res.payroll;
      });
    }
  });
});