define('slate-payroll/components/x-map', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['x-map-component'],
    center: null,
    zoom: 10,
    markers: false,
    fitMarkers: false,
    heatmap: false,
    heatmapData: null,
    heatmapPointRadius: 20,
    mapTypeId: 'HYBRID',

    didInsertElement: function didInsertElement() {
      this.__drawMap();
    },


    __drawMap: Ember.observer('center.lat', 'center.lng', 'zoom', 'mapTypeId', 'markers.[]', function () {
      (false && !(!!google.maps) && Ember.assert('Google Maps API must be loaded.', !!google.maps));


      var mapOptions = this.getProperties('center', 'zoom', 'mapTypeId'),
          markers = this.get('markers'),
          $this = this.$(),
          domNode = $this.find('.g-map')[0],
          g = google.maps;

      mapOptions.mapTypeId = g.MapTypeId[mapOptions.mapTypeId];

      var mapObject = new g.Map(domNode, mapOptions);

      if (this.get('__gMapObject')) {
        this.set('__reusedMap', true);
      }

      this.set('__gMapObject', mapObject);

      if (markers && markers.length > 0) {
        var gMapMarkers = markers.map(this.__buildMarker.bind(mapObject));

        if (this.get('fitMarkers')) {
          var bounds = new g.LatLngBounds();

          gMapMarkers.forEach(function (marker) {
            bounds.extend(marker.getPosition());
          });

          g.event.addListenerOnce(mapObject, 'tilesloaded', function () {
            return mapObject.fitBounds(bounds);
          });
        }
      }
    }),

    __buildMarker: function __buildMarker(markerOptions) {
      var g = google.maps;

      (false && !(!!markerOptions.position.lat || !!markerOptions.position.lng) && Ember.assert('You must specify a position with each marker.', !!markerOptions.position.lat || !!markerOptions.position.lng));


      return new g.Marker(Ember.$.extend({ map: this }, markerOptions));
    },


    __buildHeatmap: Ember.observer('__gMapObject', 'heatmap', 'heatmapData.[]', function () {
      var g = google.maps,
          enable = this.get('heatmap'),
          data = this.get('heatmapData'),
          radius = this.get('heatmapPointRadius'),
          gMapObject = this.get('__gMapObject'),
          gVisualizationObject = this.get('__gHeatmapVisualizationObject');

      if (!gMapObject) {
        return;
      }

      if (enable && data && (!gVisualizationObject || this.get('__reusedMap'))) {
        this.set('__reusedMap', false);

        var gvl = new g.visualization.HeatmapLayer({
          data: data,
          radius: radius
        });

        gvl.setMap(gMapObject);

        this.set('__gHeatmapVisualizationObject', gvl);
      } else if (enable && data && gVisualizationObject && gVisualizationObject.getMap()) {
        gVisualizationObject.setData(data);
      } else if (gVisualizationObject && enable === !gVisualizationObject.getMap()) {
        gVisualizationObject.setMap(enable ? gMapObject : null);
      }
    })
  });
});