define('slate-payroll/controllers/2fa/remind', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    isTwentyTwenty: Ember.computed(function () {
      return new Date().getFullYear() <= 2020;
    }),

    continueWork: function continueWork() {
      var savedTransition = this.savedTransition;


      if (savedTransition) {
        this.set('savedTransition', null);
        savedTransition.retry();
      } else {
        this.transitionToRoute('account');
      }
    },


    actions: {
      skip2fa: function skip2fa() {
        this.continueWork();
      },
      neverRemind: function neverRemind() {
        this.model.set('tfaDismiss', true);
        this.model.save();
        this.continueWork();
      }
    }
  });
});