define('slate-payroll/controllers/account/broadcasts/index', ['exports', 'slate-payroll/mixins/controller-abstractions/delete', 'slate-payroll/mixins/controller-abstractions/resource'], function (exports, _delete, _resource) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_resource.default, _delete.default, {
    queryParams: ['page', 'limit', 'sortAsc'],
    sortAsc: false,
    sortBy: 'created',
    enableNotify: true,

    afterDelete: function afterDelete() {
      this.send('refresh');
    }
  });
});