define('slate-payroll/models/user', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var attr = _emberData.default.attr;

  exports.default = _emberData.default.Model.extend({
    username: attr('string'),
    type: attr('string'),
    email: attr('string'),
    phone: attr('string'),

    dataExplorer: attr('boolean'),
    tfaDismiss: attr('boolean'),
    tfaMethods: attr('array'),

    preferences: attr('array'),
    punchViews: attr('array'),

    company: _emberData.default.belongsTo('company', { async: true }),
    permissions: _emberData.default.hasMany('permission', {
      inverse: false,
      async: true
    }),
    wageExclusions: _emberData.default.hasMany('employee', {
      async: true,
      inverse: null
    }),
    excludeAllWages: attr('boolean'),
    excludeAllBalances: attr('boolean'),

    activatedOn: attr('date'),
    created: attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    })
  });
});