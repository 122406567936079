define('slate-payroll/routes/account/reminders/add', ['exports', 'slate-payroll/mixins/route-abstractions/add'], function (exports, _add) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_add.default, {
    modelName: 'reminder',

    model: function model() {
      return Ember.RSVP.hash({
        reminder: this.store.createRecord('reminder'),
        employees: this.store.query('employee', { terminatedOn: { $not: { $type: 9 } } }),
        users: this.store.findAll('user')
      });
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        model: model.reminder,
        employees: model.employees,
        users: model.users
      });
    }
  });
});