define('slate-payroll/components/punch-employee-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    isActiveEmployee: Ember.computed('model.id', 'selectedEmployee', function () {
      return this.get('model.id') === this.get('selectedEmployee');
    }),

    actions: {
      selectEmployee: function selectEmployee() {
        this.sendAction('selectEmployee', this.get('model'));
      }
    }
  });
});