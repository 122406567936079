define('slate-payroll/components/help-center/vote', ['exports', 'slate-payroll/mixins/ux-ajax-status-hooks'], function (exports, _uxAjaxStatusHooks) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_uxAjaxStatusHooks.default, {
    init: function init() {
      this._super.apply(this, arguments);
      this._getVoteStatus();
    },


    votedDown: Ember.computed.equal('direction', 'down'),

    _getVoteStatus: Ember.observer('article', function () {
      Ember.$.getJSON('/api/help-articles/' + this.get('article.id') + '/vote-status').done(status, Ember.run.bind(this, this.setProperties(status)));
    }),

    actions: {
      vote: function vote(action) {
        if (this.get('working')) {
          return;
        }

        this.ajaxStart();

        Ember.$.post('/api/help-articles/' + this.get('article.id') + '/' + action).done(Ember.run.bind(this, this.ajaxSuccess()), this.setProperties({
          voted: true,
          votedUp: action === 'up',
          direction: action
        })).fail(this.ajaxError.bind(this));
      }
    }
  });
});