define('slate-payroll/components/list-item/employee-ale-det', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var EmployeeAleDetComponent = Ember.Component.extend({
    tagName: 'tr',

    months: Ember.computed('employee.values.[]', 'ranges.[]', function () {
      var ranges = this.get('ranges'),
          values = Ember.A(this.get('employee.values'));

      return ranges.map(function (range) {
        return values.findBy('month', range.month) || false;
      });
    }),

    average: Ember.computed('employee.values.[]', function () {
      var amt = parseFloat(((this.get('employee.values') || []).reduce(function (s, i) {
        return s + (i.employeeValue || 0);
      }, 0) / this.get('ranges.length')).toFixed(2));

      return {
        amt: amt,
        fte: amt < 1
      };
    })
  });

  EmployeeAleDetComponent.reopenClass({ positionalParams: ['employee', 'ranges'] });

  exports.default = EmployeeAleDetComponent;
});