define('slate-payroll/components/x-employee-number-history', ['exports', 'slate-payroll/mixins/ux-ajax-status-hooks'], function (exports, _uxAjaxStatusHooks) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_uxAjaxStatusHooks.default, {
    init: function init() {
      this._super.apply(this, arguments);
      this._getEmployeeNumbers();
    },


    attributeBindings: ['placement:data-placement', 'data-toggle', 'title', '_popoverContent:data-content'],
    placement: 'left',

    _popoverContent: Ember.computed('_employeeNumbers', 'working', function () {
      var employeeNumbers = this.get('_employeeNumbers');

      if (this.get('working')) {
        return '<p class="text-center">Loading recent employee numbers...</p>';
      } else if (!employeeNumbers || !employeeNumbers.length) {
        return '<p class="text-muted">No employee numbers found.</p>';
      } else {
        var msg = '<p>Here are the last few employee numbers you used:</p><ul>';
        employeeNumbers.forEach(function (employeeNumber) {
          msg += '<li>' + employeeNumber + '</li>';
        });
        return msg + '</ul>';
      }
    }),

    didInsertElement: function didInsertElement() {
      this._attachPopover();
    },


    _attachPopover: function _attachPopover() {
      this.$().popover({
        html: true,
        trigger: 'hover'
      });
    },

    _getEmployeeNumbers: function _getEmployeeNumbers() {
      var _this = this;

      this.ajaxStart();

      Ember.$.getJSON('/api/employees/', {
        select: 'employeeNumber',
        sort: { 'time_stamp': -1 },
        limit: 5
      }).then(Ember.run.bind(this, function (result) {
        _this.set('_employeeNumbers', result.employee.mapBy('employeeNumber'));
        _this.ajaxSuccess();
      })).fail(this.ajaxError.bind(this));
    }
  });
});