define('slate-payroll/routes/account/employee/balances', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return Ember.RSVP.hash({
        employee: this.modelFor('account.employee').employee,
        paycodes: this.store.findAll('company-paycode')
      });
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        model: model.employee,
        paycodes: model.paycodes
      });
    }
  });
});