define('slate-payroll/controllers/account/punch', ['exports', 'slate-payroll/mixins/ux-ajax-status-hooks'], function (exports, _uxAjaxStatusHooks) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_uxAjaxStatusHooks.default, {
    modalTitle: 'Edit Punch Notes',
    modalId: 'edit-punch-notes-modal',
    showModal: false,

    actions: {
      toggleProperty: function toggleProperty(prop) {
        this.toggleProperty(prop);
      },
      editPunchNotes: function editPunchNotes() {
        this.set('showModal', true);
      },
      savePunch: function savePunch() {
        var _this = this;

        var punch = this.get('model');

        this.ajaxStart();

        punch.save().then(function () {
          _this.ajaxSuccess();
          _this.set('showModal', false);
        }).catch(this.ajaxError.bind(this));
      },
      deletePunch: function deletePunch() {
        var _this2 = this;

        var punch = this.get('model');

        if (!confirm('You are about to delete ' + punch.get('employee.firstName') + '\'s punch.')) {
          return;
        }

        punch.destroyRecord().then(function () {
          _this2.transitionToRoute('account.pay-periods');
        });
      }
    }
  });
});