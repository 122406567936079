define('slate-payroll/serializers/paystub', ['exports', 'ember-data', 'slate-payroll/serializers/application', 'slate-payroll/utils/serialize-object', 'slate-payroll/utils/expand-serialized-object'], function (exports, _emberData, _application, _serializeObject, _expandSerializedObject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    isNewSerializerAPI: true,
    attrs: {
      earnings: { embedded: 'always' },
      deductions: { embedded: 'always' },
      achTransactions: { embedded: 'always' }
    },

    normalize: function normalize(modelClass, hash) {
      (0, _serializeObject.default)(hash, true, 'yearToDateInformation', 'totals', 'withholding');
      return this._super.apply(this, arguments);
    },
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);
      (0, _expandSerializedObject.default)(json, 'yearToDateInformation', 'totals', 'withholding');
      return json;
    }
  });
});