define('slate-payroll/components/input/multi-select', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['multi-select', 'list-group'],
    _selection: Ember.A(),

    selectionChanged: Ember.observer('selection.[]', function () {
      var selection = this.get('selection');
      if (selection) {
        var a = Ember.A();
        a.addObjects(selection);
        this.set('_selection', a);
      }
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      this.selectionChanged();
    },
    toggleItem: function toggleItem(value) {
      var selection = this.get('_selection');
      selection[selection.includes(value) ? 'removeObject' : 'addObject'](value);
      this.get('onChange')(selection);
    }
  });
});