define('slate-payroll/components/visualizations/organization-tree-list/employee-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var EmployeeNodeComponent = Ember.Component.extend({ tagName: '' });

  EmployeeNodeComponent.reopenClass({ positionalParams: ['node', 'parent'] });

  exports.default = EmployeeNodeComponent;
});