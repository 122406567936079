define('slate-payroll/controllers/account/reminders/add', ['exports', 'slate-payroll/mixins/controller-abstractions/add-edit'], function (exports, _addEdit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_addEdit.default, {
    init: function init() {
      this._super.apply(this, arguments);
      this.repeatUnits = ['days', 'weeks', 'months', 'years'];
      this.requireFields = ['description', 'starts', 'warningDaysBefore'];
    },

    // Abstraction options
    transitionAfterSave: 'account.reminders.index',
    transitionWithModel: false,

    updateRepeatVars: Ember.observer('repeats', function () {
      if (!this.get('repeats')) {
        this.get('model').setProperties({
          repeatUnits: undefined,
          repeatUnit: undefined
        });
      }
    })
  });
});