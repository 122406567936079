define('slate-payroll/routes/account/time-off/historical', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    limit: 25,
    queryParams: {
      page: { refreshModel: true },
      e: { refreshModel: true }
    },

    model: function model(params) {
      var page = params.page - 1;

      var query = {
        page: page || 0,
        limit: this.get('limit'),
        sort: { 'name.last': 1 }
      };
      var employeesQuery = {};

      if (params.e) {
        query.employee = params.e;
      }

      return Ember.RSVP.hash({
        model: this.store.query('time-off-request', query),
        employees: this.store.query('employee', employeesQuery)
      });
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        model: model.model,
        employees: model.employees,
        limit: this.get('limit')
      });
    }
  });
});