define('slate-payroll/routes/account/pay-periods/payroll/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    setupController: function setupController(controller, model) {
      controller.setProperties({
        model: model.model,
        transactions: model.transactions
      });
    }
  });
});