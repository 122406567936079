define('slate-payroll/components/list-item/blackout-item', ['exports', 'slate-payroll/mixins/confirm', 'slate-payroll/mixins/controller-abstractions/delete'], function (exports, _confirm, _delete) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var BlackoutItemComponent = Ember.Component.extend(_confirm.default, _delete.default, {
    classNames: ['list-group-item', 'clearfix'],

    actions: {
      deleteBlackout: function deleteBlackout() {
        var _this = this;

        this.confirm('Are you sure you want to delete this blackout?', 'Are you sure?', 'confirmState').then(function (ok) {
          if (ok) {
            _this.send('delete', _this.get('model'));
          }
        });
      }
    }
  });

  BlackoutItemComponent.reopenClass({ positionalParams: ['model'] });

  exports.default = BlackoutItemComponent;
});