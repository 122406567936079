define('slate-payroll/routes/setup-account/ds', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      var _this = this;

      return Ember.$.getJSON('/api/user/activate/' + params.userid).then(function (result) {
        if (result.hasPassword) {
          _this.controllerFor('login').set('preActivatedAccount', true);
          return _this.transitionTo('login');
        } else {
          result.id = params.userid;
          return result;
        }
      });
    },

    actions: {
      error: function error() {
        this.transitionTo('setup-account.error');
      }
    }
  });
});