define('slate-payroll/components/list-item/document-read-by', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['list-group-item', 'clearfix'],

    signature: Ember.computed('signatures.@each.employee.id', 'employee', function () {
      var employeeId = this.get('employee.id');

      return this.get('signatures').find(function (signature) {
        return signature.get('employee.id') === employeeId;
      });
    }),

    actions: {
      toggleSignature: function toggleSignature() {
        this.toggleProperty('showSignature');
      }
    }
  });
});