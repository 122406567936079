define('slate-payroll/services/ajax', ['exports', 'ember-ajax/services/ajax'], function (exports, _ajax) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ajax.default.extend({
    auth: Ember.inject.service(),

    headers: Ember.computed('auth.session.token', {
      get: function get() {
        var headers = {};
        var token = this.get('auth.session.token');

        if (token) {
          headers['X-API-Token'] = token;
        }

        return headers;
      }
    }),

    normalizeErrorResponse: function normalizeErrorResponse(status, headers, payload) {
      if (typeof payload === 'string') {
        return [{ detail: payload }];
      }
    }
  });
});