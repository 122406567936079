define('slate-payroll/mixins/controller-abstractions/resource', ['exports', 'slate-payroll/mixins/controller-abstractions/pagination'], function (exports, _pagination) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_pagination.default, {
    queryParams: ['page', 'limit', 'sortAsc', 'sortBy'],
    limit: 20,
    page: 1
  });
});