define('slate-payroll/components/x-footer', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'footer',
    classNames: ['app-footer'],

    currentYear: Ember.computed(function () {
      return (0, _moment.default)().format('YYYY');
    })
  });
});