define('slate-payroll/controllers/account/help/category/article', ['exports', 'slate-payroll/mixins/ux-ajax-status-hooks'], function (exports, _uxAjaxStatusHooks) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_uxAjaxStatusHooks.default, {
    init: function init() {
      this._super.apply(this, arguments);
      this._fetchRelatedArticles();
    },

    _fetchRelatedArticles: Ember.observer('model', 'model.tags.[]', 'model.id', function () {
      var _this = this;

      var article = this.get('model');

      if (!article || !article.get('tags') || article.get('tags.length') < 1) {
        return this.set('relatedArticles', undefined);
      }

      var $regex = '(' + article.get('tags').join('|') + ')',
          $options = 'gi';

      this.ajaxStart();

      this.store.query('help-article', {
        _id: { $ne: article.get('id') },
        $or: [{
          body: {
            $regex: $regex,
            $options: $options
          }
        }, {
          title: {
            $regex: $regex,
            $options: $options
          }
        }],
        limit: 10
      }).then(function (relatedArticles) {
        _this.set('relatedArticles', relatedArticles);
        _this.ajaxSuccess();
      }).catch(this.ajaxError.bind(this));
    })
  });
});