define('slate-payroll/serializers/application', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTSerializer.extend({
    isNewSerializerAPI: true,

    normalize: function normalize(modelClass, hash) {
      if (!hash.id && hash._id) {
        hash.id = hash._id;
      }

      delete hash._id;
      delete hash.__v;

      return this._super.apply(this, arguments);
    }
  });
});