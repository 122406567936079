define('slate-payroll/controllers/account/pay-period/settings', ['exports', 'moment', 'slate-payroll/mixins/controller-abstractions/delete', 'slate-payroll/mixins/disable-holidays'], function (exports, _moment, _delete, _disableHolidays) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_delete.default, _disableHolidays.default, {
    disableForm: Ember.computed.or('working', 'modelIsNotDirty'),
    modelIsNotDirty: Ember.computed.not('model.hasDirtyAttributes'),

    actions: {
      saveSettings: function saveSettings() {
        var _this = this;

        this.ajaxStart();
        this.set('model.start', new Date((0, _moment.default)(this.get('model.start')).startOf('day').utc()));
        this.set('model.end', new Date((0, _moment.default)(this.get('model.end')).endOf('day').utc()));

        this.get('model').save().then(function () {
          _this.ajaxSuccess('Successfully saved pay period.');
        }).catch(this.ajaxError.bind(this));
      }
    }
  });
});