define('slate-payroll/routes/account/user/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      if (this.modelFor('account.user').isSelf) {
        return this.transitionTo('account.user.index');
      }
    },
    model: function model() {
      return Ember.RSVP.hash({
        model: this.modelFor('account.user'),
        permissions: this.store.findAll('permission', { sort: { name: 1 } }),
        employees: this.store.query('employee', {
          sort: {
            'name.last': 1,
            'name.first': 1
          }
        })
      });
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        model: model.model.user,
        permissions: model.permissions,
        employees: model.employees
      });
    },


    actions: {
      willTransition: function willTransition(transition) {
        this._super.apply(this, arguments);

        var model = this.controller.model,
            hasChangedAttributes = Object.keys(model.changedAttributes()).length > 0;


        if (hasChangedAttributes && !confirm('Are you sure you want to abandon progress on this page?')) {
          transition.abort();
        } else {
          if (hasChangedAttributes) {
            model.rollbackAttributes();
          }

          return true;
        }
      }
    }
  });
});