define('slate-payroll/mixins/scroll-reveal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    init: function init() {
      this._super.apply(this, arguments);
      this._initScrollReveal();
    },


    _initScrollReveal: Ember.observer('scrollRevealNeedsRender', function () {
      var _this = this;

      Ember.run.scheduleOnce('afterRender', function () {
        if (!_this.get('scrollRevealNeedsRender')) {
          return;
        }

        var selector = _this.get('scrollRevealSelector');
        window.sr = new scrollReveal();

        if (selector) {
          window.sr.reveal(selector);
        }

        _this.set('scrollRevealNeedsRender', false);
      });
    })
  });
});