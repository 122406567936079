define('slate-payroll/routes/account/pay-period/paystubs', ['exports', 'slate-payroll/mixins/refreshable'], function (exports, _refreshable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Route.extend(_refreshable.default, {
    queryParams: {
      filterEmployees: { refreshModel: true },
      page: { refreshModel: true },
      sortAsc: { refreshModel: true },
      sortBy: { refreshModel: true },
      limit: { refreshModel: true }
    },

    model: function model(params) {
      var page = params.page ? params.page - 1 : 0,
          canDel = this.get('canDeletePaystub');

      var query = {
        page: page,
        limit: params.limit,
        payPeriod: this.modelFor('account.pay-period').get('id'),
        sort: {}
      };

      query.sort[params.sortBy] = params.sortAsc ? 1 : -1;

      if (params.filterEmployees && params.filterEmployees.length > 0) {
        query.employee = { $in: params.filterEmployees.toArray() };
      }

      return Ember.RSVP.hash({
        params: params,
        paystubs: this.store.query('paystub', query),
        employees: this.store.query('employee', {
          $or: [{ terminatedOn: { $not: { $type: 9 } } }, { terminatedOn: { $gt: this.modelFor('account.pay-period').get('start') } }],
          sort: { 'name.last': 1 }
        }),
        canDelete: (typeof canDel === 'undefined' ? 'undefined' : _typeof(canDel)) === Boolean ? canDel : this.auth.hasPermission('DELETE_PAYSTUB')
      });
    },
    setupController: function setupController(controller, model) {
      var qp = model.params || {};

      this.set('canDeletePaystub', !!model.canDelete);

      controller.setProperties({
        model: model.paystubs,
        employees: model.employees,
        canDeletePaystub: this.get('canDeletePaystub'),
        filterByEmployees: Ember.A(Ember.get(qp, 'filterEmployees'))
      });
    }
  });
});