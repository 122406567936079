define('slate-payroll/mixins/route-abstractions/paginated-view', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    limit: 10,
    queryParams: {
      page: { refreshModel: true },
      sortBy: { refreshModel: true },
      sortAsc: { refreshModel: true }
    },

    model: function model(params) {
      var modelName = this.get('modelName'),
          filters = this.get('filterSettings'),
          page = params.page - 1;

      (false && !(modelName) && Ember.assert('You must specify a modelName.', modelName));


      var query = {
        page: page || 0,
        limit: this.get('controller.limit') || this.get('limit'),
        sort: {}
      };

      query.sort[params.sortBy] = params.sortAsc ? -1 : 1;

      if (filters) {
        filters.forEach(function (filter) {
          if (params[filter.name]) {
            Ember.$.extend(query, filter.merge(params));
          }
        });
      }

      return this.store.query(modelName, query);
    }
  });
});