define('slate-payroll/components/input/forms/w-4', ['exports', 'slate-payroll/config/app-data'], function (exports, _appData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var STEPPING_STEP3 = 500;

  exports.default = Ember.Component.extend({
    filingStatuses: _appData.filingStatuses,
    classNames: ['row'],
    federalTaxStep2: [{
      label: 'Yes',
      value: true
    }, {
      label: 'No',
      value: false
    }],

    isExempt: Ember.computed.equal('model.federalTaxFilingStatus', 'Exempt'),

    touched: function touched() {
      var _this = this;

      ['federalTaxStep3', 'federalTaxStep4a', 'federalTaxStep4b', 'federalTaxStep4c'].forEach(function (key) {
        var val = _this.model[key];

        if (val === undefined || val === null) {
          _this.model.set(key, 0);
        }
      });
    },
    updateFederalTaxFilingStatus: function updateFederalTaxFilingStatus(val) {
      this.model.setProperties({
        federalTaxAllowances: null,
        federalTaxAdditionalWithholding: null,
        federalTaxFilingStatus: val
      });

      this.touched();

      if (this.isExempt) {
        this.model.setProperties({
          federalTaxStep2: null,
          federalTaxStep3: null,
          federalTaxStep4a: null,
          federalTaxStep4b: null,
          federalTaxStep4c: null
        });
      }
    },
    updateStep2: function updateStep2(val) {
      this.model.set('federalTaxStep2', val);
      this.touched();
    },
    updateStep3: function updateStep3(val) {
      var nVal = val && parseInt(val, 0);
      this.model.set('federalTaxStep3', nVal % STEPPING_STEP3 ?
      // Value must be rounded to a step
      Math.round(nVal / STEPPING_STEP3) * STEPPING_STEP3 :
      // Value is in the stepping range
      nVal);
    }
  });
});