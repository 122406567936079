define('slate-payroll/controllers/account/pay-period/close', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.navLinks = [{
        title: 'Get Started',
        icon: 'fa-angle-right',
        link: 'account.pay-period.close.index'
      }, {
        title: 'Incomplete Punches',
        icon: 'fa-circle-notch',
        link: 'account.pay-period.close.incomplete-punches'
      }, {
        title: 'Audits',
        icon: 'fa-check',
        link: 'account.pay-period.close.audit'
      }, {
        title: 'Add Punches',
        icon: 'fa-plus',
        link: 'account.pay-period.close.add-punches'
      }, {
        title: 'Review',
        icon: 'fa-lock',
        link: 'account.pay-period.close.review'
      }];
    },


    actions: {
      updateCollapsePreference: function updateCollapsePreference(pref) {
        this.auth.setPreference('payPeriodNavCollapsed', pref);
      }
    }
  });
});