define('slate-payroll/serializers/overtime-rule', ['exports', 'slate-payroll/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    isNewSerializerAPI: true,

    normalize: function normalize() {
      return this._super.apply(this, arguments);
    },
    serialize: function serialize(snapshot, options) {
      var json = this._super(snapshot, options);

      json._id = json.id;
      delete json.id;

      return json;
    }
  });
});