define('slate-payroll/controllers/account/time-off/approve', ['exports', 'slate-payroll/mixins/render-tooltips', 'slate-payroll/mixins/controller-abstractions/add-edit', 'slate-payroll/mixins/confirm'], function (exports, _renderTooltips, _addEdit, _confirm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_renderTooltips.default, _addEdit.default, _confirm.default, {
    auth: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.requireFields = ['companyPaycode.content'];
      this.requireFieldDescriptors = { 'companyPaycode.content': 'paycode' };
    },

    transitionWithModel: false,
    transitionAfterSave: 'account.time-off.index',

    actions: {
      approveRequest: function approveRequest() {
        var _this = this;

        var model = this.get('model');

        this.confirm('Are you sure you want to approve this request?', 'Are you sure?').then(function (ok) {
          if (ok) {
            model.set('approved', true);
            _this.send('save', model);
          } else {
            _this.transitionToRoute('account.time-off.approve', model.get('id'));
          }
        });
      }
    }
  });
});