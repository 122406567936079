define('slate-payroll/controllers/account/time-off/settings', ['exports', 'moment', 'slate-payroll/mixins/controller-abstractions/add-edit', 'slate-payroll/mixins/controller-abstractions/resource'], function (exports, _moment, _addEdit, _resource) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_addEdit.default, _resource.default, {
    init: function init() {
      this._super.apply(this, arguments);

      this.basePickerOpts = {
        selectYears: true,
        selectMonths: true,
        container: 'body'
      };
    },
    afterSave: function afterSave() {
      this.resetModel();
      this.get('target').send('refresh');
    },
    resetModel: function resetModel() {
      this.set('pendingBlackout', null);
      this.setProperties({
        start: null,
        end: null
      });
    },


    dateRange: Ember.computed('start', 'end', function () {
      return [this.get('start'), this.get('end')];
    }),

    actions: {
      selectAllLocations: function selectAllLocations() {
        this.get('pendingBlackout.locations').addObjects(this.get('locations'));
      },
      deselectAllLocations: function deselectAllLocations() {
        this.set('pendingBlackout.locations', Ember.A());
      },
      selectAllDepartments: function selectAllDepartments() {
        this.get('pendingBlackout.departments').addObjects(this.get('departments'));
      },
      deselectAllDepartments: function deselectAllDepartments() {
        this.set('pendingBlackout.departments', Ember.A());
      },
      setDateRange: function setDateRange(dateRange) {
        var start = dateRange[0] && (0, _moment.default)(dateRange[0]).startOf('day').toDate(),
            end = dateRange[1] && (0, _moment.default)(dateRange[1]).endOf('day').toDate();

        this.setProperties({
          start: start,
          end: end
        });

        this.get('pendingBlackout').setProperties({
          start: start,
          end: end
        });
      },
      startAddingBlackout: function startAddingBlackout() {
        this.set('pendingBlackout', this.store.createRecord('time-off-blackout'));
      },
      cancelAddingBlackout: function cancelAddingBlackout() {
        this.get('pendingBlackout').destroyRecord();
        this.resetModel();
      },
      itemDeleted: function itemDeleted() {
        this.get('target').send('refresh');
      }
    }
  });
});