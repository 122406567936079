define('slate-payroll/mixins/array-qp-tweaks', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    deserializeQueryParam: function deserializeQueryParam(value, urlKey, defaultValueType) {
      if (defaultValueType === 'array') {
        return (/^\[".*\]/.test(value) ? value.replace(/["\s[\]]/g, '').split(',') : value.split('-')
        );
      } else {
        return this._super.apply(this, arguments);
      }
    },
    serializeQueryParam: function serializeQueryParam(value, urlKey, defaultValueType) {
      if (defaultValueType === 'array') {
        return typeof value === 'string' ? value : value.join('-');
      } else {
        return this._super.apply(this, arguments);
      }
    },
    coerceToArray: function coerceToArray(val) {
      var retVal = val && val.toArray ? val.toArray() : val;

      if (retVal && !Array.isArray(retVal) && typeof retVal === 'string') {
        try {
          retVal = JSON.parse(retVal.replace(/\\/g, ''));
        } catch (e) {
          retVal = retVal.match(/(\d\w*)/g);
        }
      }

      return retVal;
    }
  });
});