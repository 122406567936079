define("slate-payroll/utils/fade-rgb", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = fadeRgb;
  function fadeRgb(_ref, opacity) {
    var r = _ref.r,
        g = _ref.g,
        b = _ref.b;

    return "rgba(" + r + ", " + g + ", " + b + ", " + opacity + ")";
  }
});