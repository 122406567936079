define('slate-payroll/routes/account/pay-period/close', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = Ember.Route.extend({
    model: function model() {
      return {
        model: this._super.apply(this, arguments),
        navCollapsed: this.auth.getPreference('payPeriodNavCollapsed')
      };
    },
    setupController: function setupController(controller, model) {
      controller.setProperties(_extends({}, model));
    }
  });
});