define('slate-payroll/components/list/collapsing-nav-pills', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var CollapsingNavPillsComponent = Ember.Component.extend({
    tagName: 'ul',
    classNames: ['nav', 'nav-pills', 'nav-stacked', 'nav-pills--collapsing'],
    classNameBindings: ['collapsed:nav-pills--collapsed'],
    collapsed: false,

    didReceiveAttrs: function didReceiveAttrs() {
      this.collapsed = !!this.isCollapsed;
    }
  });

  CollapsingNavPillsComponent.reopenClass({ positionalParams: ['links'] });

  exports.default = CollapsingNavPillsComponent;
});