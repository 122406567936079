define('slate-payroll/routes/account/data-explorer/explore/reports/run', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return this.store.find('query', params.id);
    },
    afterModel: function afterModel(query) {
      var _this = this;

      var qid = query.get('id'),
          dataPoints = query.get('dataPoints'),
          parameterController = this.controllerFor('account.data-explorer.explore.reports.parameters'),
          parameters = Ember.get(parameterController, 'parameters'),
          parametersFor = Ember.get(parameterController, 'parametersFor');

      var body = void 0;

      if (dataPoints && dataPoints.findBy('parameter', true) && parametersFor !== qid) {
        return this.transitionTo('account.data-explorer.explore.reports.parameters', query);
      }

      if (parameters && Object.keys(parameters).length > 0 && parametersFor === qid) {
        body = { parameters: parameters };
        Ember.set(parameterController, 'parameters', {});
        Ember.set(parameterController, 'model', undefined);
      }

      Ember.$.post('/api/queries/' + qid + '/run', body).done(Ember.run.bind(this, function (result) {
        this.transitionTo('account.data-explorer.explore.reports.view.result', qid, result.queryResult._id);
      })).fail(function (err) {
        _this.send('error', err);
      });
    },


    actions: {
      willTransition: function willTransition() {
        return true;
      }
    }
  });
});