define('slate-payroll/components/input/report-param', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ReportParamComponent = Ember.Component.extend({
    humanizedKey: Ember.computed('param.paramKey', function () {
      return Ember.String.capitalize(Ember.String.decamelize(this.get('param.paramKey')).replace(/_/g, ' '));
    })
  });

  ReportParamComponent.reopenClass({ positionalParams: ['param'] });

  exports.default = ReportParamComponent;
});