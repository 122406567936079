define('slate-payroll/routes/account/employees/census/review', ['exports', 'slate-payroll/mixins/refreshable'], function (exports, _refreshable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_refreshable.default, {
    model: function model(_ref) {
      var uploadId = _ref.uploadId;

      return Ember.$.getJSON('/api/employee/census/' + uploadId).then(function (fileData) {
        return Ember.RSVP.hash({
          fileData: fileData,
          potentialData: Ember.$.post('/api/employee/census/' + uploadId + '/dryrun', { headerMap: fileData.data[0] })
        });
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      controller.setProperties({
        dryrunResult: null,
        model: model.fileData || model,
        potentialData: model.potentialData
      });
    }
  });
});