define('slate-payroll/components/form-step', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var FormStepComponent = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.set('_formIndex', this.get('onRender')(this));
    },


    show: Ember.computed('step', '_formIndex', function () {
      return this.get('step') === this.get('_formIndex');
    }),

    _focus: function _focus() {
      var _this = this;

      if (this.get('autofocus') === false) {
        return;
      }

      Ember.run.scheduleOnce('afterRender', function () {
        var $input = _this.$().find('input:first') || _this.$().find('select:first');

        if ($input) {
          $input.focus();
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      this.get('onDestroy')(this);
    }
  });

  FormStepComponent.reopenClass({ positionalParams: ['stepName'] });

  exports.default = FormStepComponent;
});