define('slate-payroll/routes/account/pay-periods/payroll/correct', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return Ember.RSVP.hash({
        payroll: this._super.apply(this, arguments),
        employees: this.store.query('employee', {
          $or: [{ terminatedOn: { $not: { $type: 9 } } }, { terminatedOn: { $gt: (0, _moment.default)().subtract(1, 'month').toDate() } }],
          sort: { 'name.last': 1 }
        }),
        paycodes: this.store.query('company-paycode', { sort: { description: 1 } })
      });
    },
    afterModel: function afterModel(model) {
      if (!model.payroll.model.inGracePeriod || model.payroll.model.belongsTo('payPeriod').id()) {
        return this.transitionTo('account.pay-periods.payroll.index');
      }
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        model: model.payroll.model,
        employees: model.employees,
        paycodes: model.paycodes,
        transactions: model.payroll.transactions.toArray() || Ember.A()
      });
    }
  });
});