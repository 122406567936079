define('slate-payroll/serializers/automated-paycode', ['exports', 'ember-data', 'slate-payroll/serializers/application'], function (exports, _emberData, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    isNewSerializerAPI: true,
    attrs: {
      companyPaycode: {
        deserialize: 'records',
        serialize: 'ids'
      }
    }
  });
});