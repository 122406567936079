define('slate-payroll/routes/account/pay-periods/payrolls/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    queryParams: {
      page: { refreshModel: true },
      sortAsc: { refreshModel: true }
    },

    model: function model(params) {
      return this.store.query('payroll', {
        finalizedOn: { $exists: true },
        page: params.page - 1 || 0,
        limit: this.get('controller.limit') || 5,
        wasCorrected: { $ne: true },
        sort: { finalizedOn: params.sortAsc ? -1 : 1 }
      });
    }
  });
});