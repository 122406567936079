define('slate-payroll/controllers/account/pay-periods/review-auto', ['exports', 'slate-payroll/mixins/disable-holidays', 'slate-payroll/mixins/ux-ajax-status-hooks', 'moment'], function (exports, _disableHolidays, _uxAjaxStatusHooks, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_disableHolidays.default, _uxAjaxStatusHooks.default, {
    actions: {
      editPayPeriod: function editPayPeriod(payPeriod) {
        this.setProperties({
          pendingPayPeriod: payPeriod,
          isEditing: true
        });
      },
      deletePayPeriod: function deletePayPeriod(payPeriod) {
        payPeriod.destroyRecord();
        this.get('model').removeObject(payPeriod);
      },
      saveEdits: function saveEdits() {
        this.get('pendingPayPeriod').set('end', (0, _moment.default)(this.get('pendingPayPeriod.end')).endOf('day').toDate());

        this.setProperties({
          pendingPayPeriod: null,
          isEditing: false
        });
      },
      savePayPeriods: function savePayPeriods() {
        var _this = this;

        var payPeriods = this.get('model');

        this.ajaxStart();

        Ember.RSVP.all(payPeriods.map(function (payPeriod) {
          return payPeriod.save();
        })).then(function () {
          _this.ajaxSuccess();
          _this.transitionToRoute('account.pay-periods');
        }).catch(this.ajaxError.bind(this));
      }
    }
  });
});