define('slate-payroll/models/session', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    token: (0, _attr.default)('string'),
    expires: (0, _attr.default)('string'),
    user: (0, _attr.default)('string'),
    spoofing: (0, _attr.default)('boolean'),
    twoFactorRequired: (0, _attr.default)('boolean'),
    twoFactorAuthorized: (0, _attr.default)('boolean')
  });
});