define('slate-payroll/models/approval', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    approved: (0, _attr.default)('array'),

    payPeriod: (0, _relationships.belongsTo)('pay-period', {
      async: true,
      inverse: null
    }),
    employee: (0, _relationships.belongsTo)('employee', {
      async: true,
      inverse: null
    }), // supervisor
    standInEmployee: (0, _relationships.belongsTo)('employee', {
      async: true,
      inverse: null
    }),
    company: (0, _relationships.belongsTo)('company', {
      async: true,
      inverse: null
    }),

    completedOn: (0, _attr.default)('date'),
    quickApproval: (0, _attr.default)('boolean'),
    managerRejected: (0, _attr.default)('date'),
    managerComment: (0, _attr.default)('string'),
    managerApproval: (0, _attr.default)('string'),
    supervisorComment: (0, _attr.default)('string'),

    created: (0, _attr.default)('date', { defaultValue: function defaultValue() {
        return new Date();
      } })
  });
});