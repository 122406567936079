define('slate-payroll/instance-initializers/rollbar', ['exports', 'ember-rollbar-client/instance-initializers/rollbar'], function (exports, _rollbar) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _rollbar.default;
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function () {
      return _rollbar.initialize;
    }
  });
});