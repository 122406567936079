define('slate-payroll/routes/account/pay-periods/payrolls/new', ['exports', 'moment', 'slate-payroll/mixins/route-abstractions/add'], function (exports, _moment, _add) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_add.default, {
    modelName: 'payroll',

    model: function model() {
      return Ember.RSVP.hash({
        model: this._super.apply(this, arguments),
        employees: this.store.query('employee', {
          $or: [{ terminatedOn: { $not: { $type: 9 } } }, { terminatedOn: { $gt: (0, _moment.default)().subtract(1, 'month').toDate() } }],
          sort: { 'name.last': 1 }
        }),
        paycodes: this.store.query('company-paycode', { sort: { description: 1 } })
      });
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        model: model.model,
        employees: model.employees,
        paycodes: model.paycodes
      });
    }
  });
});