define('slate-payroll/controllers/account/punch/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.hoursChart = {
        chartOptions: {
          color: { pattern: ['#374140', '#3E606F', '#79BD8F'] },
          legend: { position: 'right' }
        },
        dataOptions: { type: 'donut' }
      };
    },


    hoursDataset: Ember.computed('model.{hours,breaks,lunch}', function () {
      var data = this.get('model').getProperties('hours', 'breaks', 'lunch');

      return Ember.A([['Working Hours', data.hours], ['Breaks', data.breaks], ['Lunch', data.lunch]]);
    })
  });
});