define('slate-payroll/controllers/account/employee/paystubs', ['exports', 'slate-payroll/mixins/ux-ajax-status-hooks'], function (exports, _uxAjaxStatusHooks) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_uxAjaxStatusHooks.default, {
    actions: {
      deletePaystub: function deletePaystub(paystub) {
        var _this = this;

        if (!confirm('Are you sure you want to delete this paystub?')) {
          return;
        }

        this.ajaxStart();

        paystub.destroyRecord().then(function () {
          _this.ajaxSuccess();
          _this.send('refresh');
        }).catch(this.ajaxError.bind(this));
      },
      printPaystub: function printPaystub(paystubId) {
        this.set('printOnInit', true);
        this.transitionToRoute('account.employee.paystub', paystubId);
      },
      downloadPaystub: function downloadPaystub(paystubId) {
        this.set('downloadOnInit', true);
        this.transitionToRoute('account.employee.paystub', paystubId.id);
      }
    }
  });
});