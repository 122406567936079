define('slate-payroll/controllers/account/reminders/edit', ['exports', 'moment', 'slate-payroll/mixins/controller-abstractions/add-edit'], function (exports, _moment, _addEdit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_addEdit.default, {
    init: function init() {
      this._super.apply(this, arguments);
      this.repeatUnits = ['days', 'weeks', 'months', 'years'];
      this.requireFields = ['description', 'starts', 'warningDaysBefore'];
    },

    // Abstraction options
    transitionAfterSave: 'account.reminders.index',
    transitionWithModel: false,

    setupModelAttributes: Ember.observer('model', function () {
      var model = this.get('model');

      if (!model) {
        return;
      }

      var starts = model.get('starts');

      this.setProperties({
        repeats: model.get('repeatUnit') && model.get('repeatUnits'),
        reminderDateTextValue: starts ? (0, _moment.default)(starts).format('DD MMMM, YYYY') : undefined,
        reminderTimeTextValue: starts ? (0, _moment.default)(starts).format('h:mm A') : undefined
      });
    }),

    updateRepeatVars: Ember.observer('repeats', function () {
      if (!this.get('repeats')) {
        this.get('model').setProperties({
          repeatUnits: undefined,
          repeatUnit: undefined
        });
      }
    }),

    updateReminderTime: Ember.observer('reminderDate', 'reminderTime', function () {
      var data = this.getProperties('reminderDate', 'reminderTime');

      if (!data.reminderDate && !data.reminderTime) {
        return this.set('model.starts', undefined);
      }

      var date = (0, _moment.default)(data.reminderDate),
          time = (0, _moment.default)(data.reminderTime, 'h:mm A');

      if (data.reminderTime) {
        date = date.set('hour', time.get('hour')).set('minute', time.get('minute')).toDate();
      } else {
        date = date.startOf('day').toDate();
      }

      this.set('model.starts', date);
    })
  });
});