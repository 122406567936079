define('slate-payroll/controllers/account/pay-period/punches/add', ['exports', 'slate-payroll/config/app-data', 'slate-payroll/mixins/ux-ajax-status-hooks', 'moment'], function (exports, _appData, _uxAjaxStatusHooks, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Controller.extend(_uxAjaxStatusHooks.default, {
    queryParams: ['unitEntry', 'employee', 'nav', 'i', 'q'],
    parentController: Ember.inject.controller('account/pay-period'),
    punchesController: Ember.inject.controller('account/pay-period/punches'),
    showModal: true,
    unitEntry: false,
    modalId: 'add-punch-modal',
    lunchIsOther: Ember.computed.equal('punchLunch', 'other'),

    modalTitle: Ember.computed('model.payPeriod.{start,end}', function () {
      var payPeriod = this.get('model.payPeriod'),
          start = (0, _moment.default)(payPeriod.get('start')).format('MMMM Do'),
          end = (0, _moment.default)(payPeriod.get('end')).format('MMMM Do');

      return '<h2>Add Punch For Pay Period <br /><small class="text-sm">[' + start + ' - ' + end + ']</small></h2>';
    }),

    balanceForCode: Ember.computed('model.{companyPaycode,employee.balances.[]}', function () {
      var paycode = this.get('model.companyPaycode');

      return paycode && paycode.get('timeOffCapability') && (this.get('model.employee.balances') || []).find(function (balance) {
        return balance.belongsTo('companyPaycode').id() === paycode.get('id');
      });
    }),

    isMultiDay: Ember.computed('model.{timeIn,timeOut}', function () {
      var _model = this.model,
          timeIn = _model.timeIn,
          timeOut = _model.timeOut;

      return timeIn && timeOut && !(0, _moment.default)(timeOut).isSame(timeIn, 'day');
    }),

    updateLunch: Ember.observer('punchLunch', 'otherPunchLunch', function () {
      var data = this.getProperties('punchLunch', 'otherPunchLunch');
      var lunch = data.punchLunch !== 'other' ? data.punchLunch / 60 : data.otherPunchLunch / 60 || 0;

      this.set('model.lunch', lunch.toFixed(2) / 1);
    }),

    disableDateOut: Ember.computed(function () {
      var _this = this;

      return [function (date) {
        var timeIn = _this.get('model.timeIn');
        return (0, _moment.default)(date).endOf('day').isBefore(timeIn);
      }];
    }),

    resetForm: function resetForm() {
      this.setProperties({
        autoDateOut: undefined,
        punchLunch: undefined,
        other: undefined
      });
    },
    transitionToParentRoute: function transitionToParentRoute() {
      this.resetForm();
      this.transitionToRoute('account.pay-period.punches', this.get('parentController.model.id'));
    },
    autosetDateOut: function autosetDateOut() {
      var timeIn = this.get('model.timeIn'),
          timeOut = this.get('model.timeOut');

      if (timeIn && (!timeOut || !(0, _moment.default)(timeIn).isSame(timeOut, 'day'))) {
        var timeOutMut = (0, _moment.default)(timeIn);

        if (!timeOut) {
          timeOutMut.add(8, 'hours');
        } else {
          var toM = (0, _moment.default)(timeOut);

          timeOutMut.set({
            hour: toM.hour(),
            minute: toM.minute()
          });
        }

        this.set('model.timeOut', timeOutMut.toDate());
      }
    },


    actions: {
      cancel: function cancel() {
        this.transitionToParentRoute();
      },
      savePunch: function savePunch() {
        var _this2 = this;

        var punch = this.get('model');

        this.ajaxStart();
        this.set('lockSelection', true);

        if (!punch.get('employee.id')) {
          return this.ajaxError('No employee selected');
        }

        if (!punch.get('companyPaycode.id')) {
          return this.ajaxError('No paycode selected');
        }

        punch.set('directHourEntry', this.get('unitEntry'));

        if (this.get('unitEntry')) {
          var paycode = punch.get('companyPaycode'),
              mapping = _appData.paycodeUnitMap[paycode.get('unitActual')],
              units = this.get('units');

          if (!mapping) {
            return this.ajaxError('Unable to find suitable punch unit type.');
          }

          if ((typeof mapping === 'undefined' ? 'undefined' : _typeof(mapping)) === 'object') {
            mapping = mapping[paycode.get('type')];
          }

          if (typeof mapping === 'function') {
            mapping = mapping(units);
          }

          punch.set(mapping, units);

          var time = (0, _moment.default)(punch.get('created')).hour(12).minute(0).second(0).toDate();
          punch.setProperties({
            timeIn: time,
            timeOut: time,
            created: new Date()
          });
        }

        punch.save().then(function () {
          _this2.ajaxSuccess();
          _this2.set('units', null);
          _this2.transitionToParentRoute();
        }).catch(this.ajaxError.bind(this));
      }
    }
  });
});