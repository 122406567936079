define('slate-payroll/mixins/resource-pagination', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    __maxItems: Ember.computed.alias('model.meta.totalRecords'),

    pages: Ember.computed('__maxItems', 'limit', function () {
      return Math.ceil(this.get('__maxItems') / this.get('limit'));
    }),

    __minMax: Ember.observer('page', 'pages', function () {
      var page = this.get('page'),
          pages = this.get('pages');

      if (page && page < 0) {
        this.set('page', 1);
      } else if (page && page > pages) {
        this.set('page', pages || 1);
      }
    })
  });
});