define('slate-payroll/controllers/account/index/ale-report', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.aleChart = {
        chartOptions: {
          color: { pattern: ['#374140', '#79BD8F', '#FFD96A', '#0089EC'] },
          axis: {
            x: {
              type: 'timeseries',
              tick: { format: function format(x) {
                  return (0, _moment.default)(x).format('MMMM \'YY');
                } },
              label: {
                text: 'Month',
                position: 'outer-center'
              }
            },
            y: {
              label: {
                text: 'Hours',
                position: 'outer-middle'
              }
            },
            y2: {
              show: true,
              label: {
                text: 'FT/FTEs',
                position: 'outer-middle'
              }
            }
          }
        },

        dataOptions: {
          x: 'x',
          axes: {
            'Total Hours': 'y',
            'Total Employee Values': 'y2',
            'FTE': 'y2',
            'FT': 'y2'
          }
        }
      };
    },


    queryParams: ['start', 'end'],
    start: null,
    end: null,
    ale: Ember.computed.gte('model.averages.employees', 50),

    monthlyAverages: Ember.computed('model.chart.[]', function () {
      return this.get('model.chart').objectAt(2).slice(1);
    }),

    reportXlsxLink: Ember.computed('start', 'end', function () {
      var base = '/api/report/aca-ale-check/download?token=',
          token = encodeURIComponent(this.auth.content.token);

      return '' + base + token + '&start=' + (this.start || '') + '&end=' + (this.end || '');
    }),

    actions: {
      setLookback: function setLookback() {
        this.setProperties({
          start: this.get('lbStart'),
          end: this.get('lbEnd')
        });
      },
      clearLookback: function clearLookback() {
        this.setProperties({
          start: null,
          end: null
        });
      }
    }
  });
});