define('slate-payroll/controllers/account/data-explorer/explore/reports/view/result', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function generateRangesToCurrent(m, rangeType, workWeekStart) {
    var generate = true,
        now = (0, _moment.default)(),
        ranges = Ember.A(),
        isWorkWeek = rangeType === 'work week',
        _ww = isNaN(workWeekStart) ? 7 : workWeekStart,
        _m = m;
    do {
      var _s = isWorkWeek ? (0, _moment.default)(_m).day(_ww).startOf('day').toDate() : (0, _moment.default)(_m).startOf(rangeType).toDate();

      ranges.pushObject({
        start: _s,
        end: isWorkWeek ? (0, _moment.default)(_s).add(6, 'days').endOf('day').toDate() : (0, _moment.default)(_m).endOf(rangeType).toDate()
      });

      if ((0, _moment.default)(ranges.get('lastObject.end')).isAfter(now)) {
        generate = false;
      } else {
        _m.add(1, isWorkWeek ? 'week' : rangeType);
      }
    } while (generate);

    return ranges;
  }

  function identityForRange(range) {
    var f = function f(d) {
      return (0, _moment.default)(d).format('ddd, MMM Do YYYY');
    };
    return f(range.start) + ' - ' + f(range.end);
  }

  exports.default = Ember.Controller.extend({
    printing: Ember.inject.service(),

    propMap: {
      firstName: 'name.first',
      lastName: 'name.last'
    },

    groups: Ember.computed('query.grouping.{groupType,value}', 'workWeekStart', function () {
      var grouping = this.get('query.grouping'),
          type = grouping ? grouping.groupType : grouping,
          isField = type === 'Field',
          columns = this.get('model.columns'),
          workWeekStart = this.get('workWeekStart');

      var value = grouping ? grouping.value : grouping,
          rows = this.get('model.rows');

      if (!grouping || type !== 'Field' && type !== 'Time') {
        return false;
      }

      var groups = Ember.A();

      if (isField) {
        var uniqueFields = Ember.A(),
            alikeColumns = columns.filterBy('name', value),
            identityRows = rows,
            headers = [];

        if (Ember.get(alikeColumns, 'length') > 1) {
          identityRows = identityRows.toArray().map(function (row) {
            var accumulator = [],
                _row = Ember.$.extend({}, row);
            alikeColumns.forEach(function (column) {
              if (!row[value]) {
                return;
              }
              accumulator.push(Ember.get(row[value], column.displayKey));
            });
            _row[value] = accumulator.join(' ');
            return _row;
          });
        }
        identityRows.forEach(function (row) {
          uniqueFields.addObject(Ember.get(row, value));
        });

        uniqueFields.forEach(function (identity) {
          var time = (0, _moment.default)(identity, _moment.default.ISO_8601, true).isValid() && value !== 'employee' ? (0, _moment.default)(identity).format('MM/DD/YY') : false;
          var modIdentity = time ? time : typeof identity === 'string' ? identity : (identity || {}).name || 'Undefined';
          if (!headers.includes(modIdentity)) {
            headers.push(modIdentity);
            groups.pushObject({
              identity: modIdentity,
              rows: rows.filter(function (row, index) {
                if (typeof Ember.get(identityRows[index], value) === 'string') {
                  return Ember.get(identityRows[index], value) === identity;
                }
                return (Ember.get(identityRows[index], value) || {}).name === (identity || {}).name;
              })
            });
          }
        });
      } else {
        value = value.toLowerCase();

        var sortedRows = rows.sortBy('created'),
            firstRow = sortedRows.get('firstObject'),
            timeKey = firstRow && firstRow.time_stamp ? 'time_stamp' : 'created';

        if (!firstRow) {
          return false;
        }

        var firstDate = (0, _moment.default)(Ember.get(firstRow, timeKey));

        var ranges = generateRangesToCurrent(firstDate, value, workWeekStart);
        ranges.forEach(function (range) {
          return groups.pushObject({
            identity: identityForRange(range),
            rows: rows.filter(function (row) {
              var t = (0, _moment.default)(row[timeKey]);
              return t.isAfter(range.start) && t.isBefore(range.end);
            })
          });
        });
      }

      return groups;
    }),

    actions: {
      print: function print() {
        this.printing.currentModal.willPrint();
        Ember.run.scheduleOnce('afterRender', function () {
          return window.print();
        });
      }
    }
  });
});