define('slate-payroll/components/visualizations/organization-tree', ['exports', 'd3-selection', 'd3-zoom', 'd3-hierarchy', 'ember-resize/mixins/resize-aware'], function (exports, _d3Selection, _d3Zoom, _d3Hierarchy, _resizeAware) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var OrganizationTreeComponent = Ember.Component.extend(_resizeAware.default, {
    tagName: 'svg',
    ajax: Ember.inject.service(),
    classNames: ['visualization__org-tree', 'visualization__org-tree--full'],
    attributeBindings: ['width', 'height'],
    resizeWidthSensitive: true,
    resizeHeightSensitive: true,

    nodeRadius: 8,
    baseNodeRadius: 11,

    init: function init() {
      this._super.apply(this, arguments);

      this.set('margin', {
        top: 20,
        right: 120,
        bottom: 20,
        left: 100
      });
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.get('_baseRender').call(this);
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      this.get('_dataUpdate');
    },
    debouncedDidResize: function debouncedDidResize() {
      var _this = this;

      this.setProperties({
        width: this.$().width(),
        height: this.$().height()
      });

      return Ember.run.next(function () {
        Ember.run.scheduleOnce('afterRender', function () {
          var margin = _this.get('margin');
          var svg = _d3Selection.default.select('#' + _this.get('elementId')),
              width = svg.attr('width') - margin.left - margin.right,
              height = svg.attr('height') - margin.top - margin.bottom;

          _this.setProperties({
            width: width,
            height: height
          });
          _this.get('_dataUpdate');
        });
      });
    },
    _baseRender: function _baseRender() {
      var _this2 = this;

      this.setProperties({
        width: this.$().width(),
        height: this.$().height()
      });

      return Ember.run.next(function () {
        Ember.run.scheduleOnce('afterRender', function () {
          var margin = _this2.get('margin'),
              width = margin.right - margin.left,
              height = margin.top - margin.bottom;

          var zoom = _d3Zoom.default.zoom();

          var svg = _d3Selection.default.select('#' + _this2.get('elementId')).attr('minwidth', width).attr('minheight', height).call(zoom.on('zoom', function () {
            svg.attr('transform', _d3Selection.default.event.transform);
          })).append('g'),
              g = svg.append('g').attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

          _this2.setProperties({
            svg: svg,
            g: g
          });

          return _this2.get('_dataUpdate');
        });
      });
    },


    _dataUpdate: Ember.computed('graphNodes', 'nodeRadius', 'baseNodeRadius', 'width', 'height', function () {
      var _this3 = this;

      var minimumXSpacing = 125,
          baseNode = this.get('graphNodes');

      Ember.run.scheduleOnce('afterRender', function () {
        var root = (0, _d3Hierarchy.hierarchy)(baseNode),
            g = _this3.get('g'),
            baseNodeId = _this3.get('baseNode._id'),
            nr = _this3.get('nodeRadius'),
            bnr = _this3.get('baseNodeRadius'),
            width = _this3.get('width'),
            height = _this3.get('height');

        if (width / root.height < minimumXSpacing) {
          width = minimumXSpacing * root.height;
        }

        var clust = (0, _d3Hierarchy.tree)().size([height, width])(root);

        g.selectAll('.link').remove();
        g.selectAll('.node').remove();

        var link = g.selectAll('.link').data(clust.descendants().slice(1));

        link.exit().remove();
        link.enter().append('path').attr('class', 'link').merge(link).attr('d', function (d) {
          return 'M' + d.y + ',' + d.x + 'C' + (d.parent.y + 100) + ',' + d.x + ' ' + (d.parent.y + 100) + ',' + d.parent.x + ' ' + d.parent.y + ',' + d.parent.x;
        });

        var nodes = g.selectAll('.node').data(clust.descendants());

        nodes.exit().remove();
        var node = nodes.enter().append('g');
        node.merge(nodes).attr('class', function (d) {
          var c = 'node';
          c += d.children ? ' node--internal' : ' node--leaf';

          if (d.data.deprecating) {
            c += ' node--deprecating';
          }

          if (d.data._id === baseNodeId) {
            c += ' node--base';
          }

          return c;
        }).attr('transform', function (d) {
          return 'translate(' + d.y + ',' + d.x + ')';
        });

        // The data DOES get passed down to the circles, and the enter() statement
        // will create a circle child for each data entry
        node.append('circle').attr('r', function (e) {
          return e.data._id === baseNodeId ? bnr : nr;
        }).exit().remove();

        node.append('text').attr('x', '1.5em').attr('dy', function (d) {
          return (d.data._id === baseNodeId ? bnr : nr) / 3;
        }).style('text-anchor', 'start').text(function (d) {
          var n = [],
              _ref = d.data.name || {},
              first = _ref.first,
              last = _ref.last;


          if (first) {
            n.push(first);
          }

          if (last) {
            n.push(last);
          }

          return n.join(' ');
        }).exit().remove();
      });
    })
  }); /* eslint-disable ember/no-on-calls-in-components */


  OrganizationTreeComponent.reopenClass({ positionalParams: ['graphNodes'] });

  exports.default = OrganizationTreeComponent;
});