define('slate-payroll/models/employee', ['exports', 'ember-data', 'slate-payroll/utils/address-formatter', 'ember-data-copyable', 'slate-payroll/config/app-data'], function (exports, _emberData, _addressFormatter, _emberDataCopyable, _appData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;


  function firstCharacter(str) {
    return str && str.charAt(0) ? str.charAt(0).toUpperCase() : '';
  }

  exports.default = Model.extend(_emberDataCopyable.default, {
    archivalId: attr('string'),
    employeeNumber: attr('string'),
    ssn: attr('string'),
    phone: attr('string'),
    phoneMobile: attr('string'),
    phoneExt: attr('string'),
    email: attr('string'),
    overtimeAlerts: attr('boolean'),
    emailBouncing: attr('boolean'),
    emailBouncingReason: attr('string'),
    maritalStatus: attr('string'),
    picture: attr('string'),

    exempt: attr('boolean'),
    partTime: attr('boolean', { defaultValue: false }),
    allowAdvanced: attr('boolean'),
    codeBasedPunch: attr('boolean', { defaultValue: false }),
    codeBasedPunchCodes: _emberData.default.hasMany('company-paycodes', { async: true }),

    limitPunchByIp: attr('boolean'),
    ipAddresses: attr('string'),

    limitPunchByDeviceType: attr('boolean'),
    deviceTypes: attr('array'),

    socCode: attr('string'),
    jobTitle: attr('string'),
    gender: attr('string'),
    notes: attr('string'),
    ongoingPayrollComment: attr('string'),

    emergencyContactName: attr('string'),
    emergencyContactPhone: attr('string'),

    workersCompensation: attr('string'),
    workersCompClass: attr('string'),

    enableOvertimeRules: attr('boolean'),

    payRateHourly: attr('number'),
    payRateSalary: attr('number'),
    payType: attr('string', { defaultValue: 'Hourly' }),
    payRateVacation: attr('number'),

    limitsVacation: attr('number'),

    accrualSick: attr('number'),

    federalTaxAllowances: attr('number', { defaultValue: 0 }),
    federalTaxAdditionalWithholding: attr('number', { defaultValue: 0 }),
    federalTaxFilingStatus: attr('string'),
    federalTaxStep2: attr('boolean'),
    federalTaxStep3: attr('number'),
    federalTaxStep4a: attr('number'),
    federalTaxStep4b: attr('number'),
    federalTaxStep4c: attr('number'),

    stateTaxName: attr('string', { defaultValue: 'MT' }),
    stateTaxAllowances: attr('number', { defaultValue: 0 }),
    stateTaxAdditionalWithholding: attr('number', { defaultValue: 0 }),
    stateTaxReducedWithholding: attr('number'),
    stateTaxFilingStatus: attr('string'),
    stateTaxStep2: attr('boolean', { defaultValue: false }),
    stateTaxUnemploymentInsurance: attr('string', { defaultValue: 'MT' }),
    stateIncomeTaxExempt: Ember.computed('stateTaxName', function () {
      return _appData.statesIncomeTaxExempt.includes(this.stateTaxName);
    }),

    stateTaxName2: attr('string'),
    stateTaxAllowances2: attr('number'),
    stateTaxAdditionalWithholding2: attr('number'),
    stateTaxFilingStatus2: attr('string'),
    stateIncomeTaxExempt2: Ember.computed('stateTaxName2', function () {
      return _appData.statesIncomeTaxExempt.includes(this.stateTaxName2);
    }),

    hsaAccount: attr('string'),
    hsaRouting: attr('string'),
    hsaType: attr('string'),

    externalLinkService: attr('string'),
    externalLinkId: attr('string'),
    externalLinkDocumentLink: attr('string'),
    externalLinkAutomaticSync: attr('boolean'),
    externalLinkLastSync: attr('date'),

    firstName: attr('string'),
    middleInitial: attr('string'),
    lastName: attr('string'),
    suffix: attr('string'),

    addressLine1: attr('string'),
    addressLine2: attr('string'),
    addressCity: attr('string'),
    addressState: attr('string', { defaultValue: 'MT' }),
    addressZipcode: attr('string'),

    company: _emberData.default.belongsTo('company', { async: true }),
    location: _emberData.default.belongsTo('location', {
      async: true,
      inverse: false
    }),
    department: _emberData.default.belongsTo('department', {
      async: true,
      inverse: false
    }),
    bankAccounts: _emberData.default.hasMany('bank-account'),
    supervisor: _emberData.default.belongsTo('employee', {
      async: true,
      inverse: 'supervises'
    }),
    supervises: _emberData.default.hasMany('employee', {
      async: true,
      inverse: 'supervisor'
    }),
    balances: _emberData.default.hasMany('balance', { inverse: null }),
    overtimeRules: _emberData.default.hasMany('overtime-rule'),

    legacyEmployeeId: attr('number'),
    legacyUsername: attr('string'),
    legacyDeptId: attr('number'),
    legacySupervisorId: attr('number'),
    legacyManagerId: attr('number'),
    legacyLocationId: attr('number'),
    legacyBarcodeAvailability: attr('boolean'),
    legacyOngoingComment: attr('string'),
    memo: attr('string'),

    effective: attr('date'), // Field for translating delayed change intents to changeRequests
    hireDate: attr('date'),
    rehireDate: attr('date'),
    terminatedOn: attr('date'),
    dateOfBirth: attr('date'),
    'time_stamp': attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),

    usingW42020: Ember.computed('federalTaxStep3', 'federalTaxStep4a', 'federalTaxStep4b', 'federalTaxStep4c', function () {
      var _this = this;

      return ['federalTaxStep3', 'federalTaxStep4a', 'federalTaxStep4b', 'federalTaxStep4c'].some(function (prop) {
        var val = _this.get(prop);
        return val !== undefined && val !== null;
      });
    }),

    payString: Ember.computed('payRateSalary', 'payRateHourly', 'payType', function () {
      var hourlyRate = this.get('payRateHourly'),
          salaryRate = this.get('payRateSalary'),
          typeString = salaryRate ? 'yr' : 'hr',
          rate = salaryRate || hourlyRate;

      return typeString && rate ? rate + '/' + typeString : undefined;
    }),

    payRate: Ember.computed('payRateSalary', 'payRateHourly', function () {
      return this.get('payRateHourly') || this.get('payRateSalary');
    }),

    payTypeAbbrv: Ember.computed('payRateHourly', 'payRateSalary', function () {
      return this.get('payRateHourly') ? 'hr' : 'yr';
    }),

    briefName: Ember.computed('firstName', 'lastName', function () {
      var fn = this.get('firstName'),
          ln = this.get('lastName');

      return fn && ln ? fn + ' ' + ln.charAt(0) + '.' : fn;
    }),

    fullName: Ember.computed('firstName', 'lastName', 'middleInitial', 'suffix', function () {
      var n = this.getProperties('firstName', 'lastName', 'middleInitial', 'suffix'),
          ret = '';

      if (n.firstName) {
        ret += n.firstName;
      }

      if (n.middleInitial) {
        ret += ' ' + n.middleInitial + '. ';
      }

      if (n.lastName) {
        ret += ' ' + n.lastName;
      }

      if (n.suffix) {
        ret += ' ' + n.suffix;
      }

      return ret.length > 0 ? ret : undefined;
    }),

    initials: Ember.computed('firstName', 'lastName', 'middleInitial', function () {
      var data = this.getProperties('firstName', 'lastName', 'middleInitial'),
          ret = firstCharacter(data.firstName);

      if (data.middleInitial) {
        ret += firstCharacter(data.middleInitial);
      }

      ret += firstCharacter(data.lastName);

      return ret;
    }),

    md5EmailHash: Ember.computed('email', function () {
      var email = this.get('email');
      return email ? md5(email.toLowerCase().trim()) : undefined;
    }),

    gravatarUrl: Ember.computed('md5EmailHash', function () {
      var md5EmailHash = this.get('md5EmailHash');
      return md5EmailHash ? 'https://secure.gravatar.com/avatar/' + md5EmailHash + '?d=identicon&s=200' : undefined;
    }),

    hasAddress: Ember.computed('addressLine1', 'addressCity', 'addressState', function () {
      return this.get('addressLine1') && this.get('addressCity') && this.get('addressState');
    }),

    addressFormatted: _addressFormatter.default.property('addressLine1', 'addressLine2', 'addressCity', 'addressState', 'addressZipcode'),

    randomColor: Ember.computed('firstName', 'lastName', function () {
      var defaultColor = {
        r: 255,
        g: 255,
        b: 255
      };

      var data = this.getProperties('firstName', 'lastName'),
          al = 'abcdefghijklmnopqrstuvwxyz'.split('');

      if (!data.firstName || !data.lastName) {
        return defaultColor;
      }

      var indices = {
        f: al.indexOf(firstCharacter(data.firstName).toLowerCase()) * 10,
        l: al.indexOf(firstCharacter(data.lastName).toLowerCase()) * 10
      };

      indices.o = Math.abs(indices.f - indices.l);

      return {
        r: indices.f > 255 ? 255 : indices.f,
        g: indices.l > 255 ? 255 : indices.l,
        b: indices.o > 255 ? 255 : indices.o
      };
    }),

    randomColorRGB: Ember.computed('randomColor', function () {
      var colors = this.get('randomColor');
      return 'rgb(' + colors.r + ', ' + colors.g + ', ' + colors.b + ')';
    }),

    randomColorText: Ember.computed('randomColor', function () {
      var _ref = this.get('randomColor') || {},
          r = _ref.r,
          g = _ref.g,
          b = _ref.b;

      return (r * 299 + g * 587 + b * 114) / 1000 > 123 ? 'rgb(0, 0, 0)' : 'rgb(255, 255, 255)';
    }),

    phoneFormatted: Ember.computed('phone', function () {
      var phone = this.get('phone');
      return phone ? phone.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') : phone;
    }),

    phoneMobileFormatted: Ember.computed('phoneMobile', function () {
      var phoneMobile = this.get('phoneMobile');
      return phoneMobile ? phoneMobile.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') : phoneMobile;
    }),

    ssnFormatted: Ember.computed('ssn', function () {
      var ssn = this.get('ssn');
      return ssn ? ssn.replace(/\D/g, '').replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3') : ssn;
    }),

    ssnMasked: Ember.computed('ssn', function () {
      var ssn = this.get('ssn');
      return ssn ? ssn.replace(/\D/g, '').replace(/(?:\d{5})(\d{4})/, '***-**-$1') : ssn;
    }),

    apiPictureUrl: Ember.computed('picture', function () {
      var picture = this.get('picture');
      if (picture) {
        return '/api' + picture;
      }
    })
  });
});