define('slate-payroll/controllers/account/user', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      deleteUser: function deleteUser() {
        var _this = this;

        var user = this.get('model.user');

        if (!confirm('You are about to delete user: ' + user.get('username') + '')) {
          return;
        }

        user.destroyRecord().then(function () {
          _this.transitionToRoute('account.users');
        });
      }
    }
  });
});