define('slate-payroll/controllers/account/pay-period/close/incomplete-punches/index', ['exports', 'slate-payroll/mixins/controller-abstractions/add-edit'], function (exports, _addEdit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_addEdit.default, {
    actions: {
      dismissPunchReview: function dismissPunchReview(punch) {
        var _this = this;

        punch.setProperties({
          needsReview: false,
          reviewDate: new Date(),
          reviewedBy: this.get('auth.currentUser.username')
        });

        this.saveModel(punch).then(function () {
          return _this.send('refresh');
        });
      }
    }
  });
});