define('slate-payroll/components/x-navigation', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    headData: Ember.inject.service(),
    auth: Ember.inject.service(),
    tagName: 'nav',
    role: 'navigation',
    classNames: ['navbar', 'navbar-default', 'app-navbar'],
    attributeBindings: ['role'],

    actions: {
      toggleDarkMode: function toggleDarkMode() {
        this.toggleProperty('headData.darkMode');
        this.auth.setPreference('darkMode', this.get('headData.darkMode'));
      },
      logout: function logout() {
        this.sendAction('logout');
      }
    }
  });
});