define('slate-payroll/controllers/new-integration', ['exports', 'slate-payroll/mixins/ux-ajax-status-hooks'], function (exports, _uxAjaxStatusHooks) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_uxAjaxStatusHooks.default, {
    actions: {
      grant: function grant() {
        var _this = this;

        this.ajaxStart();

        Ember.$.post('/api/integrations/' + this.get('model._id') + '/grant').done(function (integration) {
          _this.ajaxSuccess();

          if (integration.redirect) {
            window.location = integration.redirect;
            return;
          }

          _this.transitionToRoute('index');
        }).fail(this.ajaxError.bind(this));
      },
      deny: function deny() {
        var denyRedirect = this.get('model.denyRedirect');

        if (denyRedirect) {
          window.location = denyRedirect;
          return;
        }

        this.transitionToRoute('login');
      }
    }
  });
});