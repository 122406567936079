define('slate-payroll/models/payroll', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = _emberData.default.Model.extend({
    descriptor: attr('string'),
    columnHeaders: attr('array'),
    rows: attr('array'),
    staticHeaders: attr('array'),
    statics: attr('array'),
    totalsByColumns: attr('array'),

    company: belongsTo('company', {
      async: true,
      inverse: null
    }),
    payPeriod: belongsTo('pay-period', {
      async: true,
      inverse: null
    }),

    finalized: attr('boolean'),
    finalizedOn: attr('date'),
    notified: attr('boolean'),
    paidOn: attr('date'),
    correction: attr('boolean'),
    memo: attr('string'),

    created: attr('date', { defaultValue: function defaultValue() {
        return new Date();
      } }),

    inGracePeriod: Ember.computed('paidOn', function () {
      return (0, _moment.default)().isBefore((0, _moment.default)(this.get('paidOn')).endOf('day'));
    })
  });
});