define('slate-payroll/controllers/account/punch/edit', ['exports', 'slate-payroll/config/app-data', 'slate-payroll/utils/is-number', 'slate-payroll/mixins/ux-ajax-status-hooks', 'moment'], function (exports, _appData, _isNumber, _uxAjaxStatusHooks, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Controller.extend(_uxAjaxStatusHooks.default, {
    parentController: Ember.inject.controller('account/punch'),
    showModal: true,
    timeInterval: Ember.computed.reads('auth.currentUser.company.timeMinuteInterval'),
    modalId: 'edit-punch-modal',
    modalTitle: '<h2><i class="fa fa-fw fa-pencil-square"></i> Editing Punch</h2>',
    lunchIsOther: Ember.computed.equal('punchLunch', 'other'),

    autosetPunchAttributes: Ember.observer('model', 'model.timeIn', 'model.timeOut', function () {
      var model = this.get('model');

      if (!model || this.get('didAutoset')) {
        return;
      }

      var data = model.getProperties('timeIn', 'timeOut', 'lunch'),
          timeOutMoment = (0, _moment.default)(data.timeOut);

      var isOtherLunch = data.lunch !== 0 && data.lunch !== 1 && data.lunch !== 0.5 && data.lunch !== undefined && data.lunch !== null,
          isUnit = model.get('directHourEntry') || (0, _isNumber.default)(model.get('addEarnings')) || (0, _isNumber.default)(model.get('deduction')) || (0, _isNumber.default)(model.get('days')) || model.get('companyPaycode.unitActual') !== 'Hours' || !timeOutMoment.isValid() && (0, _isNumber.default)(model.get('hours'));

      this.setProperties({
        didAutoset: true,
        punchLunch: isOtherLunch ? 'other' : !data.lunch ? null : data.lunch * 60,
        otherPunchLunch: isOtherLunch ? data.lunch * 60 : undefined,
        unitEntry: isUnit,
        units: null
      });

      if (isUnit) {
        var paycode = model.get('companyPaycode'),
            mapping = _appData.paycodeUnitMap[paycode.get('unitActual')];

        if (!mapping) {
          return;
        }

        if ((typeof mapping === 'undefined' ? 'undefined' : _typeof(mapping)) === 'object') {
          mapping = mapping[paycode.get('type')];
        }

        this.set('units', model.get(mapping));
      }
    }),

    isMultiDay: Ember.computed('model.{timeIn,timeOut}', function () {
      var _model = this.model,
          timeIn = _model.timeIn,
          timeOut = _model.timeOut;

      return timeIn && timeOut && !(0, _moment.default)(timeOut).isSame(timeIn, 'day');
    }),

    updateLunch: Ember.observer('punchLunch', 'otherPunchLunch', function () {
      var data = this.getProperties('punchLunch', 'otherPunchLunch');
      var lunch = data.punchLunch !== 'other' ? data.punchLunch / 60 : data.otherPunchLunch / 60 || 0;

      this.set('model.lunch', lunch.toFixed(2) / 1);
    }),

    disableDateOut: Ember.computed(function () {
      var _this = this;

      return [function (date) {
        var timeIn = _this.get('model.timeIn');
        return (0, _moment.default)(date).endOf('day').isBefore(timeIn);
      }];
    }),

    transitionToParentRoute: function transitionToParentRoute() {
      this.transitionToRoute('account.punch', this.get('parentController.model.id'));
    },


    actions: {
      discardChanges: function discardChanges() {
        this.transitionToParentRoute();
        this.get('model').rollbackAttributes();
      },
      savePunch: function savePunch() {
        var _this2 = this;

        var punch = this.get('model');

        this.ajaxStart();

        if (!punch.get('employee.id')) {
          return this.ajaxError('No employee selected.');
        }

        if (!punch.get('companyPaycode.id')) {
          return this.ajaxError('No paycode selected.');
        }

        if (this.get('unitEntry')) {
          var paycode = punch.get('companyPaycode'),
              mapping = _appData.paycodeUnitMap[paycode.get('unitActual')],
              units = this.get('units');

          if (!mapping) {
            return this.ajaxError('Unable to find suitable punch unit type.');
          }

          if ((typeof mapping === 'undefined' ? 'undefined' : _typeof(mapping)) === 'object') {
            mapping = mapping[paycode.get('type')];
          }

          punch.setProperties({
            hours: null,
            days: null,
            addEarnings: null,
            deduction: null,
            lunch: null,
            directHourEntry: true
          });

          punch.set(mapping, units);

          var time = (0, _moment.default)(punch.get('timeIn')).hour(12).minute(0).second(0).toDate();

          punch.setProperties({
            timeIn: time,
            timeOut: time
          });
        } else {
          punch.set('directHourEntry', false);
        }

        punch.save().then(function () {
          _this2.ajaxSuccess();
          _this2.transitionToParentRoute();
        }).catch(this.ajaxError.bind(this));
      }
    }
  });
});