define('slate-payroll/routes/account/messaging/index', ['exports', 'slate-payroll/mixins/route-abstractions/resource', 'slate-payroll/mixins/refreshable'], function (exports, _resource, _refreshable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_resource.default, _refreshable.default, {
    modelName: 'message',
    queryParams: {
      page: { refreshModel: true },
      limit: { refreshModel: true },
      sortBy: { refreshModel: true },
      sortAsc: { refreshModel: true },
      box: { refreshModel: true }
    },

    mutateQuery: function mutateQuery(q, params) {
      if (!params.box) {
        return;
      }

      q['metadata.fromAdmin'] = params.box === 'received' ? true : { $ne: true };
    }
  });
});