define('slate-payroll/models/custom-report', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = _emberData.default.Model.extend({
    name: attr('string'),
    description: attr('string'),
    modelKey: attr('string'),
    processType: attr('string'),
    parameters: attr('array', { defaultValue: function defaultValue() {
        return Ember.A();
      } }),

    company: belongsTo('company', {
      inverse: null,
      async: true
    }),

    created: attr('date', { defaultValue: function defaultValue() {
        return new Date();
      } })
  });
});