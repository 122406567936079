define('slate-payroll/serializers/employee', ['exports', 'ember-data', 'slate-payroll/serializers/application', 'slate-payroll/utils/serialize-object', 'slate-payroll/utils/expand-serialized-object'], function (exports, _emberData, _application, _serializeObject, _expandSerializedObject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    isNewSerializerAPI: true,
    attrs: {
      bankAccounts: { embedded: 'always' },
      balances: { embedded: 'always' },
      overtimeRules: { embedded: 'always' }
    },

    normalize: function normalize(modelClass, hash) {
      if (modelClass.modelName !== 'employee') {
        return this._super.apply(this, arguments);
      }

      if (hash.name) {
        hash.firstName = hash.name.first;
        hash.middleInitial = hash.name.middleInitial;
        hash.lastName = hash.name.last;
        hash.suffix = hash.name.suffix;

        delete hash.name;
      }

      if (hash.payRate) {
        hash.payType = hash.payRate.salary ? 'Salary' : 'Hourly';
      }

      (0, _serializeObject.default)(hash, true, 'emergencyContact', 'address', 'payRate', 'limits', 'accrual', 'federalTax', 'stateTax', 'hsa', 'externalLink', 'supervisor', 'location', 'department');
      return this._super.apply(this, arguments);
    },


    serialize: function serialize(snapshot, options) {
      var json = this._super(snapshot, options);

      json.name = {
        first: json.firstName,
        middleInitial: json.middleInitial,
        last: json.lastName,
        suffix: json.suffix
      };

      delete json.payType;
      delete json.firstName;
      delete json.middleInitial;
      delete json.lastName;
      delete json.suffix;

      (0, _expandSerializedObject.default)(json, 'emergencyContact', 'address', 'payRate', 'limits', 'accrual', 'federalTax', 'stateTax', 'hsa', 'externalLink');

      return json;
    }
  });
});