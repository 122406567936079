define('slate-payroll/controllers/account/pay-periods/payroll/table', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    totalHours: Ember.computed('model', function () {
      var rows = this.get('model.rows');
      return rows.reduce(function (total, row) {
        return total + row.totalHours;
      }, 0);
    })
  });
});