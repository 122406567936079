define('slate-payroll/routes/account/data-explorer/explore/query/index', ['exports', 'slate-payroll/mixins/route-abstractions/add'], function (exports, _add) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_add.default, {
    titleToken: 'Query',
    modelName: 'query'
  });
});