define('slate-payroll/models/company-document', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var attr = _emberData.default.attr;

  exports.default = _emberData.default.Model.extend({
    title: attr('string'),
    message: attr('string'),
    name: attr('string'),
    link: attr('string'),
    type: attr('string'),

    company: _emberData.default.belongsTo('Company', {
      async: true,
      inverse: 'companyDocuments'
    }),
    readBy: _emberData.default.hasMany('employee', {
      async: true,
      inverse: null
    }),
    forEmployee: _emberData.default.belongsTo('employee', {
      async: true,
      inverse: null
    }),
    forEmployees: _emberData.default.hasMany('employee', {
      async: true,
      inverse: null
    }),
    signatures: _emberData.default.hasMany('signature', { inverse: null }),

    displayAlert: attr('boolean'),
    requireSignature: attr('boolean'),

    created: attr('date', { defaultValue: function defaultValue() {
        return new Date();
      } })
  });
});