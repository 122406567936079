define('slate-payroll/components/suggest-input', ['exports', 'ember-inflector', 'slate-payroll/utils/unique-by-key'], function (exports, _emberInflector, _uniqueByKey) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var SugggestInputComponent = Ember.Component.extend({
    classNames: ['typeahead-component', 'suggestion-input-component'],
    inputType: 'text',
    inputId: 'typeahead-input',
    name: 'tt-suggestion-input-component',
    identifier: '_id',
    placeholder: null,
    displayKey: '_id',

    computedInputClass: Ember.computed('inputClass', function () {
      return (this.get('inputClass') || '') + ' typeahead';
    }),

    __buildSuggestionEngine: Ember.observer('fixtureSuggestions', 'displayKey', 'selectQuery', 'modelName', function () {
      var _this = this;

      Ember.run.once(function () {
        var local = _this.get('fixtureSuggestions'),
            name = _this.get('name'),
            modelName = _this.get('modelName'),
            querySelect = '_id%20' + (_this.get('selectQuery') || '').replace(/\s/i, '%20'),
            datumKey = _this.get('datumKey') || _this.get('displayKey') || '_id';

        var modelApiUrl = '/api/' + (0, _emberInflector.pluralize)(modelName) + '?select=' + querySelect;
        modelApiUrl += '&qKey=' + datumKey + '&q={{QUERY}}';

        var suggestionEngine = new Bloodhound({
          local: local,
          name: name,
          identify: _this.__identity(),
          datumTokenizer: _this.__datumTokenizer(),
          queryTokenizer: Bloodhound.tokenizers.whitespace,
          remote: {
            url: modelApiUrl,
            wildcard: '{{QUERY}}',
            transform: function transform(res) {
              var model = res[modelName];
              return (0, _uniqueByKey.default)(model, datumKey);
            }
          }
        });

        suggestionEngine.initialize();

        _this.__renderTypeahead(suggestionEngine);
      });
    }),

    didInsertElement: function didInsertElement() {
      this.__buildSuggestionEngine();
    },
    __renderTypeahead: function __renderTypeahead(_engine) {
      var _this2 = this;

      Ember.run.scheduleOnce('afterRender', function () {
        var name = _this2.get('name'),
            displayKey = _this2.get('displayKey'),
            fixtures = !!_this2.get('fixtureSuggestions');

        var $existingTypeaheadComponent = _this2.$().children('.twitter-typeahead').children('input.typeahead');

        if ($existingTypeaheadComponent[0]) {
          $existingTypeaheadComponent.typeahead('destroy');
        }

        _this2.$().children('input.typeahead').typeahead({ minLength: fixtures ? 0 : 1 }, {
          name: name,
          displayKey: displayKey,
          source: fixtures ? _this2.__pinDefault(_engine) : _engine.ttAdapter()
        });
      });
    },
    __identity: function __identity() {
      var identifier = this.get('identifier');

      return function (obj) {
        return (typeof obj === 'undefined' ? 'undefined' : _typeof(obj)) === 'object' ? obj[identifier] : obj;
      };
    },
    __datumTokenizer: function __datumTokenizer() {
      var datumKey = this.get('datumKey') || this.get('displayKey') || 'id';

      return function (datum) {
        return Bloodhound.tokenizers.whitespace(datum[datumKey]);
      };
    },
    __pinDefault: function __pinDefault(dataset) {
      var _this3 = this;

      return function (q, sync, async) {
        if (q === '') {
          sync(dataset.get(_this3.__getFixtureSelection()));
        } else {
          dataset.search(q, sync, async);
        }
      };
    },
    __getFixtureSelection: function __getFixtureSelection() {
      var fixtureLength = this.get('fixtureSuggestions.length');
      var returnArray = [];

      for (var i = 0; i < fixtureLength; i++) {
        returnArray.push(i + 1);
      }

      return returnArray;
    }
  });

  SugggestInputComponent.reopenClass({ positionalParams: ['modelName'] });

  exports.default = SugggestInputComponent;
});