define('slate-payroll/controllers/xsu', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['t', 'u', 'e', 'redirect'],
    t: null,
    u: null,
    e: null,
    redirect: null
  });
});