define('slate-payroll/models/balance', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    balance: (0, _attr.default)('number'),
    description: (0, _attr.default)('string'),
    updater: (0, _attr.default)('string'),
    companyPaycode: (0, _relationships.belongsTo)('company-paycode', {
      async: true,
      inverse: null
    }),
    asOf: (0, _attr.default)('date')
  });
});