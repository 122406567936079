define('slate-payroll/components/x-filter', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'li',
    classNameBindings: ['_enabled:enabled:disabled-overlay'],
    _enabled: Ember.computed.alias('enabled'),

    click: function click() {
      if (!this.get('_enabled')) {
        this.set('_enabled', true);
      }
    },


    actions: {
      disable: function disable() {
        var _this = this;

        Ember.run.next(function () {
          _this.set('_enabled', false);
        });
      }
    }
  });
});