define('slate-payroll/mixins/refreshable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    actions: {
      refresh: function refresh() {
        this.refresh();
      }
    }
  });
});