define('slate-payroll/controllers/login/forgot', ['exports', 'slate-payroll/mixins/ux-ajax-status-hooks'], function (exports, _uxAjaxStatusHooks) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_uxAjaxStatusHooks.default, {
    showModal: true,
    modalId: 'forgot-password-username-modal',

    actions: {
      sendForgotLink: function sendForgotLink() {
        var username = this.get('username'),
            email = this.get('email');

        this.ajaxStart();

        Ember.$.post('/api/user/send-forgot-link', {
          username: username,
          email: email
        }).done(Ember.run.bind(this, function () {
          var _this = this;

          this.ajaxSuccess();
          this.set('awaitingTransition', true);

          Ember.run.later(function () {
            _this.transitionToRoute('login');
            _this.set('awaitingTransition', false);
          }, 2000);
        })).fail(this.ajaxError.bind(this));
      }
    }
  });
});