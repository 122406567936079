define('slate-payroll/routes/account/time-off/index', ['exports', 'moment', 'slate-payroll/mixins/refreshable'], function (exports, _moment, _refreshable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_refreshable.default, {
    queryParams: {
      month: { refreshModel: true },
      year: { refreshModel: true },
      sortAsc: { refreshModel: true },
      sortBy: { refreshModel: true }
    },

    model: function model(params) {
      var _this = this;

      var queryDate = params.month && params.year ? (0, _moment.default)(params.month + ' ' + params.year, 'MMMM YYYY') : (0, _moment.default)(),
          start = (0, _moment.default)(queryDate).startOf('month').toDate(),
          end = (0, _moment.default)(queryDate).endOf('month').toDate();

      var queries = [{
        approved: { $ne: true },
        reviewedOn: { $not: { $type: 9 } },
        sort: {}
      }, {
        $and: [{ approved: true }, { 'days.date': { $gte: start } }, { 'days.date': { $lte: end } }]
      }];

      queries[0].sort[params.sortBy] = params.sortAsc ? -1 : 1;

      return Ember.RSVP.all(queries.map(function (query) {
        return _this.store.query('time-off-request', query);
      })).then(function (res) {
        return res.reduce(function (results, result) {
          return results.concat(result.toArray());
        }, []);
      });
    }
  });
});