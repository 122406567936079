define('slate-payroll/controllers/account/pay-periods/add', ['exports', 'slate-payroll/mixins/ux-ajax-status-hooks', 'slate-payroll/mixins/disable-holidays', 'slate-payroll/config/app-data', 'moment'], function (exports, _uxAjaxStatusHooks, _disableHolidays, _appData, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var calcRange = {
    weekly: function weekly(start, to) {
      var diff = to.diff(start, 'weeks'),
          ret = [];

      for (var i = 0; i < diff; i++) {
        var from = (0, _moment.default)(start).add(i, 'weeks').toDate();

        ret.push({
          from: from,
          to: (0, _moment.default)(from).add(1, 'week').subtract(1, 'day').endOf('day').toDate()
        });
      }

      return ret;
    },
    biweekly: function biweekly(start, to) {
      var diff = to.diff(start, 'weeks'),
          ret = [];

      diff = diff % 2 === 1 ? diff = diff / 2 - 1 : diff / 2;

      for (var i = 0; i < diff; i++) {
        var from = (0, _moment.default)(start).add(i > 0 ? i * 2 : i, 'weeks').toDate();

        ret.push({
          from: from,
          to: (0, _moment.default)(from).add(2, 'weeks').subtract(1, 'day').endOf('day').toDate()
        });
      }

      return ret;
    },
    semimonthly: function semimonthly(start, to) {
      var diff = to.diff(start, 'months'),
          ret = [];

      var midDate = this.get('semiMonthlyMidDate') || 1,
          endDate = this.get('semiMonthlyEndOnDate') ? this.get('semiMonthlyEndDate') : false;

      for (var i = 0; i < diff; i++) {
        var from = (0, _moment.default)(start).add(i, 'months').toDate(),
            midMoment = (0, _moment.default)(from).date(midDate),
            end = (0, _moment.default)();

        if (midMoment.isBefore(from)) {
          midMoment = (0, _moment.default)(from).add(1, 'month').date(midDate);
        }

        if (!endDate) {
          end = (0, _moment.default)(midMoment).endOf('month');
        } else if (!isNaN(endDate)) {
          end = (0, _moment.default)(midMoment).date(endDate);
          end = end.isBefore(midMoment) ? (0, _moment.default)(midMoment).add(1, 'month').date(endDate) : end;
        }

        ret.addObjects([{
          from: from,
          to: (0, _moment.default)(midMoment).subtract(1, 'day').endOf('day').toDate()
        }, {
          from: midMoment.toDate(),
          to: end.endOf('day').toDate()
        }]);
      }

      return ret;
    },
    monthly: function monthly(start, to) {
      var diff = to.diff(start, 'months'),
          ret = [];

      for (var i = 0; i < diff; i++) {
        var from = (0, _moment.default)(start).add(i, 'months').toDate();

        ret.push({
          from: from,
          to: (0, _moment.default)(from).add(1, 'month').subtract(1, 'day').endOf('day').toDate()
        });
      }

      return ret;
    }
  };

  exports.default = Ember.Controller.extend(_uxAjaxStatusHooks.default, _disableHolidays.default, {
    init: function init() {
      this._super.apply(this, arguments);
      this.semiMonthlyEndSettings = [{
        l: 'End of Month',
        v: false
      }, {
        l: 'Date',
        v: true
      }];
    },

    allowSingleSubmit: Ember.computed.and('start', 'end', 'paidOn', 'notSaving'),
    allowAutoSubmit: Ember.computed.and('payPeriodAutoStart', 'payPeriodAutoTo'),
    notSaving: Ember.computed.not('working'),
    payAfter: 5,

    payAfterSettings: Ember.computed(function () {
      var ret = [];

      for (var i = 0; i < 10; i++) {
        ret.push({
          l: i < 1 ? '1 day' : i + 1 + ' days',
          v: i + 1
        });
      }

      return ret;
    }),

    semiMonthlyMidSettings: Ember.computed(function () {
      var ret = [];

      for (var i = 0; i < 30; i++) {
        ret.push({
          l: (0, _moment.default)(i + 1, 'D').format('Do') + ' of every month',
          v: i + 1
        });
      }

      return ret;
    }),

    actions: {
      saveSingle: function saveSingle() {
        var _this = this;

        this.ajaxStart();

        var data = this.getProperties('start', 'end', 'paidOn', 'acceptPunches');
        data.end = (0, _moment.default)(data.end).endOf('day').toDate();
        var payPeriod = this.store.createRecord('pay-period', data);

        payPeriod.save().then(function () {
          _this.ajaxSuccess('Successfully saved pay period.');
          _this.setProperties({
            start: null,
            end: null,
            paidOn: null
          });
        }).catch(this.ajaxError.bind(this));
      },
      autoGenerate: function autoGenerate() {
        var _this2 = this;

        var start = (0, _moment.default)(this.get('payPeriodAutoStart')),
            to = (0, _moment.default)(this.get('payPeriodAutoTo')),
            payAfter = this.get('payAfter'),
            freq = this.get('auth.currentUser.company.payPeriod');

        this.set('autoGenerateError', null);

        var ranges = calcRange[freq.replace(/-/g, '').toLowerCase()].call(this, start, to);

        var onWeekend = function onWeekend(m) {
          var day = m.day();

          return day === 0 || day === 6;
        };

        var onHoliday = function onHoliday(m) {
          var onHolidays = _appData.holidays.filter(function (holiday) {
            return (0, _moment.default)(holiday).isSame(m, 'day');
          });

          return onHolidays.length > 0 ? onHolidays : false;
        };

        var needsRev = function needsRev(m) {
          return onWeekend(m) || onHoliday(m);
        };

        var payPeriods = ranges.map(function (range) {
          var payOn = (0, _moment.default)(range.to).add(payAfter, 'days');

          var setRet = function setRet() {
            range.paidOn = payOn.toDate();
            return range;
          };

          if (!needsRev(payOn)) {
            return setRet();
          }

          while (payOn.diff(range.to, 'days') > 1 && needsRev(payOn)) {
            payOn.subtract(1, 'day');
          }

          if (!needsRev(payOn)) {
            return setRet();
          }

          while (payOn.diff(range.to, 'days') < 11 && needsRev(payOn)) {
            payOn.add(1, 'day');
          }

          if (needsRev(payOn)) {
            var message = 'Unable to find suitable pay date for ' + (0, _moment.default)(range.from).format('M/D/YY') + '-' + (0, _moment.default)(range.to).format('M/D/YY');
            this.set('autoGenerateError', message);
            throw new Error(message);
          }

          return setRet();
        }).map(function (data) {
          return _this2.store.createRecord('payPeriod', {
            start: data.from,
            end: data.to,
            paidOn: data.paidOn
          });
        });

        if (payPeriods.length < 1) {
          return this.set('autoGenerateError', 'No pay periods could be generated for that range.');
        }

        this.set('generatedPayPeriods', payPeriods);
        this.transitionToRoute('account.pay-periods.review-auto');
      }
    }
  });
});