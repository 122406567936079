define('slate-payroll/routes/account/pay-period/close/add-punches', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return Ember.RSVP.hash({
        employees: this.store.query('employee', {
          $or: [{ terminatedOn: { $not: { $type: 9 } } }, { terminatedOn: { $gt: this.modelFor('account.pay-period').get('start') } }],
          sort: { 'name.last': 1 }
        }),

        paycodes: this.store.query('company-paycode', { sort: { description: 1 } })
      });
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        model: model.employees,
        paycodes: model.paycodes
      });
    },
    willTransition: function willTransition() {
      this.get('controller').set('selectedEmployees', Ember.A());
    }
  });
});