define('slate-payroll/initializers/inject-auth', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var initialize = exports.initialize = function initialize(application) {
    application.inject('controller', 'auth', 'service:auth');
    application.inject('route', 'auth', 'service:auth');
    application.inject('component', 'auth', 'service:auth');
  };

  exports.default = {
    name: 'inject-auth',
    after: 'ember-data',
    initialize: initialize
  };
});