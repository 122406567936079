define('slate-payroll/routes/account/pay-periods/payroll/paystubs', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    queryParams: {
      filterEmployees: { refreshModel: true },
      page: { refreshModel: true },
      sortAsc: { refreshModel: true },
      sortBy: { refreshModel: true },
      limit: { refreshModel: true }
    },

    model: function model(params) {
      var page = params.page ? params.page - 1 : 0;

      var query = {
        page: page,
        limit: params.limit,
        payroll: this.modelFor('account.pay-periods.payroll').model.get('id'),
        sort: {}
      };

      query.sort[params.sortBy] = params.sortAsc ? -1 : 1;

      if (params.filterEmployees && params.filterEmployees.length > 0) {
        query.employee = { $in: params.filterEmployees.toArray() };
      }

      return Ember.RSVP.hash({
        paystubs: this.store.query('paystub', query),
        employees: this.store.query('employee', {
          $or: [{ terminatedOn: { $not: { $type: 9 } } }, { terminatedOn: { $gt: this.modelFor('account.pay-periods.payroll').model.get('start') } }],
          sort: { 'name.last': 1 }
        }),
        params: params
      });
    },
    setupController: function setupController(controller, model) {
      var qp = model.params || {};

      controller.setProperties({
        model: model.paystubs,
        employees: model.employees,
        filterByEmployees: Ember.A(Ember.get(qp, 'filterEmployees'))
      });
    },


    actions: {
      refresh: function refresh() {
        this.refresh();
      }
    }
  });
});