define('slate-payroll/routes/account/data-explorer/explore/reports/view/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var query = this.modelFor('account.data-explorer.explore.reports.view');

      return Ember.RSVP.hash({
        query: query,
        queryResults: this.store.query('query-result', {
          query: query.get('id'),
          sort: { created: -1 }
        })
      });
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        model: model.queryResults,
        query: model.query
      });
    }
  });
});