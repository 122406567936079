define('slate-payroll/controllers/account/pay-periods/index', ['exports', 'slate-payroll/mixins/resource-pagination'], function (exports, _resourcePagination) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Controller.extend(_resourcePagination.default, {
    queryParams: ['page', 'sortAsc', 'showFilters', 'filterByReport', 'filterByClosed', 'filterByAccepting', 'report', 'closed', 'accepting', 'from', 'to'],
    sortAsc: true,
    page: 1,
    limit: 20,
    showFilters: false,
    filterByReport: false,
    filterByClosed: false,
    filterByAccepting: false,
    report: false,
    closed: false,
    accepting: false,
    viewAll: true,
    from: null,
    to: null,

    dateRange: Ember.computed('from', 'to', function () {
      return [this.get('from'), this.get('to')];
    }),

    actions: {
      toggleProperty: function toggleProperty(prop) {
        this.toggleProperty(prop);
      },

      toggleViewAll: function toggleViewAll() {
        this.toggleProperty('viewAll');
        this.auth.setPreference('payPeriodViewAll', this.get('viewAll'));
        this.send('refresh');
      },
      setDateRange: function setDateRange(dateRange) {
        var _dateRange = _slicedToArray(dateRange, 2),
            from = _dateRange[0],
            to = _dateRange[1];

        this.setProperties({
          from: from,
          to: to
        });
      }
    }
  });
});