define('slate-payroll/routes/account/departments/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    queryParams: {
      page: { refreshModel: true },
      sortAsc: { refreshModel: true }
    },

    model: function model(params) {
      return this.store.query('department', {
        page: params.page - 1 || 0,
        limit: this.get('controller.limit') || 20,
        sort: { name: params.sortAsc ? -1 : 1 }
      });
    }
  });
});