define('slate-payroll/instance-initializers/mixpanel', ['exports', 'ember-cli-mixpanel-service/configuration'], function (exports, _configuration) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(instance) {
    if (_configuration.default.enabled) {
      var router = void 0;
      if (typeof instance.lookup === 'function') {
        router = instance.lookup('router:main');
      } else {
        router = instance.container.lookup('router:main');
      }

      if (_configuration.default.autoPageviewTracking == undefined || _configuration.default.autoPageviewTracking) {
        router.on('didTransition', function () {
          var attributeOverrides = _configuration.default.attributeOverrides;
          var mixpanelService = void 0;
          if (typeof instance.lookup === 'function') {
            mixpanelService = instance.lookup('service:mixpanel');
          } else {
            mixpanelService = instance.container.lookup('service:mixpanel');
          }
          var pageViewAttribute = _configuration.default.pageViewAttribute;
          mixpanelService.trackPageView(this.get(pageViewAttribute), attributeOverrides);
        });
      }
    }
  }

  exports.default = {
    name: 'mixpanel',
    initialize: initialize
  };
});