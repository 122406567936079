define('slate-payroll/routes/account/index/ale-report', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    queryParams: {
      start: { refreshModel: true },
      end: { refreshModel: true }
    },

    model: function model(params) {
      return Ember.$.getJSON('/api/report/aca-ale-check', {
        start: params.start,
        end: params.end
      }).then(Ember.run.bind(this, function (response) {
        var chart = Ember.A([['x'], ['Total Hours'], ['Total Employee Values'], ['FTE'], ['FT']]);

        var reduceValuesForRange = function reduceValuesForRange(r) {
          return response.data.reduce(function (d, employee) {
            var pointInValue = Ember.A(employee.values).findBy('month', r.month);

            if (pointInValue) {
              d[0] += pointInValue.totalHours || 0;
              d[1] += pointInValue.employeeValue || 0;
              d[pointInValue.fte ? 2 : 3] += pointInValue.employeeValue || 0;
            }

            return d;
          }, [0, 0, 0, 0]);
        };

        response.range.map(function (range, rangeIndex) {
          var vals = reduceValuesForRange(range, rangeIndex);
          chart.map(function (a, i) {
            return chart[i].push(i === 0 ? (0, _moment.default)(range.start).toDate() : parseFloat((vals[i - 1] || 0).toFixed(2)));
          });
        });

        var sum = function sum(s, item) {
          return s + item || 0;
        },
            av = function av(a) {
          return parseFloat((a.slice(1).reduce(sum, 0) / (chart[0].length - 1) || 0).toFixed(2));
        };

        var averages = {
          hours: av(chart[1]),
          employees: av(chart[2]),
          fte: av(chart[3]),
          ft: av(chart[4])
        };

        return {
          data: response,
          chart: chart,
          averages: averages
        };
      }));
    }
  });
});