define('slate-payroll/components/paystubs/paystub-grid-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    link: 'account.pay-period.paystub',
    paidOn: Ember.computed.or('paystub.{payPeriod.paidOn,payrollDate}'),

    actions: {
      print: function print() {
        var printAction = this.get('print');

        if (printAction && typeof printAction === 'function') {
          printAction(this.get('paystub.id'));
        }
      },
      delete: function _delete() {
        this.get('delete')(this.get('paystub'));
      },
      download: function download() {
        this.get('download')(this.get('paystub'));
      }
    }
  });
});