define('slate-payroll/controllers/account/employee/squares/new/stamp', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    finishController: Ember.inject.controller('account.employee.squares.new.finish'),

    actions: {
      squareError: function squareError(err) {
        this.set('readError', err && err.responseText ? err.responseText : err || 'That stamp is either taken or invalid.');
      },
      squareSuccess: function squareSuccess(response) {
        this.get('finishController').set('pendingSquareId', response.squareId);
        this.set('readError', null);
        this.transitionToRoute('account.employee.squares.new.finish');
      }
    }
  });
});