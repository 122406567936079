define('slate-payroll/router', ['exports', 'slate-payroll/config/environment', 'ember-router-scroll'], function (exports, _environment, _emberRouterScroll) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend(_emberRouterScroll.default, {
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  exports.default = Router.map(function () {
    this.route('login', function () {
      this.route('forgot');
      this.route('reset-password', { path: '/reset-password/:reset_token' });
    });

    this.route('new-integration', { path: '/new-integration/:id' });

    this.route('setup-account', { path: '/setup-account' }, function () {
      this.route('error');
      this.route('ds', { path: '/:userid' });
    });

    this.route('account', function () {
      this.route('index', { path: '/' }, function () {
        this.route('punch-card');
        this.route('clock-map');
        this.route('ale-report');
        this.route('org-chart');
        this.route('custom-reports', function () {
          this.route('run', { path: '/:report_id/run' });
        });
        this.route('state-w4-report');
      });

      this.route('settings');
      this.route('employees', function () {
        this.route('index', { path: '/' });
        this.route('add');
        this.route('supervisory-settings');
        this.route('census', function () {
          this.route('review', { path: '/:uploadId/review' });
          this.route('results');
        });
        this.route('balances', function () {
          this.route('upload');
        });
      });

      this.route('employee', { path: '/employee/:employee_id' }, function () {
        this.route('index', { path: '/' });
        this.route('edit');
        this.route('changes');
        this.route('balances');
        this.route('change-report');
        this.route('automated-paycodes');
        this.route('squares', function () {
          this.route('new', function () {
            this.route('stamp');
            this.route('finish');
          });
        });
        this.route('paystubs');
        this.route('paystub', { path: '/paystub/:paystub_id' });
        this.route('tax-forms');
      });

      this.route('pay-periods', function () {
        this.route('add');
        this.route('review-auto');
        this.route('payrolls', function () {
          this.route('new');
        });
        this.route('payroll', { path: '/payroll/:payroll_id' }, function () {
          this.route('table');
          this.route('paystubs');
          this.route('paystub', { path: '/paystub/:paystub_id' });
          this.route('correct');
        });
      });

      this.route('pay-period', { path: '/pay-period/:id' }, function () {
        this.route('index', { path: '/' });
        this.route('punches', function () {
          this.route('add');
          this.route('edit');
        });
        this.route('settings');

        this.route('close', function () {
          this.route('audit', function () {
            this.route('employee', { path: '/employee/:employee_id' });
            this.route('punch', { path: '/punch/:punchid' });
            this.route('changes');
            this.route('time-off');
          });

          this.route('incomplete-punches', function () {
            this.route('complete', { path: '/complete/:punchid' });
          });

          this.route('add-punches');
          this.route('review');
          this.route('finished');
        });
        this.route('paystubs');
        this.route('paystub', { path: '/paystub/:paystub_id' });
        this.route('upload', function () {
          this.route('review', { path: '/:uploadId/review' });
          this.route('results');
        });
      });

      this.route('punch', { path: '/punch/:id' }, function () {
        this.route('edit');
        this.route('location');
      });

      this.route('company-paycode', { path: '/paycode/:company_paycode_id' });

      this.route('users', function () {
        this.route('add');
      });

      this.route('user', { path: '/user/:id' }, function () {
        this.route('edit');
      });

      this.route('company-documents', function () {
        this.route('add');
        this.route('read-by', { path: '/:id/read-by' });
      });

      this.route('automated-paycodes', function () {
        this.route('add');
        this.route('edit', { path: '/:paycodeid/edit' });
        this.route('view', { path: '/:id/view' });
      });

      this.route('data-explorer', { path: '/data' }, function () {
        this.route('explore', function () {
          this.route('query', { path: '/query' }, function () {
            this.route('edit', { path: '/:queryid/edit' });
          });
          this.route('reports', function () {
            this.route('view', { path: '/:id' }, function () {
              this.route('results');
              this.route('result', { path: '/result/:resultid' });
            });
            this.route('run', { path: '/:id/run' });
            this.route('parameters', { path: '/:id/run/parameters' });
          });
        });
      });

      this.route('help', function () {
        this.route('category', { path: '/category/:categoryid' }, function () {
          this.route('article', { path: '/article/:articleid' });
        });
      });

      this.route('reminders', function () {
        this.route('edit', { path: '/:id/edit' });
        this.route('add');
      });

      this.route('departments', function () {
        this.route('add');
      });

      this.route('department', { path: '/department/:departmentid' });
      this.route('time-off', function () {
        this.route('approve', { path: '/:requestid/approve' });
        this.route('balances');
        this.route('historical');
        this.route('settings');
      });

      this.route('broadcasts', function () {
        this.route('index', { path: '/' }, function () {
          this.route('new');
          this.route('edit', { path: '/:broadcast_id/edit' });
        });
      });
      this.route('messaging', function () {
        this.route('compose');
      });
    });

    this.route('error');
    this.route('not-found');
    this.route('unauthorized');
    this.route('catchall', { path: '/*wildcard' });
    this.route('xsu');
    this.route('2fa', function () {
      this.route('verify');
      this.route('remind');
      this.route('setup');
    });
    this.route('app-privacy');
  });
});