define('slate-payroll/services/clock', ['exports', 'moment', 'slate-payroll/config/environment'], function (exports, _moment, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    refreshResolution: 1000,
    t: Ember.computed.alias('time'),

    _tick: Ember.on('init', function () {
      var m = (0, _moment.default)();

      this.setProperties({
        year: m.get('year'),
        second: m.get('second'),
        minute: m.get('minute'),
        hour: m.get('hour'),
        time: m.toDate()
      });

      if (_environment.default.environment !== 'test') {
        Ember.run.later(this, this._tick, this.get('refreshResolution'));
      }
    })
  });
});