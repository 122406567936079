define('slate-payroll/routes/account/pay-period/close/audit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var payPeriod = this.modelFor('account.pay-period');

      return Ember.RSVP.hash({
        timeOffCount: Ember.$.getJSON('/api/timeOffRequests', {
          days: {
            $elemMatch: {
              date: {
                $gte: payPeriod.get('start'),
                $lte: payPeriod.get('end')
              }
            }
          },
          _count: true
        }).then(function (res) {
          return res.count;
        }),
        approvalsCount: Ember.$.getJSON('/api/approvals', {
          payPeriod: payPeriod.get('id'),
          _count: true
        }).then(function (res) {
          return res.count;
        })
      });
    }
  });
});