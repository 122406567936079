define('slate-payroll/routes/new-integration', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return Ember.$.getJSON('/api/integrations/' + params.id).then(function (res) {
        return res.serviceIntegration;
      });
    }
  });
});