define('slate-payroll/components/list-item/broadcast-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var BroadcastItemComponent = Ember.Component.extend({
    tagName: 'li',
    classNames: ['list-group-item', 'clearfix']
  });

  BroadcastItemComponent.reopenClass({ positionalParams: ['broadcast'] });

  exports.default = BroadcastItemComponent;
});