define('slate-payroll/controllers/setup-account/ds', ['exports', 'slate-payroll/mixins/ux-ajax-status-hooks'], function (exports, _uxAjaxStatusHooks) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_uxAjaxStatusHooks.default, {
    allowSubmit: Ember.computed('password', 'passwordConfirm', 'working', function () {
      var password = this.get('password');
      return this.get('working') ? false : password && password === this.get('passwordConfirm');
    }),

    actions: {
      activateAccount: function activateAccount() {
        var _this = this;

        var activationSignature = this.get('model.activationSignature'),
            id = this.get('model.id');

        this.ajaxStart();

        Ember.$.post('/api/user/activate/' + id, {
          activationSignature: activationSignature,
          password: this.get('password')
        }, function () {
          _this.setProperties({
            password: null,
            passwordConfirm: null,
            success: true
          });

          _this.ajaxSuccess();

          Ember.run.later(function () {
            _this.transitionToRoute('login');
          }, 1000);
        }).fail(this.ajaxError.bind(this));
      }
    }
  });
});