define('slate-payroll/controllers/error', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    clientError: Ember.computed('fromError', function () {
      var error = this.get('fromError');
      return error && error.status === 400;
    })
  });
});