define('slate-payroll/mixins/category-styling', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    colorModifier: 8,

    _color: Ember.computed('category.name', 'colorModifier', function () {
      var al = 'abcdefghijklmnopqrstuvwxyz'.split(''),
          modifier = this.get('colorModifier');

      var defaultColor = {
        r: 255,
        g: 255,
        b: 255
      };
      var name = this.get('category.name');

      if (!name) {
        return defaultColor;
      }

      var _max = function _max(a) {
        return a > 255 ? 255 : Math.round(Math.abs(a));
      };

      var firstLetter = name.charAt(0).toLowerCase(),
          secondLetter = name.charAt(1).toLowerCase(),
          thirdLetter = name.charAt(2).toLowerCase(),
          r = _max(al.indexOf(firstLetter) * modifier / 2.5),
          g = _max(al.indexOf(secondLetter) * modifier * 1.2),
          b = _max(al.indexOf(thirdLetter) * modifier);

      return {
        r: r,
        g: g,
        b: b
      };
    }),

    _thumbnailStyle: Ember.computed('_color', '_overlayUrl', function () {
      var colors = this.get('_color'),
          style = 'background: ';

      var lg = function lg(color, deg) {
        var l = 'linear-gradient(';

        if (deg) {
          l += deg + 'deg, ';
        }

        return l + 'rgb(' + color.r + ', ' + color.g + ', ' + color.b + '), transparent)';
      };

      var lighten = function lighten(color, percent) {
        var lightenBy = Math.round(255 * percent / 100);
        var _color = {};

        for (var colorKey in color) {
          if (color.hasOwnProperty(colorKey)) {
            _color[colorKey] = Math.abs(color[colorKey] - lightenBy);
          }
        }

        return _color;
      };

      return style + lg(lighten(colors, 25)) + ', ' + lg(lighten(colors, 10), 90) + ', ' + lg(colors, -90) + ';';
    })
  });
});