define('slate-payroll/controllers/account/employee/automated-paycodes', ['exports', 'slate-payroll/mixins/ux-ajax-status-hooks', 'moment'], function (exports, _uxAjaxStatusHooks, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_uxAjaxStatusHooks.default, {
    init: function init() {
      this._super.apply(this, arguments);
      this.paycodeTypeSortOrder = ['E', 'D', 'L'];
    },


    disableSubmit: Ember.computed.not('pendingAutomatedPaycode.companyPaycode.content'),

    queryParams: ['previewDate'],
    previewDate: null,
    employeeController: Ember.inject.controller('account/employee'),
    employee: Ember.computed.reads('employeeController.model'),

    selectedPreviewObject: Ember.computed('availableEffectiveDates.@each.value', 'previewDate', function () {
      if (this.previewDate === null) {
        return this.get('availableEffectiveDates')[0];
      }
      return this.get('availableEffectiveDates').findBy('label', (0, _moment.default)(this.get('previewDate')).format('M/D/YY'));
    }),

    sortedModel: Ember.computed('model.@each.companyPaycode.{type,description}', function () {
      var model = this.get('model');

      if (!model) {
        return model;
      }

      var paycodeTypeSortOrder = this.get('paycodeTypeSortOrder'),
          sortIndexForType = function sortIndexForType(a) {
        return paycodeTypeSortOrder.indexOf(Ember.get(a, 'companyPaycode.type'));
      };

      return model.toArray().sort(function (a, b) {
        var sa = sortIndexForType(a),
            ba = sortIndexForType(b);

        if (sa > ba) {
          return 1;
        } else if (sa < ba) {
          return -1;
        } else {
          var aPaycode = Ember.get(a, 'companyPaycode.description') || '',
              bPaycode = Ember.get(b, 'companyPaycode.description') || '',
              aLocalePosition = aPaycode.localeCompare(bPaycode);

          return aLocalePosition === 0 ? 0 : aLocalePosition > 0 ? 1 : -1;
        }
      });
    }),

    actions: {
      addPaycode: function addPaycode() {
        var pending = this.store.createRecord('automated-paycode', {
          company: this.auth.get('currentUser.company'),
          static: true,
          changed: true
        });

        this.set('pendingAutomatedPaycode', pending);
      },
      changeDate: function changeDate(date) {
        this.set('previewDate', date ? date : false);
      },
      savePendingPaycode: function savePendingPaycode() {
        var _this = this;

        var pendingPaycode = this.get('pendingAutomatedPaycode');

        pendingPaycode.employees.addObject(this.get('employee'));

        if (!pendingPaycode.effectiveOn) {
          pendingPaycode.set('effectiveOn', this.get('availableEffectiveDates')[0].value);
        }

        this.ajaxStart();

        pendingPaycode.save().then(function () {
          _this.set('pendingAutomatedPaycode', null);
          _this.ajaxSuccess();
          _this.send('refreshModel');
        }).catch(this.ajaxError.bind(this));
      }
    }
  });
});