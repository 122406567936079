define('slate-payroll/routes/account/pay-period/close/audit/punch', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return this.store.find('punch', params.punchid);
    }
  });
});