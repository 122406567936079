define('slate-payroll/models/broadcast', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    title: (0, _attr.default)('string'),
    message: (0, _attr.default)('string'),

    company: (0, _relationships.belongsTo)('company', {
      inverse: null,
      async: true
    }),
    readBy: (0, _relationships.hasMany)('employee'),
    creator: (0, _relationships.belongsTo)('user'),

    expires: (0, _attr.default)('date'),
    created: (0, _attr.default)('date', { defaultValue: function defaultValue() {
        return new Date();
      } }),

    departments: (0, _relationships.hasMany)('department'),
    locations: (0, _relationships.hasMany)('location'),

    isExpired: Ember.computed('expires', function () {
      return this.expires && this.expires < new Date();
    })
  });
});