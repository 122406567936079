define('slate-payroll/routes/account/automated-paycodes', ['exports', 'slate-payroll/mixins/refreshable', 'slate-payroll/mixins/array-qp-tweaks'], function (exports, _refreshable, _arrayQpTweaks) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_refreshable.default, _arrayQpTweaks.default, {
    titleToken: 'Automated Paycodes',

    queryParams: {
      page: { refreshModel: true },
      sortAsc: { refreshModel: true },
      showFilters: { refreshModel: true },
      filterByStatic: { refreshModel: true },
      filterByEmployee: { refreshModel: true },
      filterByMulti: { refreshModel: true },
      static: { refreshModel: true },
      multi: { refreshModel: true }
    },

    model: function model(params) {
      var filters = [{
        name: 'static',
        merge: { static: params.static }
      }, {
        name: 'multi',
        merge: { 'employees.1': { $exists: params.multi } }
      }, {
        name: 'Employee',
        merge: { employees: { $in: params.filterByEmployee } }
      }];

      var page = params.page - 1;
      var query = {
        page: page || 0,
        limit: this.get('controller.limit') || 15,
        sort: {
          isLapsed: 1,
          cutOffOn: 1,
          paycodeDescription: params.sortAsc ? 1 : -1
        }
      };

      if (params.showFilters) {
        filters.forEach(function (filter) {
          if (params['filterBy' + Ember.String.capitalize(filter.name)]) {
            Ember.$.extend(query, filter.merge);
          }
        });
      }

      return Ember.RSVP.hash({
        automatedPaycodes: this.store.query('automated-paycode', query),
        companyPaycodes: this.store.findAll('company-paycode')
      });
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        model: model.automatedPaycodes,
        companyPaycodes: model.companyPaycodes
      });
    }
  });
});