define('slate-payroll/routes/account/employee/change-report', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    queryParams: {
      field: { refreshModel: true },
      asc: { refreshModel: true }
    },

    model: function model(params) {
      var employee = this.modelFor('account.employee').employee.get('id'),
          sortDirection = params.asc ? -1 : 1;

      var changeQuery = {};

      if (params.field) {
        changeQuery.diff = { $elemMatch: { path: params.field.split('.') } };
      }

      changeQuery.sort = {
        effective: sortDirection,
        created: sortDirection
      };

      return Ember.RSVP.hash({
        changes: this.store.query('change-request', Ember.$.extend({ employee: employee }, changeQuery)).then(function (requests) {
          return requests.reduce(function (changes, request) {
            if (params.field) {
              request.get('processedDiffs').forEach(function (diff) {
                if (diff.path.join('.') === params.field) {
                  diff.request = request;
                  changes.push(diff);
                }
              });
            } else {
              changes.pushObjects(request.get('diff').map(function (diff) {
                diff.request = request;
                return diff;
              }));
            }

            return changes;
          }, Ember.A());
        }),
        paths: Ember.$.getJSON('/api/change-requests', {
          employee: employee,
          select: 'diff -_id'
        }).then(function (result) {
          return result.changeRequest ? result.changeRequest.reduce(function (keys, change) {
            keys.addObjects(change.diff.map(function (diff) {
              return diff.path.join('.');
            }));

            return keys;
          }, Ember.A()).sort() : [];
        })
      });
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        model: model.changes,
        paths: model.paths
      });
    }
  });
});