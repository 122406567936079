define('slate-payroll/routes/account/punch/edit', ['exports', 'slate-payroll/mixins/routable-modal'], function (exports, _routableModal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_routableModal.default, {
    beforeModel: function beforeModel(transition) {
      var _this = this;

      transition.then(function () {
        var infos = transition.router.oldState ? transition.router.oldState.handlerInfos : false,
            lastInfo = infos ? infos[infos.length - 1] : false,
            prevRoute = lastInfo ? lastInfo.name : undefined;

        _this.set('previousRoutePath', prevRoute);
      });
    },
    model: function model() {
      return Ember.RSVP.hash({
        paycodes: this.store.findAll('company-paycode'),
        punch: this.modelFor('account/punch')
      });
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        model: model.punch,
        paycodes: model.paycodes
      });
    },


    actions: {
      willTransition: function willTransition(transition) {
        var _this2 = this;

        var controller = this.controller;

        if (!controller.get('showModal')) {
          controller.set('didAutoset', undefined);
          return;
        }

        transition.abort();
        controller.set('showModal', false);

        this.awaitModalClose().then(function () {
          var previousRoutePath = _this2.get('previousRoutePath');

          if (previousRoutePath) {
            var args = [previousRoutePath];

            if (/account\.pay-period(\.|$)/.test(previousRoutePath)) {
              args.push(_this2.modelFor('account.pay-period'));
            }

            if (previousRoutePath.indexOf('payroll') > -1) {
              args.push(_this2.modelFor('account.pay-periods.payroll.index').model.id);
            }

            if (previousRoutePath.indexOf('audit.employee') > -1) {
              args.push(_this2.modelFor('account.pay-period.close.audit.employee').employee.get('id'));
            }

            _this2.transitionTo.apply(_this2, args);
          } else {
            transition.retry();
          }
        });
      }
    }
  });
});