define('slate-payroll/components/list-item/reminder', ['exports', 'slate-payroll/mixins/controller-abstractions/add-edit', 'slate-payroll/mixins/controller-abstractions/delete'], function (exports, _addEdit, _delete) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_addEdit.default, _delete.default, {
    classNames: ['list-group-item', 'clearfix'],
    classNameBindings: ['reminder.virtualMissed:list-group-item-danger', 'reminder.isWarning:list-group-item-warning'],

    employee: Ember.computed('employees', function () {
      return this.employees.findBy('id', this.get('reminder.employee.id'));
    }),

    actions: {
      markCompleted: function markCompleted() {
        var reminder = this.get('reminder');
        reminder.set('completed', true);
        this.send('save', reminder);
      }
    }
  });
});