define('slate-payroll/components/time-ago', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var TimeAgo = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this._tick();
    },


    tagName: 'span',

    _tick: function _tick() {
      if (this.get('isDestroying') || this.get('isDestroyed')) {
        return;
      }

      this.set('computedTimeAgo', this.get('time') ? this.fromNowWithSeconds((0, _moment.default)(this.get('time'))) : 'N/A');

      Ember.run.later(this, this._tick, 1000);
    },

    fromNowWithSeconds: function fromNowWithSeconds(compare, m) {
      var secDiff = Math.round(Math.abs((0, _moment.default)().diff(compare)) / 1000);

      if (secDiff < 10) {
        // 10 sec
        return 'just now';
      } else if (secDiff < 60) {
        return secDiff + ' seconds ago';
      }

      return compare.fromNow(m);
    }
  });

  TimeAgo.reopenClass({ positionalParams: ['time'] });

  exports.default = TimeAgo;
});