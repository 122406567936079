define('slate-payroll/components/list/request-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var RequestItemComponent = Ember.Component.extend({
    classNames: ['list-group-item', 'clearfix'],

    didUpdateAttrs: function didUpdateAttrs() {
      var _this = this;

      Ember.run.next(function () {
        if (_this.get('expanded') !== null) {
          _this.set('showingDetails', _this.get('expanded'));
        }
      });
    },


    actions: {
      toggleExpanded: function toggleExpanded() {
        this.toggleProperty('showingDetails');
      }
    }
  });

  RequestItemComponent.reopenClass({ positionalParams: ['model'] });

  exports.default = RequestItemComponent;
});