define('slate-payroll/routes/application', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var errorRouteMap = {
    401: 'unauthorized',
    404: 'not-found',
    500: 'error',
    400: 'error'
  };

  exports.default = Ember.Route.extend({
    logging: Ember.inject.service(),

    title: function title(tokens) {
      return tokens && tokens.length ? tokens.join(' - ') + ' - Slate Payroll' : 'Slate Payroll - A clean way to pay';
    },
    beforeModel: function beforeModel() {
      var _this = this;

      return this.store.findAll('session').then(function (sessions) {
        var existingSession;

        sessions.forEach(function (session) {
          if ((0, _moment.default)(session.get('expires')).isAfter((0, _moment.default)())) {
            existingSession = session;
          } else {
            session.destroyRecord();
          }
        });

        if (existingSession) {
          _this.auth.set('content', existingSession);
          _this.auth.get('currentUser').then(function (user) {
            _this.auth.initializeHelp();
            _this.auth.identify(user);
            _this.auth.set('headData.darkMode', _this.auth.getPreference('darkMode'));
          });
        }
      });
    },


    actions: {
      error: function error(_error) {
        var route = 'error',
            err = _error.errors ? _error.errors[0] : _error;

        if (err && err.status) {
          var routeInMap = errorRouteMap[err.status];

          if (routeInMap) {
            route = routeInMap;
          }
        }

        this.logging.send(_error);
        this.logging.debug('Routing to ', route, 'to handle UX error...');

        this.controllerFor(route).set('fromError', err);
        this.transitionTo('/' + route);
      },
      logout: function logout() {
        this.auth.destroySession().then(function () {
          window.location.reload();
        });
      },
      loading: function loading(transition) {
        NProgress.start();
        transition.finally(function () {
          return NProgress.done();
        });
      }
    }
  });
});