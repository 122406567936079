define('slate-payroll/config/statics', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var defaultOperators = ['is', 'is not'];

  var dataTypeOperatorMap = {
    string: [].concat(defaultOperators, ['contains', '$regex']),
    boolean: defaultOperators,
    number: ['is equal to', 'is not equal to', 'is less than', 'is greater than'],
    date: ['is within', 'is before', 'is after', 'doesn\'t exist'],
    belongsTo: [].concat(defaultOperators, ['is one of', 'is not one of']),
    hasMany: ['is', 'contains', 'does not contain']
  };

  exports.dataTypeOperatorMap = dataTypeOperatorMap;
});