define('slate-payroll/components/display/video-hero', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['video-hero'],
    attributeBindings: ['_height:style'],

    didInsertElement: function didInsertElement() {
      this.bindToResize();
      this.scaleContainer();
      this.autoplay();
    },


    bindToResize: function bindToResize() {
      this.initSizes();
      Ember.$(window).on('resize', this.initSizes.bind(this));
    },

    scaleContainer: function scaleContainer() {
      var _this = this;

      Ember.run.scheduleOnce('afterRender', function () {
        if (_this.get('isDestroyed') || _this.get('isDestroying')) {
          return;
        }
        _this.set('_height', 'height: ' + parseInt(Ember.$(window).height() + 5, 10) + 'px');
      });
    },

    autoplay: function autoplay() {
      var _this2 = this;

      Ember.run.scheduleOnce('afterRender', function () {
        _this2.$().find('video').get(0).play();
      });
    },

    scaleVideo: function scaleVideo(el) {
      var videoWidth, videoHeight;

      var windowWidth = Ember.$(window).width(),
          windowHeight = Ember.$(window).height() + 5;

      Ember.$(el).each(function () {
        var videoAspectRatio = Ember.$(this).data('height') / Ember.$(this).data('width');

        Ember.$(this).width(windowWidth);

        if (windowWidth < 1000) {
          videoHeight = windowHeight;
          videoWidth = videoHeight / videoAspectRatio;
          Ember.$(this).css({
            'margin-top': 0,
            'margin-left': -(videoWidth - windowWidth) / 2 + 'px'
          });
          Ember.$(this).width(videoWidth).height(videoHeight);
        }
      });
    },
    initSizes: function initSizes() {
      var scaleVideo = this.scaleVideo.bind(this),
          elementList = ['.poster img', '.filter', 'video'];

      var setDimensions = function setDimensions(el) {
        Ember.$('.video-container ' + el).each(function () {
          Ember.$(this).data('height', Ember.$(this).height());
          Ember.$(this).data('width', Ember.$(this).width());
        });

        scaleVideo(el);
      };

      this.scaleContainer();
      elementList.forEach(setDimensions);
    },
    willDestroy: function willDestroy() {
      Ember.$(window).off('resize', this.initSizes.bind(this));
    }
  });
});