define('slate-payroll/models/punch', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var attr = _emberData.default.attr;

  exports.default = _emberData.default.Model.extend({
    timeIn: attr('date'),
    timeOut: attr('date'),
    hours: attr('number'),
    days: attr('number'),
    addEarnings: attr('number'),
    deduction: attr('number'),
    lunch: attr('number'), // Hours
    breaks: attr('number', { defaultValue: 0 }), // Hours
    onBreak: attr('date'),
    commentToEmployee: attr('string'),
    directHourEntry: attr('boolean'),
    needsReview: attr('boolean'),
    reviewReason: attr('string'),
    reviewDate: attr('date'),
    reviewedBy: attr('string'),
    transaction: attr('boolean'), // Meaning it is not tied to a pay period
    triggeredOvertime: attr('boolean'),
    isOvertime: attr('boolean'),
    clientVersion: attr('string'),

    status: attr('string'),
    notes: attr('string'),
    signature: attr('string'),
    punchComment: attr('string'),
    ip: attr('string'),

    timeInGeoLat: attr('number'),
    timeInGeoLng: attr('number'),
    timeOutGeoLat: attr('number'),
    timeOutGeoLng: attr('number'),
    uaIn: attr('string'),
    uaOut: attr('string'),
    uaInDeviceString: attr('string'),
    uaOutDeviceString: attr('string'),

    // Relational
    employee: _emberData.default.belongsTo('employee', { async: true }),
    seenBySupervisors: _emberData.default.hasMany('employee', { async: true }),
    seenByUsers: _emberData.default.hasMany('user', { async: true }),
    company: _emberData.default.belongsTo('company', { async: true }),
    splitTo: _emberData.default.belongsTo('punch', {
      async: true,
      inverse: null
    }),
    overtimePunch: _emberData.default.belongsTo('punch', {
      async: true,
      inverse: null
    }),
    triggerPunch: _emberData.default.belongsTo('punch', {
      async: true,
      inverse: null
    }),
    companyPaycode: _emberData.default.belongsTo('company-paycode', {
      inverse: false,
      async: true
    }),
    payPeriod: _emberData.default.belongsTo('pay-period', { async: true }),
    supervisor: _emberData.default.belongsTo('employee', {
      inverse: false,
      async: true
    }),
    department: _emberData.default.belongsTo('department', {
      inverse: false,
      async: true
    }),
    location: _emberData.default.belongsTo('location', {
      inverse: false,
      async: true
    }),
    payroll: _emberData.default.belongsTo('payroll', {
      inverse: false,
      async: true
    }),
    timeOffRequest: attr('string'),

    // Legacy Fields
    legacyEntryId: attr('number'),
    legacyPayPeriodId: attr('number'),
    legacyCostCenterId: attr('number'),

    updatedOn: attr('date'),
    created: attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),

    incomplete: Ember.computed('timeIn', 'timeOut', function () {
      return this.get('timeIn') && !this.get('timeOut');
    }),

    hasGeoData: Ember.computed('timeInGeoLat', 'timeInGeoLng', 'timeOutGeoLat', 'timeOutGeoLng', function () {
      var g = this.getProperties('timeInGeoLat', 'timeInGeoLng', 'timeOutGeoLat', 'timeOutGeoLng');
      return g.timeInGeoLat && g.timeInGeoLng || g.timeOutGeoLat && g.timeOutGeoLng;
    })
  });
});