define('slate-payroll/models/time-off-blackout', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    description: (0, _attr.default)('string'),
    start: (0, _attr.default)('date'),
    end: (0, _attr.default)('date'),

    company: (0, _relationships.belongsTo)('company', {
      async: true,
      inverse: null
    }),
    locations: (0, _relationships.hasMany)('location', {
      async: true,
      inverse: null
    }),
    departments: (0, _relationships.hasMany)('department', {
      async: true,
      inverse: null
    }),

    created: (0, _attr.default)('date', { defaultValue: function defaultValue() {
        return new Date();
      } })
  });
});