define('slate-payroll/models/reminder', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  exports.default = _emberData.default.Model.extend({
    description: attr('string'),
    code: attr('string'),
    notes: attr('string'),
    starts: attr('date'),
    next: attr('date'),
    repeatUnit: attr('string'),
    repeatUnits: attr('number'),
    warningDaysBefore: attr('number'),
    completed: attr('boolean'),
    missed: attr('boolean'),
    showEmployee: attr('boolean'),
    emailNotifications: attr('boolean', { defaultValue: true }),

    company: belongsTo('company', {
      inverse: false,
      async: true
    }),
    employee: belongsTo('employee', {
      inverse: false,
      async: true
    }),
    users: hasMany('user', {
      inverse: false,
      async: true
    }),

    created: attr('date', { defaultValue: function defaultValue() {
        return new Date();
      } }),

    upcomingWarning: Ember.computed('warningDaysBefore', 'next', 'missed', function () {
      var next = this.get('next'),
          days = this.get('warningDaysBefore') || 30;
      if (this.get('missed')) {
        return false;
      }
      return next ? (0, _moment.default)(next).subtract(days + 30, 'days').isBefore((0, _moment.default)()) : false;
    }).volatile(),

    isWarning: Ember.computed('warningDaysBefore', 'next', 'missed', function () {
      var next = this.get('next'),
          days = this.get('warningDaysBefore') || 30;
      if (this.get('missed') || this.get('completed')) {
        return false;
      }
      return next ? (0, _moment.default)(next).subtract(days, 'days').isBefore((0, _moment.default)()) : false;
    }).volatile(),

    virtualMissed: Ember.computed('next', 'missed', 'completed', function () {
      var next = this.get('next');
      if (this.get('completed')) {
        return false;
      } else if (this.get('missed')) {
        return true;
      }
      return next ? (0, _moment.default)(next).isBefore((0, _moment.default)()) : false;
    }).volatile()
  });
});