define('slate-payroll/initializers/handle-deprecation-warnings', ['exports', 'slate-payroll/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() {
    Ember.Debug.registerDeprecationHandler(function (message, options, next) {
      if (_environment.default.BUG_THE_HELL_OUTTA_ME) {
        next(message, options);
      }
    });

    Ember.Debug.registerWarnHandler(function (message, options, next) {
      if (_environment.default.BUG_THE_HELL_OUTTA_ME) {
        next();
      }
    });
  }

  exports.default = { initialize: initialize };
});