define('slate-payroll/routes/account/users', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    titleToken: 'Users',

    model: function model() {
      return this.store.findAll('user');
    }
  });
});