define('slate-payroll/routes/account/pay-period', ['exports', 'ember-keyboard-shortcuts/mixins/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_route.default, {
    titleToken: 'Pay Period',

    model: function model(params) {
      return this.store.findRecord('pay-period', params.id);
    },


    keyboardShortcuts: {
      'alt+right': {
        action: 'goForwardOne',
        global: false,
        scoped: true
      },
      'alt+left': {
        action: 'goBackOne',
        global: false,
        scoped: true
      }
    },

    findAdjacent: function findAdjacent(prop) {
      var _this = this;

      var q = {
        limit: 1,
        select: 'id',
        sort: {}
      };

      var reverse = prop === 'end' ? 'start' : 'end';
      q.sort[prop] = prop === 'end' ? -1 : 1;
      q[prop] = {};
      q[prop][prop === 'end' ? '$lte' : '$gte'] = this.get('controller.model.' + reverse);

      Ember.$.getJSON('/api/pay-periods', q).then(function (data) {
        var pp = data.payPeriod[0];
        if (pp) {
          _this.transitionTo('account.pay-period', pp.id);
        }
      });
    },


    actions: {
      goBackOne: function goBackOne() {
        this.findAdjacent('end');
      },
      goForwardOne: function goForwardOne() {
        this.findAdjacent('start');
      }
    }
  });
});