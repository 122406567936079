define('slate-payroll/routes/account/index/clock-map', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    titleToken: 'Map View',

    queryParams: {
      range: { refreshModel: true },
      employees: { refreshModel: true }
    },

    model: function model(params) {
      var query = {
        timeInGeoLat: { $type: 1 },
        timeInGeoLng: { $type: 1 },
        timeIn: { $gt: (0, _moment.default)().subtract(params.range || 24, 'hours').toDate() }
      };

      if (params.employees && params.employees.length) {
        query.employee = { $in: params.employees };
      }

      return Ember.RSVP.hash({
        punches: this.store.query('punch', query),
        employees: this.get('controller._employees') ? this.get('controller._employees') : this.store.findAll('employee')
      });
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        model: model.punches,
        _employees: model.employees
      });
    }
  });
});