define('slate-payroll/controllers/account', ['exports', 'slate-payroll/mixins/route-context'], function (exports, _routeContext) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_routeContext.default, {
    init: function init() {
      this._super.apply(this, arguments);
      this.routeDefinitions = {
        index: 'Summary',
        'pay-periods': 'Pay Periods',
        settings: 'Settings',
        employees: 'Employees',
        users: 'Users',
        employee: 'Employee',
        'company-documents': 'Company Documents',
        'automated-paycodes': 'Automated Paycodes',
        departments: 'Departments',
        add: 'Add',
        edit: 'Edit',
        punches: 'Punches',
        messaging: 'Messages',
        'create-punch': 'New Punch'
      };
    },


    root: 'account'
  });
});