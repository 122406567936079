define('slate-payroll/models/overtime-rule', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var attr = _emberData.default.attr;

  exports.default = _emberData.default.Model.extend({
    hours: attr('number'),
    measure: attr('string', { defaultValue: 'Week' }),
    paycode: _emberData.default.belongsTo('company-paycode', {
      async: true,
      inverse: false
    }),

    created: attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    })
  });
});