define('slate-payroll/routes/account/broadcasts/index/edit', ['exports', 'slate-payroll/mixins/routable-modal', 'slate-payroll/mixins/route-abstractions/edit'], function (exports, _routableModal, _edit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_routableModal.default, _edit.default, {
    modelName: 'broadcast',
    segmentKey: 'broadcast_id',

    afterModalClose: function afterModalClose() {
      this.controllerFor('account.broadcasts.index').send('refresh');
    }
  });
});