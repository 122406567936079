define('slate-payroll/routes/account/department', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return Ember.RSVP.hash({
        department: this.store.find('department', params.departmentid),
        employees: this.store.query('employee', {
          department: params.departmentid,
          terminatedOn: { $not: { $type: 9 } },
          select: '_id name',
          sort: { 'name.last': 1 }
        })
      });
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        model: model.department,
        employees: model.employees
      });
    }
  });
});