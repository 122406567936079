define('slate-payroll/controllers/account/pay-period/index', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.punchChart = {
        chartOptions: {
          color: { pattern: ['#79BD8F', '#FF6138'] },
          axis: {
            x: {
              type: 'timeseries',
              label: 'Day'
            },
            y: { label: 'Hours' }
          }
        },
        dataOptions: { x: 'x' }
      };
    },


    setDays: Ember.observer('model.punches', function () {
      var _this = this;

      var start = this.get('model.start'),
          diffDays = (0, _moment.default)(this.get('model.end')).diff(start, 'days'),
          days = [];

      for (var i = 0; i < diffDays + 1; i++) {
        var m = (0, _moment.default)(start).add(i, 'days');

        days.addObject({
          date: m.toDate(),
          top: m.format('ddd, MMM Do')
        });
      }

      var promises = days.map(function (day) {
        return new Ember.RSVP.Promise(function (resolve, reject) {
          Ember.$.getJSON('/api/punches', {
            hours: { $exists: true },
            select: 'hours -_id',
            payPeriod: _this.get('model.id'),
            timeIn: { $gte: (0, _moment.default)(day.date).startOf('day').toDate() },
            timeOut: { $lte: (0, _moment.default)(day.date).endOf('day').toDate() }
          }).done(function (res) {
            resolve(Ember.$.extend(day, {
              body: res.punch.length ? res.punch.length + ' punch(es)<br />' + Math.round(res.punch.reduce(function (ret, val) {
                return ret + val.hours;
              }, 0)) + 'hrs' : '<br /><br />'
            }));
          }).fail(reject);
        });
      });

      Ember.RSVP.all(promises).then(function (data) {
        _this.set('days', data);
      });
    }),

    actions: {
      selectDay: function selectDay(day) {
        this.transitionToRoute('account.pay-period.punches', {
          queryParams: {
            timeIn: day.date,
            timeOut: (0, _moment.default)(day.date).add(1, 'day').toDate()
          }
        });
      }
    }
  });
});