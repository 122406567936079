define('slate-payroll/routes/xsu', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    // t = token
    // u = userId
    // e = expiration
    // redirect = a passable redirection intent after signin
    model: function model(_ref) {
      var t = _ref.t,
          u = _ref.u,
          e = _ref.e,
          redirect = _ref.redirect;

      if (!t || !u || !e) {
        return this.transitionToRoute('index');
      }

      return {
        redirect: redirect,
        session: this.get('auth').createSession({
          token: t,
          expiration: e,
          user: u,
          spoofing: true
        })
      };
    },
    afterModel: function afterModel(_ref2) {
      var _this = this;

      var redirect = _ref2.redirect;

      Ember.run.scheduleOnce('afterRender', function () {
        return Ember.run.later(function () {
          if (redirect) {
            window.location.href = '' + window.location.origin + redirect;
            return;
          }

          _this.transitionTo('account');
        }, 2500);
      });
    }
  });
});