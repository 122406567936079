define('slate-payroll/controllers/account/index/custom-reports/run', ['exports', 'slate-payroll/mixins/ux-ajax-status-hooks'], function (exports, _uxAjaxStatusHooks) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_uxAjaxStatusHooks.default, {
    enableNotify: true,
    parameters: {},

    headerRow: Ember.computed('results.[]', function () {
      return (this.get('results') || [])[0];
    }),

    dataRows: Ember.computed('results.[]', function () {
      return (this.get('results') || []).slice(1);
    }),

    getParameters: function getParameters() {
      return this.get('parameters');
    },


    actions: {
      parameterChange: function parameterChange(param, val) {
        var value = val;
        if (val.target) {
          value = val.target.value;
        }
        this.set('parameters.' + param.paramKey, value);
      },
      runReport: function runReport() {
        var _this = this;

        if (this.get('working')) {
          return;
        }

        this.ajaxStart();

        Ember.$.post('/api/custom-report/' + this.get('model.id') + '/run', this.getParameters()).done(function () {
          var response = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

          _this.set('results', response.results);
          _this.ajaxSuccess(null, true);
        }).fail(this.ajaxError.bind(this));
      }
    }
  });
});