define('slate-payroll/controllers/account/time-off/historical', ['exports', 'slate-payroll/mixins/resource-pagination'], function (exports, _resourcePagination) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_resourcePagination.default, {
    queryParams: ['page', 'e'],
    page: 1,
    e: undefined // Employee
  });
});