define('slate-payroll/controllers/account/employees/census/results', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.ids = [];
    },


    queryParams: ['ids']
  });
});