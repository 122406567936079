define('slate-payroll/components/lazy-model-select-filter', ['exports', 'slate-payroll/mixins/ux-ajax-status-hooks'], function (exports, _uxAjaxStatusHooks) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var LazyModelSelectComponent = Ember.Component.extend(_uxAjaxStatusHooks.default, {
    store: Ember.inject.service(),
    classNames: ['lazy-model-select-component'],
    classNameBindings: ['multi:lazy-model-multi-select'],
    modelQuery: null,

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this.set('_selection', this.get('selection') || Ember.A());

      if (this.get('_modelName') !== this.get('modelName')) {
        this.set('_modelName', this.get('modelName'));
        this.resolveModel().then(function (val) {
          _this.set('resolvedModel', val);
        });
      }
    },
    resolveModel: function resolveModel() {
      var _this2 = this;

      var select = 'id',
          modelQuery = this.get('modelQuery'),
          selectors = ['optionLabelPath', 'optionValuePath', 'selectQuery'];

      this.ajaxStart();

      selectors.forEach(function (selector) {
        if (_this2.get(selector)) {
          select += ' ' + _this2.get(selector);
        }
      });

      var query = modelQuery ? Ember.$.extend({ select: select }, modelQuery) : { select: select };

      return this.store.query(this.get('modelName'), query).then(function (results) {
        _this2.ajaxSuccess();
        return results;
      }).catch(this.ajaxError.bind(this));
    },


    fullSelection: Ember.computed('resolvedModel.[]', 'selection.[]', 'optionValuePath', function () {
      var valuePath = this.get('optionValuePath'),
          model = this.get('resolvedModel') || Ember.A();

      return (this.get('selection') || Ember.A()).map(function (selection) {
        return model.find(function (modelItem) {
          return valuePath ? modelItem.get(valuePath) === selection : modelItem === selection;
        });
      });
    }),

    selectContent: Ember.computed('resolvedModel.[]', 'selection.[]', function () {
      var selection = this.get('selection'),
          valuePath = this.get('optionValuePath'),
          resolvedModel = this.get('resolvedModel');

      if (!resolvedModel) {
        return;
      }

      return resolvedModel.filter(function (i) {
        return !selection.includes(valuePath ? i.get(valuePath) : i);
      });
    }),

    addSelection: function addSelection(item) {
      this.set('boundSelectSelection', null);
      this.get('onChange')(Ember.A([].concat(_toConsumableArray(this.get('selection').toArray()), [item])));
    },
    removeSelection: function removeSelection(item) {
      var valuePath = this.get('optionValuePath');
      this.get('onChange')(this.get('selection').without(valuePath ? item.get(valuePath) : item));
    }
  });

  LazyModelSelectComponent.reopenClass({ positionalParams: ['modelName'] });

  /*
    {{lazy-model-select 'my-model'
      store=store
      label='My Select'
      selectId='my-select-id'
      selectClass='form-control'
      optionLabelPath='model.label.path'
      selection=defaultSelection
      multi=true
      action=(action (mut mySelectionValue))
      prompt="--Select--"}}
  */

  exports.default = LazyModelSelectComponent;
});