define('slate-payroll/routes/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    actions: {
      didTransition: function didTransition() {
        this.controller.set('scrollRevealNeedsRender', true);
      }
    }
  });
});