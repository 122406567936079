define('slate-payroll/routes/account/employees', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    titleToken: 'Employees',

    model: function model() {}
  });
});