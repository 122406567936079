define('slate-payroll/serializers/company-paycode', ['exports', 'ember-data', 'slate-payroll/serializers/application', 'slate-payroll/utils/serialize-object'], function (exports, _emberData, _application, _serializeObject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    isNewSerializerAPI: true,
    attrs: {
      paycode: {
        deserialize: 'records',
        serialize: 'ids'
      }
    },

    normalize: function normalize(modelClass, hash) {
      (0, _serializeObject.default)(hash, false, 'codeSettings', 'permissions');
      return this._super.apply(this, arguments);
    }
  });
});